import React, { useEffect, useState } from "react";
import { Input, Button, Form, Row, Col, message, Layout } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Back from "../../img/home_icon/auth/back";
import NewPassword from "../../img/home_icon/auth/newpassword.js";
import Resetpassword from "../../api/auth/newpassword";
const { Header, Footer, Sider, Content } = Layout;
const CardNewPassword = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [verifynoti, setVerifyNoti] = useState(false);
  const [verify, setVerify] = useState(false);
  const [verifyborder, setVerifyBorder] = useState(false);
  var navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, []);
  return (
    <Layout className="boxframe">
      <Header style={styles.Header}>
        <div
          onClick={() => {
            navigate("/login");
          }}
        >
          <Back />
        </div>
      </Header>
      <Content style={styles.my_auto}>
        <div className="container">
          <Row justify={"center"} align={"middle"}>
            <Col span={24}>
              <div style={styles.title}>Create New Password</div>
              <div style={{ textAlign: "center", margin: "47px 0 38px 0" }}>
                <NewPassword />
              </div>
              <div style={styles.description}>Please enter a new password.</div>
              <div style={styles.subtitle}>
                Your new password must be different from previous used password.
              </div>
              <Row justify={"center"} align={"middle"}>
                <Form
                  layout={"vertical"}
                  autoComplete="off"
                  form={form}
                  onFinish={(e) => {
                    if (e.password === e.C_password) {
                      Resetpassword(
                        {
                          id: props.id,
                          password: e.password,
                          confirmPassword: e.C_password,
                        },
                        (err, res) => {
                          if (!err && res.statusCode == 200) {
                            navigate("/login");
                          } else {
                            setVerifyNoti(true);
                            setVerifyBorder(true);
                          }
                        }
                      );
                    } else {
                      setVerifyNoti(true);
                      setVerifyBorder(true);
                    }
                  }}
                >
                  <div
                    style={verify ? styles.input_err : styles.input}
                    className="input_ps"
                  >
                    <Form.Item
                      label={null}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          transform(value) {
                            if (value.length == 0) {
                              setVerify(true);
                            } else {
                              setVerify(false);
                              setVerifyBorder(false);
                              setVerifyNoti(false);
                            }
                            // return value;
                          },
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Password"
                        name="password"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        required
                        bordered={false}
                        style={styles.font_input}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ margin: "40px 0 0 0" }}></div>
                  <div
                    style={verifyborder ? styles.input_err : styles.input}
                    className="input_ps"
                  >
                    <Form.Item
                      label={null}
                      name="C_password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your confirm password!",
                        },
                        {
                          transform(value) {
                            if (value.length == 0) {
                              setVerifyNoti(false);
                              setVerifyBorder(true);
                            } else {
                              setVerifyBorder(false);
                              setVerifyNoti(false);
                            }
                            return value;
                          },
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Confirm Password"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        required
                        bordered={false}
                        style={styles.font_input}
                      />
                    </Form.Item>
                  </div>
                  {verifynoti && (
                    <div style={styles.font_input_err}>
                      *The password confirmation does not match.
                    </div>
                  )}
                  {!verifynoti && <div style={{ height: "22px" }}></div>}
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={styles.buttonSend}
                  >
                    Save New Password
                  </Button>
                </Form>
              </Row>
            </Col>
          </Row>
        </div>
      </Content>
      <Footer style={styles.Footer}>
        <Row style={styles.bottom}>
          {new Date().getFullYear()}, Obotrons Corporation Limited
        </Row>
      </Footer>
    </Layout>
  );
};

const styles = {
  Header: {
    background: "transparent",
    padding: "20px 18px 20px 18px",
    height: "100px",
  },
  Footer: {
    background: "transparent",
    padding: "20px",
  },
  my_auto: {
    marginBottom: "auto",
    marginTop: "auto",
    flex: "unset",
  },
  title: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "36px",
    color: "#FFFFFF",
    textAlign: "center",
    // margin: "63px 0 0 0",
  },
  subtitle: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    color: "#FFFFFF",
    textAlign: "center",
    margin: "0 0 30px 0",
  },
  description: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#FFFFFF",
    textAlign: "center",
    margin: "0 0 45px 0 ",
  },
  buttonSend: {
    width: "381px",
    height: "75px",
    background: "rgba(18, 44, 77, 0.8)",
    borderRadius: "10px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    color: "rgba(255, 255, 255, 0.8)",
    margin: "56px 0 40px 0",
    boxShadow: "none",
  },
  bottom: {
    justifyContent: "center",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(255, 255, 255, 0.55)",
  },
  input: {
    width: "383px",
    height: "75px",
    background: "rgba(0, 0, 0, 0.1)",
    borderBottom: "2px solid rgba(255, 255, 255, 0.9)",
    borderRadius: "10px",
    // margin: "0 0 40px 0",
    color: "#FFFFFF",
  },
  input_err: {
    width: "100%",
    height: "75px",
    background: "rgba(0, 0, 0, 0.1)",
    borderBottom: "2px solid #F40606",
    borderRadius: "10px",
    // margin: "0 0 40px 0",
    color: "#FFFFFF",
  },
  font_input: {
    height: "75px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    color: "rgba(255, 255, 255, 0.8)",
    // line-height: 28px;
  },
  font_input_err: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.5714285714285714",
    color: "#ff4d4f",
  },
};
export default CardNewPassword;
