import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Spin, Table, Tooltip, Button, Input, Space } from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import Getproejecttable from "../../api/project/table";
import { Homefullfill } from "../../img/project_icon/home";
import { useDispatch } from "react-redux";
import {
  editActive,
  editOnfilter,
  editH_filter,
} from "../../slice/projectSlice";
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 36,
      color: "#ffffff",
    }}
    spin
  />
);
const Table_project = () => {
  const dispatch = useDispatch();
  const Params = useParams();
  const [isLoad, setIsload] = useState(true);
  const [dataTable, setDatatable] = useState([]);
  const searchInput = useRef(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: "5px",
          // alignItems: "center",
          // display: "flex",
          background: "#D9D9D9",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
          borderRadius: "8px",
          margin: "5px 0px",
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          bordered={false}
          style={{
            fontFamily: "Arial Narrow",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "20px",
            lineHeight: "23px",
            color: "#122C4D",
            display: "block",
            marginBottom: "8px",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{
              width: "110px",
              height: "40px",
              fontFamily: "Arial Narrow",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "20px",
              lineHeight: "23px",
              color: "#FFFFFF",
              backgroundColor: "#122C4D",
              boxShadow: "none",
            }}
          >
            Search
          </Button>
          <Button
            type="primary"
            onClick={() =>
              clearFilters && confirm && handleReset(clearFilters, confirm)
            }
            size="small"
            style={{
              width: "110px",
              height: "40px",
              fontFamily: "Arial Narrow",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "20px",
              lineHeight: "23px",
              color: "#122C4D",
              backgroundColor: "#FFFFFF",
              boxShadow: "none",
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#FFFFFF" : "#FFFFFF",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) => text,
  });
  const columns = [
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
      render: (floor) => `Floor ${floor}`,
      width: 80,
      align: "start",
      onClick: () => {
        console.log("onClick");
      },
      ellipsis: {
        showTitle: false,
      },
      ...getColumnSearchProps("floor"),
      render: (floor) => (
        <Tooltip placement="topLeft" title={floor}>
          {floor}
        </Tooltip>
      ),
    },
    {
      title: () => {
        return <Homefullfill color={"#66B040"} />;
      },
      dataIndex: "online",
      key: "online",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (online) => (
        <Tooltip placement="topLeft" title={online}>
          {online}
        </Tooltip>
      ),
    },
    {
      title: () => {
        return <Homefullfill color={"#FAB52F"} />;
      },
      dataIndex: "unstable",
      key: "unstable",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (unstable) => (
        <Tooltip placement="topLeft" title={unstable}>
          {unstable}
        </Tooltip>
      ),
    },
    {
      title: () => {
        return <Homefullfill color={"#F40606"} />;
      },
      dataIndex: "offline",
      key: "offline",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (offline) => (
        <Tooltip placement="topLeft" title={offline}>
          {offline}
        </Tooltip>
      ),
    },
    {
      title: "Total",
      key: "total",
      dataIndex: "total",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (total) => (
        <Tooltip placement="topLeft" title={total}>
          {total}
        </Tooltip>
      ),
    },
    {
      title: "Residents",
      key: "residents",
      dataIndex: "residents",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (residents) => (
        <Tooltip placement="topLeft" title={residents}>
          {residents}
        </Tooltip>
      ),
    },
    {
      title: "Juristic",
      key: "Juristic",
      dataIndex: "Juristic",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (Juristic) => (
        <Tooltip placement="topLeft" title={Juristic}>
          {Juristic}
        </Tooltip>
      ),
    },
    {
      title: "Both Request",
      key: "bothRequest",
      dataIndex: "bothRequest",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (bothRequest) => (
        <Tooltip placement="topLeft" title={bothRequest}>
          {bothRequest}
        </Tooltip>
      ),
    },
    {
      title: "PIR Battery",
      key: "pirbattery",
      dataIndex: "pirbattery",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (pirbattery) => (
        <Tooltip placement="topLeft" title={pirbattery}>
          {pirbattery}
        </Tooltip>
      ),
    },
  ];
  useEffect(() => {
    if (isLoad) {
      Getproejecttable(Params.project, (err, res) => {
        if (!err && res.statusCode === 200) {
          setDatatable(res.data);
          setIsload(false);
        }
      });
    }
  }, []);

  return (
    <Spin indicator={antIcon} spinning={isLoad}>
      <div style={styles.box}>
        <Row>
          <Col span={24} className="tableProject">
            <Table
              style={{ backgroundColor: "rgba(255, 255, 255, 0.1) !important" }}
              columns={columns}
              dataSource={dataTable}
              onRow={(record, rowIndex) => {
                return {
                  style: { background: "rgba(0, 0, 0, 0.1)" },
                  onClick: (event) => {
                    dispatch(
                      editActive({
                        title: String("Floor " + record.floor),
                        sub: "Floor",
                        count_room: record.total,
                      })
                    );
                    dispatch(
                      editOnfilter({
                        floor: record.floor,
                        serviceRequst: "all",
                        pir_battery: "all",
                        room: "all",
                        time: "all",
                        status: "all",
                        state: "all",
                      })
                    );
                    dispatch(
                      editH_filter({
                        floor: record.floor,
                        serviceRequst: "all",
                        pir_battery: "all",
                        room: "all",
                        time: "all",
                        status: "all",
                        state: "all",
                      })
                    );
                  }, // click row
                };
              }}
              scroll={{
                y: 553,
              }}
              pagination={false}
              // rowClassName={(record, rowIndex) =>  {return JSON.stringify(record)}}
            />
          </Col>
        </Row>
      </div>
    </Spin>
  );
};
const styles = {
  box: {
    background: "rgba(255, 255, 255, 0.1)",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "15px",
    padding: "20px",
    height: "635.5px",
  },
  headersTable: { backgroundColor: "rgb(100, 108, 140)" },
  title: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
  },
  total: {
    marginTop: "20px",
    marginBottom: "0px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
  },
};
export default Table_project;
