import React, { useEffect, useState } from "react";
import {
  Residents_filter,
  Juristic_filter,
  BotRequest_filter,
} from "../../img/project_icon/services";
import { FilterIcon } from "../../img/project_icon/filter";
import { HomeShow_Filter } from "../../img/project_icon/home";
import Battery from "../../img/project_icon/pir";
import Components from "../../components";
import Getmockdata from "../../api/project/detail";
import { Col, Row, Spin, Button, Drawer } from "antd";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getonFilter,
  getActive,
  editRooms,
  editOnfilter,
  getH_filter,
} from "../../slice/projectSlice";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 36,
      color: "#ffffff",
    }}
    spin
  />
);
const Colright = () => {
  const dispatch = useDispatch();
  const TitleActive = useSelector(getActive);
  const Filter_obj = useSelector(getonFilter);
  const H_filter = useSelector(getH_filter);
  const [open, setOpen] = useState(false);
  const [typeroom, setTyperoom] = useState("Room");
  const Params = useParams();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = (type) => {
    setOpen(false);
    if (type !== 'filter') {
      dispatch(editOnfilter(H_filter));
      // dispatch(
      //   editOnfilter({
      //     floor:'all',
      //     serviceRequst: "all",
      //     pir_battery: "all",
      //     room: "all",
      //     time: "all",
      //     status: "all",
      //     state: "all",
      //   })
      // );
    }
  
  };
  useEffect(() => {
    Getmockdata({site:Params.project,filter:Filter_obj}, (err, res) => {
      if (!err && res.statusCode === 200) {
        dispatch(editRooms(res.data));
      }
    });

    if (TitleActive.count_room > 1) {
      setTyperoom("Rooms");
    }
    else if (TitleActive.count_room === 0 || TitleActive.count_room === 1) {
      setTyperoom("Room");
    }
    
    return () => {
      // cleanup
    };
  }, [TitleActive]);
  return (
    <Spin indicator={antIcon} spinning={false}>
      <div className="card-filterbar" style={styles.box}>
        <Row>
          <div className="title_rs"
            style={{
              padding: "0 20px",
            }}
          >
            {TitleActive.sub} Detail
          </div>
        </Row>
        <div style={styles.background}>
          <Row style={styles.boxrooms}>
            <Col span={24}>
              <Row style={styles.boxfilter} align={"middle"}>
                {!TitleActive.title.includes("Floor") && (
                  <Col xs={4} sm={4} md={4} lg={6} xl={4} xxl={2}>
                    {TitleActive.title == "Both Request" && (
                      <BotRequest_filter
                        justify={"start"}
                        width={45}
                        color={"white"}
                      />
                    )}
                    {TitleActive.title == "Residents" && (
                      <Residents_filter
                        justify={"start"}
                        width={45}
                        color={"white"}
                      />
                    )}
                    {TitleActive.title == "Juristic" && (
                      <Juristic_filter
                        justify={"start"}
                        width={45}
                        color={"white"}
                      />
                    )}
                    {TitleActive.title == "Offline" && (
                      <HomeShow_Filter color={"#F40606"} />
                    )}
                    {TitleActive.title == "Unstable" && (
                      <HomeShow_Filter color={"#FAB52F"} />
                    )}
                    {TitleActive.title == "Online" && (
                      <HomeShow_Filter color={"#66B040"} />
                    )}
                    {TitleActive.title == "PIR Battery" && (
                      <Battery width="45" color={"white"} />
                    )}
                  </Col>
                )}

                <Col
                  xs={!TitleActive.title.includes("Floor") ? 16 : 20}
                  sm={!TitleActive.title.includes("Floor") ? 16 : 20}
                  md={!TitleActive.title.includes("Floor") ? 16 : 20}
                  lg={!TitleActive.title.includes("Floor") ? 14 : 20}
                  xl={!TitleActive.title.includes("Floor") ? 16 : 20}
                  xxl={!TitleActive.title.includes("Floor") ? 18 : 20}
                  style={{ padding: '0 15px' }}
                >
                  <Row justify={"start"} align={"middle"} >
                    <div className="title_rs" style={{ textAlign: 'start'}}>
                      {TitleActive.title}
                    </div>
                  </Row>
                  <Row align={"middle"} justify={"start"}>
                    <div className="title_btr" style={{ textAlign: 'start'}}>
                      {TitleActive.count_room} {typeroom}
                    </div>
                  </Row>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <Row justify={"end"} align={"middle"}>
                    <Button
                      type="primary"
                      style={styles.button_filterer}
                      onClick={showDrawer}
                    >
                      <Row align={"middle"} justif={"center"}>
                        <FilterIcon />
                      </Row>
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="filterbar-body">
            <Drawer
              title={
                <div>
                  <div style={styles.titleFilter}>Filter</div>
                  <div className="hr-title-noti"></div>
                </div>
              }
              placement="right"
              closable={false}
              onClose={onClose}
              open={open}
              getContainer={false}
              footer={
                <Components.FooterFilterbar_project
                  this_onClose={(e) => {
                    onClose(e);
                  }}
                />
              }
            >
              <Components.Filterbar_project />
            </Drawer>
          </div>
          <Components.Detail_project />
        </div>
      </div>
    </Spin>
  );
};
const styles = {
  box: {
    padding: "13px 0 0 0",
  },
  boxfilter: {
    background: "#122C4D",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    height: "113px",
    padding: "20px",
    // justifyContent: 'flex-start'
  },
  boxrooms: {
    background: "rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "15px",
    marginTop: "15px",
    // height:'113px'
    // marginBottom: '0px',
  },
  title: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: '37px',
    fontSize: "32px",
    color: "#FFFFFF",
    textAlign: 'start'
    // marginLeft: '10px',
  },
  titleFilter: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    color: "#FFFFFF",
    // marginLeft: '10px',
  },
  subtitle: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: '32px',
    color: "#FFFFFF",
    // marginLeft: '10px',
  },
  total: {
    marginTop: "20px",
    marginBottom: "0px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
  },
  button_filterer: {
    background: "rgba(0, 0, 0, 0.15)",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    with: "64px",
    height: "64px",
    boxShadow: "none",
  },
  background: {
    background: "rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "15px",
    height: "829px",
  },
};
export default Colright;
