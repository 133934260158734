import React, { useEffect, useState } from 'react';
import Containerroom from '../../container';
import { Col, Row } from 'antd';

const Display_room = () => {

    return (
        <Containerroom.Containner_room />
    )
};
export default Display_room;
