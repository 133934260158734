import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "antd";
import {
  Battery,
  BothRequest,
  HomeNomal,
  HomeVIP,
  HomeVVIP,
  Juristic,
  Residents,
} from "../../img/project_icon/filter";
import { getActive, getonFilter, editOnfilter } from '../../slice/projectSlice';
import { useSelector, useDispatch } from "react-redux";
const Filterbar = () => {
  const TitleActive = useSelector(getActive);
  const Filter_obj = useSelector(getonFilter);
  const dispatch = useDispatch();

  return (
    <div>
      {/* Detail */}
      <div style={{ padding: "0px 0" }}>
        {/* Service Request */}
        {TitleActive.sub !== 'Request' && TitleActive.sub !== 'Floor' && (
          <div style={{ margin: "0 0 35px 0" }}>
            <div style={styles.subtitle}>Service Request</div>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Button type="primary" style={Filter_obj.serviceRequst == 'residents' ? styles.ifreamActive : styles.ifream} onClick={() => {
                  let tmp = { ...Filter_obj };
                  tmp['serviceRequst'] = 'residents'
                  dispatch(editOnfilter(tmp))
                }}>
                  <Row>
                    <Col span={4}>
                      <Residents fill={Filter_obj.serviceRequst == 'residents' ? "#FFFFFF" : "#122C4D"}/>
                    </Col>
                    <Col span={20}>
                      <div style={{ padding: "0 0 0 16px" }}>Residents</div>
                    </Col>
                  </Row>
                </Button>
              </Col>
              <Col span={12}>
                <Button type="primary" style={Filter_obj.serviceRequst == 'juristic' ? styles.ifreamActive : styles.ifream} onClick={() => {
                  let tmp = { ...Filter_obj };
                  tmp.serviceRequst = 'juristic';
                  dispatch(editOnfilter(tmp))
                }}>
                  <Row>
                    <Col span={4}>
                      <Juristic fill={Filter_obj.serviceRequst == 'juristic' ? "#FFFFFF" : "#122C4D"}/>
                    </Col>
                    <Col span={20}>
                      <div style={{ padding: "0 0 0 16px" }}>Juristic</div>
                    </Col>
                  </Row>
                </Button>
              </Col>
              <Col span={12}>
                <Button type="primary" style={Filter_obj.serviceRequst == 'bothrequest' ? styles.ifreamActive : styles.ifream} onClick={() => {
                  let tmp = { ...Filter_obj };
                  tmp.serviceRequst = 'bothrequest';
                  dispatch(editOnfilter(tmp))
                }}>
                  <Row>
                    <Col span={4}>
                      <BothRequest fill={Filter_obj.serviceRequst == 'bothrequest' ? "#FFFFFF" : "#122C4D"}/>
                    </Col>
                    <Col span={20}>
                      <div style={{ padding: "0 0 0 16px" }}>Both Request</div>
                    </Col>
                  </Row>
                </Button>
              </Col>
              <Col span={12}>
                <Button type="primary" style={Filter_obj.serviceRequst == 'all' ? styles.ifreamActive : styles.ifream} onClick={() => {
                  let tmp = { ...Filter_obj };
                  tmp.serviceRequst = 'all';
                  dispatch(editOnfilter(tmp))
                }}>
                  <Row justify="center" align="middle">
                    <div>All Request</div>
                  </Row>
                </Button>
              </Col>
              <Col span={12}>
                <Button type="primary" style={Filter_obj.serviceRequst == 'none' ? styles.ifreamActive : styles.ifream} onClick={() => {
                  let tmp = { ...Filter_obj };
                  tmp.serviceRequst = 'none';
                  dispatch(editOnfilter(tmp))
                }}>
                  <Row justify="center" align="middle">
                    <div>None</div>
                  </Row>
                </Button>
              </Col>
            </Row>
          </div>
        )}
        {/* PIR Battery */}
        {TitleActive.sub !== 'Battery' && TitleActive.sub !== 'Floor' && (
          <div style={{ margin: "0 0 35px 0" }}>
            <div style={styles.subtitle}>PIR Battery</div>
            <Row gutter={[8, 8]}>
              <Col span={8}>
                <Button type="primary" style={Filter_obj.pir_battery == 'normal' ? styles.ifreamActive : styles.ifream} onClick={() => {
                  let tmp = { ...Filter_obj };
                  tmp.pir_battery = 'normal';
                  dispatch(editOnfilter(tmp))
                }}>
                  <Row justify="center" align="middle">
                    <div>Normal</div>
                  </Row>
                </Button>
              </Col>
              <Col span={10}>
                <Button type="primary" style={Filter_obj.pir_battery == 'low' ? styles.ifreamActive : styles.ifream} onClick={() => {
                  let tmp = { ...Filter_obj };
                  tmp.pir_battery = 'low';
                  dispatch(editOnfilter(tmp));
                }}>
                  <Row justify={'center'} align={'middle'}>
                    <Col span={4}>
                      <Battery fill={Filter_obj.pir_battery == 'low' ? "#FFFFFF" : "#122C4D"} />
                    </Col>
                    <Col span={20}>
                      <div style={{ padding: "0 0 0 10px" }}>Low Battery</div>
                    </Col>
                  </Row>
                </Button>
              </Col>
              <Col span={6}>
                <Button type="primary" style={Filter_obj.pir_battery == 'all' ? styles.ifreamActive : styles.ifream} onClick={() => {
                  let tmp = { ...Filter_obj };
                  tmp.pir_battery = 'all';
                  dispatch(editOnfilter(tmp))
                }}>
                  <Row justify="center" align="middle">
                    <div>All</div>
                  </Row>
                </Button>
              </Col>
            </Row>
          </div>
        )}



        {/* Room */}
        <div style={{ margin: "0 0 35px 0" }}>
          <div style={styles.subtitle}>Room</div>
          <Row gutter={[8, 8]}>
            <Col span={8}>
              <Button type="primary" style={Filter_obj.room == 'normal' ? styles.ifreamActive : styles.ifream} onClick={() => {
                let tmp = { ...Filter_obj };
                tmp.room = 'normal';
                dispatch(editOnfilter(tmp));
              }}>
                <Row justify="center" align="middle">
                  <Col span={4}>
                    <HomeNomal fill={Filter_obj.room == 'normal' ? "#FFFFFF" : "#122C4D"} />
                  </Col>
                  <Col span={20}>
                    <div style={{ padding: "0 0 0 10px" }}>Normal</div>
                  </Col>
                </Row>
              </Button>
            </Col>
            <Col span={8}>
              <Button type="primary" style={Filter_obj.room == 'vip' ? styles.ifreamActive : styles.ifream} onClick={() => {
                let tmp = { ...Filter_obj };
                tmp.room = 'vip';
                dispatch(editOnfilter(tmp));
              }}>
                <Row justify="center" align="middle">
                  <Col span={4}>
                    <HomeVIP fill={Filter_obj.room == 'vip' ? "#FFFFFF" : "#122C4D"} />
                  </Col>
                  <Col span={20}>
                    <div style={{ padding: "0 0 0 10px" }}>VIP</div>
                  </Col>
                </Row>
              </Button>
            </Col>
            <Col span={8}>
              <Button type="primary" style={Filter_obj.room == 'vvip' ? styles.ifreamActive : styles.ifream} onClick={() => {
                let tmp = { ...Filter_obj };
                tmp.room = 'vvip';
                dispatch(editOnfilter(tmp));
              }}>
                <Row justify="center" align="middle">
                  <Col span={4}>
                    <HomeVVIP fill={Filter_obj.room == 'vvip' ? "#FFFFFF" : "#122C4D"} />
                  </Col>
                  <Col span={20}>
                    <div style={{ padding: "0 0 0 10px" }}>VVIP</div>
                  </Col>
                </Row>
              </Button>
            </Col>
            <Col span={8}>
              <Button type="primary" style={Filter_obj.room == 'all' ? styles.ifreamActive : styles.ifream} onClick={() => {
                let tmp = { ...Filter_obj };
                tmp.room = 'all';
                dispatch(editOnfilter(tmp));
              }}>
                <Row justify="center" align="middle">
                  <div>All Room</div>
                </Row>
              </Button>
            </Col>
          </Row>
        </div>

        {/* Time */}
        <div style={{ margin: "0 0 35px 0" }}>
          <div style={styles.subtitle}>Time</div>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Button type="primary" style={Filter_obj.time == 'etw' ? styles.ifreamActive : styles.ifream} onClick={() => {
                let tmp = { ...Filter_obj };
                tmp.time = 'etw';
                dispatch(editOnfilter(tmp));
              }}>
                <Row justify="center" align="middle">
                  <div>Earlier This Week</div>
                </Row>
              </Button>
            </Col>
            <Col span={12}>
              <Button type="primary" style={Filter_obj.time == 'etm' ? styles.ifreamActive : styles.ifream} onClick={() => {
                let tmp = { ...Filter_obj };
                tmp.time = 'etm';
                dispatch(editOnfilter(tmp));
              }}>
                <Row justify="center" align="middle">
                  <div>Earlier This Month</div>
                </Row>
              </Button>
            </Col>
            <Col span={12}>
              <Button type="primary" style={Filter_obj.time == 'older30day' ? styles.ifreamActive : styles.ifream} onClick={() => {
                let tmp = { ...Filter_obj };
                tmp.time = 'older30day';
                dispatch(editOnfilter(tmp));
              }}>
                <Row justify="center" align="middle">
                  <div>Older 30 Days</div>
                </Row>
              </Button>
            </Col>
            <Col span={12}>
              <Button type="primary" style={Filter_obj.time == 'all' ? styles.ifreamActive : styles.ifream} onClick={() => {
                let tmp = { ...Filter_obj };
                tmp.time = 'all';
                dispatch(editOnfilter(tmp));
              }}>
                <Row justify="center" align="middle">
                  <div>All Time</div>
                </Row>
              </Button>
            </Col>
          </Row>
        </div>

        {/* Status */}
        {TitleActive.sub !== 'Status' && TitleActive.sub !== 'Floor' && (
          <div style={{ margin: "0 0 35px 0" }}>
            <div style={styles.subtitle}>Status</div>
            <Row gutter={[8, 8]}>
              <Col span={7}>
                <Button type="primary" style={Filter_obj.status == 'online' ? styles.ifreamActive : styles.ifream} onClick={() => {
                  let tmp = { ...Filter_obj };
                  tmp.status = 'online';
                  dispatch(editOnfilter(tmp));
                }}>
                  <Row justify="start">
                    <Col span={4}>
                      <HomeNomal fill={"#66B040"} />
                    </Col>
                    <Col span={20}>
                      <div style={{ padding: "0 0 0 9px" }}>Online</div>
                    </Col>
                  </Row>
                </Button>
              </Col>
              <Col span={9}>
                <Button type="primary" style={Filter_obj.status == 'unstable' ? styles.ifreamActive : styles.ifream} onClick={() => {
                  let tmp = { ...Filter_obj };
                  tmp.status = 'unstable';
                  dispatch(editOnfilter(tmp));
                }}>
                  <Row justify="start">
                    <Col span={4}>
                      <HomeNomal fill={"#FAB52F"} />
                    </Col>
                    <Col span={20}>
                      <div style={{ padding: "0 0 0 9px" }}>Unstable</div>
                    </Col>
                  </Row>
                </Button>
              </Col>
              <Col span={8}>
                <Button type="primary" style={Filter_obj.status == 'offline' ? styles.ifreamActive : styles.ifream} onClick={() => {
                  let tmp = { ...Filter_obj };
                  tmp.status = 'offline';
                  dispatch(editOnfilter(tmp));
                }}>
                  <Row justify="start">
                    <Col span={4}>
                      <HomeNomal fill={"#F40606"} />
                    </Col>
                    <Col span={20}>
                      <div style={{ padding: "0 0 0 9px" }}>Offline</div>
                    </Col>
                  </Row>
                </Button>
              </Col>
              <Col span={7}>
                <Button type="primary" style={Filter_obj.status == 'all' ? styles.ifreamActive : styles.ifream} onClick={() => {
                  let tmp = { ...Filter_obj };
                  tmp.status = 'all';
                  dispatch(editOnfilter(tmp));
                }}>
                  <Row justify="center" align="middle">
                    <div>All Status</div>
                  </Row>
                </Button>
              </Col>
            </Row>
          </div>
        )}
        {/* State */}
        <div style={{ margin: "0 0 35px 0" }}>
          <div style={styles.subtitle}>State</div>
          <Row gutter={[8, 8]}>
            <Col span={8}>
              <Button type="primary" style={Filter_obj.state == 'accepted' ? styles.ifreamActive : styles.ifream} onClick={() => {
                let tmp = { ...Filter_obj };
                tmp.state = 'accepted';
                dispatch(editOnfilter(tmp));
              }}>
                <Row justify="center" align="middle">
                  <div>Accepted</div>
                </Row>
              </Button>
            </Col>
            <Col span={8}>
              <Button type="primary" style={Filter_obj.state == 'unaccepted' ? styles.ifreamActive : styles.ifream} onClick={() => {
                let tmp = { ...Filter_obj };
                tmp.state = 'unaccepted';
                dispatch(editOnfilter(tmp));
              }}>
                <Row justify="center" align="middle">
                  <div>Unaccepted</div>
                </Row>
              </Button>
            </Col>
            <Col span={8}>
              <Button type="primary" style={Filter_obj.state == 'all' ? styles.ifreamActive : styles.ifream} onClick={() => {
                let tmp = { ...Filter_obj };
                tmp.state = 'all';
                dispatch(editOnfilter(tmp));
              }}>
                <Row justify="center" align="middle">
                  <div>All State</div>
                </Row>
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const styles = {
  ifream: {
    background: "#D9D9D9",
    borderRadius: "8px",
    width: "100%",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: "#122C4D",
    textAlign: "start",
    boxShadow: 'unset',
  },
  ifreamActive: {
    background: "#122C4D",
    borderRadius: "8px",
    width: "100%",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: "#FFFFFF",
    textAlign: "start",
    boxShadow: 'unset',
  },
  subtitle: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    color: "#FFFFFF",
    textAlign: "start",
    margin: "0 0 10px 0",
  },
};
export default Filterbar;
