import { Col, Row } from "antd";
import _ from "lodash";
const Home_Icon = (props) => {
  const styles = {
    sizetext: {
      fontFamily: "Arial Narrow",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "36px",
      textAlign: "center",
      lineHeight: "41px",
    },
    sizetextsmall: {
      fontFamily: "Arial Narrow",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "20px",
      textAlign: "center",
    },
    name: {
      fontFamily: "Arial Narrow",
      fontStyle: "normal",
      fontWeight: "700",
      color: props.color,
      marginTop: "5px",
    },
  };
  return (
    <>
      <Row
        justify={"center"}
        align={"middle"}
        style={{ padding: 0, margin: 0 }}
      >
        {/* <Col> */}
        <svg
          width="81"
          height="98"
          viewBox="0 0 81 98"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M70.628 37.2262L44.1817 9.8945C43.2486 8.7558 41.9003 8.13407 40.5521 8.03078C39.1004 8.13404 37.7521 8.85899 36.9225 9.8945L10.371 37.2262C8.50405 39.1931 7.571 41.7818 7.571 44.3697V94.2727C7.571 95.2042 7.15583 96.1365 6.43031 96.8613C5.70411 97.5862 4.77101 98 3.73377 98C1.65932 98 0.104179 96.3436 0 94.3767V42.6108C0 39.2979 1.45171 36.0884 4.25238 33.0861L32.7735 3.47658C36.6107 -0.768414 43.1445 -1.18213 47.3969 2.5451C47.7079 2.85559 48.019 3.16609 48.33 3.47658L76.7476 32.9828C79.6518 35.9851 81 39.1947 81 42.5075V87.2335C81 90.6503 80.1703 93.3422 78.3035 95.1017C76.4365 96.9655 73.7399 98.0009 71.1476 97.7936H18.4619C17.5288 97.7936 16.595 97.3791 15.9731 96.6549C14.6248 95.2057 14.6248 92.9276 15.9731 91.4785C16.5951 90.7535 17.5289 90.3398 18.4619 90.3398H69.1762C71.9763 90.3398 73.4286 88.8906 73.4286 85.9915V44.3715C73.4286 41.7836 72.4955 39.195 70.6286 37.228L70.628 37.2262Z"
            fill={props.color}
          />
          {props.count.toString().length == 1 && (
            <text
              x="30"
              y="75"
              className="sizetext"
              style={styles.sizetext}
              fill="#FFFFFF"
            >
              {props.count}
            </text>
          )}
          {props.count.toString().length == 2 && (
            <text
              x="25"
              y="75"
              className="sizetext"
              style={styles.sizetext}
              fill="#FFFFFF"
            >
              {props.count}
            </text>
          )}
          {props.count.toString().length == 3 && (
            <text
              x="17"
              y="75"
              className="sizetext"
              style={styles.sizetext}
              fill="#FFFFFF"
            >
              {props.count}
            </text>
          )}
          {props.count.toString().length == 4 && (
            <text
              x="7"
              y="75"
              className="sizetext"
              style={styles.sizetext}
              fill="#FFFFFF"
            >
              {props.count}
            </text>
          )}
        </svg>
        {/* </Col> */}
      </Row>
      <Row className="statusName" style={styles.name} justify={"center"}>
        {props.name}
      </Row>
    </>
  );
};

const HomeShow_Filter = (props) => {
  const styles = {
    sizetext: {
      fontFamily: "Arial Narrow",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "36px",
      textAlign: "center",
      lineHeight: "41px",
    },
    sizetextsmall: {
      fontFamily: "Arial Narrow",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "20px",
      textAlign: "center",
    },
    name: {
      fontFamily: "Arial Narrow",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "28px",
      color: props.color,
      marginTop: "5px",
      lineHeight: "32px",
    },
  };
  return (
    <>
      <Row justify={"start"} align={"middle"} style={{ padding: 0, margin: 0 }}>
        <svg
          width="45"
          height="45"
          viewBox="0 0 81 98"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M70.628 37.2262L44.1817 9.8945C43.2486 8.7558 41.9003 8.13407 40.5521 8.03078C39.1004 8.13404 37.7521 8.85899 36.9225 9.8945L10.371 37.2262C8.50405 39.1931 7.571 41.7818 7.571 44.3697V94.2727C7.571 95.2042 7.15583 96.1365 6.43031 96.8613C5.70411 97.5862 4.77101 98 3.73377 98C1.65932 98 0.104179 96.3436 0 94.3767V42.6108C0 39.2979 1.45171 36.0884 4.25238 33.0861L32.7735 3.47658C36.6107 -0.768414 43.1445 -1.18213 47.3969 2.5451C47.7079 2.85559 48.019 3.16609 48.33 3.47658L76.7476 32.9828C79.6518 35.9851 81 39.1947 81 42.5075V87.2335C81 90.6503 80.1703 93.3422 78.3035 95.1017C76.4365 96.9655 73.7399 98.0009 71.1476 97.7936H18.4619C17.5288 97.7936 16.595 97.3791 15.9731 96.6549C14.6248 95.2057 14.6248 92.9276 15.9731 91.4785C16.5951 90.7535 17.5289 90.3398 18.4619 90.3398H69.1762C71.9763 90.3398 73.4286 88.8906 73.4286 85.9915V44.3715C73.4286 41.7836 72.4955 39.195 70.6286 37.228L70.628 37.2262Z"
            fill={props.color}
          />
        </svg>
      </Row>
    </>
  );
};

const Homefullfill = (props) => {
  return (
    <>
      <svg
        width="24"
        height="28"
        viewBox="0 0 24 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.1853 10.6361L12.627 2.827C12.3604 2.50166 11.975 2.32402 11.5897 2.29451C11.1748 2.32401 10.7895 2.53114 10.5524 2.827L2.96401 10.6361C2.43044 11.198 2.16377 11.9377 2.16377 12.6771V26.9351C2.16377 27.2012 2.04512 27.4676 1.83777 27.6747C1.63022 27.8818 1.36354 28 1.0671 28C0.474229 28 0.029774 27.5267 0 26.9648V12.1745C0 11.228 0.414896 10.311 1.21532 9.45317L9.36658 0.993308C10.4633 -0.219547 12.3306 -0.337751 13.5459 0.727171C13.6348 0.815881 13.7237 0.904597 13.8126 0.993308L21.9343 9.42366C22.7643 10.2815 23.1496 11.1985 23.1496 12.145V24.9238C23.1496 25.9001 22.9125 26.6692 22.3789 27.1719C21.8454 27.7044 21.0747 28.0003 20.3338 27.941H5.27638C5.0097 27.941 4.7428 27.8226 4.56506 27.6157C4.17973 27.2016 4.17973 26.5507 4.56506 26.1367C4.74285 25.9296 5.00972 25.8114 5.27638 25.8114H19.7704C20.5706 25.8114 20.9857 25.3973 20.9857 24.569V12.6776C20.9857 11.9382 20.719 11.1986 20.1855 10.6366L20.1853 10.6361Z"
          fill={props.color}
        />
        <path
          d="M3.8584 13.2786V22.2677C3.8584 23.3636 4.74678 24.252 5.84265 24.252H17.0867C18.1826 24.252 19.071 23.3636 19.071 22.2677V13.2451C19.071 12.7388 18.8774 12.2516 18.5299 11.8833L13.0871 6.1155C12.3195 5.30209 11.0317 5.28343 10.2408 6.07426L4.43957 11.8755C4.06745 12.2476 3.8584 12.7523 3.8584 13.2786Z"
          fill={props.color}
        />
      </svg>
    </>
  );
};
const Home_full_fun = (props) => {
  const styles = {
    sizetext: {
      fontFamily: "Arial Narrow",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "28px",
      lineHeight: "32px",
      textAlign: "center",
    },
    sizetextsmall: {
      fontFamily: "Arial Narrow",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "22px",
      lineHeight: "32px",
      textAlign: "center",
    },
    name: {
      fontFamily: "Arial Narrow",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
    },
    circles: {
      backgroundColor: props.data.color,
      width: "12px",
      height: "12px",
      borderRadius: "50%",
      marginRight: "5px",
    },
  };

  return (
    <>
      <Row justify={"start"} align={"middle"}>
        <svg
          width="104"
          height="101"
          viewBox="0 0 104 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ margin: "0px 0 0 8px" }}
        >
          {/* items  */}
          {props.data.service_request.resident && (
            <path
              d="M98.4527 85.4486C98.3485 86.7002 97.4618 87.8998 96.2102 87.8998H95.9493C96.3404 87.0653 96.549 86.1267 96.549 85.1358C96.549 84.4317 96.4448 83.7538 96.2362 83.1019H97.1489C96.184 80.1812 93.6026 78.0431 90.5778 78.0171C87.5529 78.0431 84.9714 80.1554 84.0066 83.1019H85.0236C84.8149 83.7277 84.7107 84.4317 84.7107 85.1358C84.7107 86.1006 84.9194 87.0655 85.3104 87.8998H84.9454C83.6938 87.8998 82.7811 86.6742 82.7028 85.4486C82.3378 80.9377 86.145 77.0261 90.5778 77C95.0107 77.0262 98.8178 80.9376 98.4527 85.4486ZM91.4122 87.3261C91.7512 87.3261 92.038 87.4825 92.1945 87.7433H94.9065C95.3237 86.9872 95.5324 86.1005 95.5324 85.1357C95.5324 82.4499 93.4203 79.8684 90.6301 79.8684C87.2664 79.8684 85.102 83.4147 85.8842 86.5178C86.4319 88.6821 88.3354 90.4292 90.6301 90.4292C92.0381 90.4292 93.2899 89.7773 94.1764 88.7603H92.1947C92.0383 89.0211 91.7514 89.1776 91.4124 89.1776C90.8909 89.1776 90.4736 88.7603 90.4736 88.2388C90.4996 87.7433 90.9169 87.3261 91.4122 87.3261ZM101.556 95.7486C101.112 93.4019 99.0523 91.6808 96.6274 91.6808H94.8543L91.9858 98.4867L91.4122 93.2715L91.9336 91.6808H89.4825L90.0039 93.2715L89.4303 98.4867L86.5618 91.6808H84.7887C82.3897 91.6808 80.3296 93.3757 79.8604 95.7486L79 100.442H102.468L101.556 95.7486Z"
              fill={props.data.color}
            />
          )}
          {props.data.service_request.juristic && (
            <path
              d="M98.2222 39H83.7778C82.2444 39 81 40.3125 81 41.9167V64.0833C81 65.6933 82.2444 67 83.7778 67H98.2222C99.7556 67 101 65.6933 101 64.0833V41.9167C101 40.3125 99.7556 39 98.2222 39ZM89.3333 40.7517H92.6667C92.9736 40.7517 93.2222 41.0127 93.2222 41.335C93.2222 41.6573 92.9736 41.9183 92.6667 41.9183H89.3333C89.0264 41.9183 88.7778 41.6573 88.7778 41.335C88.7778 41.0131 89.0267 40.7517 89.3333 40.7517ZM91 65.145C90.3871 65.145 89.8889 64.6219 89.8889 63.9783C89.8889 63.3348 90.3871 62.8117 91 62.8117C91.6129 62.8117 92.1111 63.3348 92.1111 63.9783C92.1111 64.6216 91.6129 65.145 91 65.145ZM98.7778 60.5848C98.7778 60.907 98.5291 61.1681 98.2222 61.1681H83.7778C83.4709 61.1681 83.2222 60.907 83.2222 60.5848V44.2514C83.2222 43.9292 83.4709 43.6681 83.7778 43.6681H98.2222C98.5291 43.6681 98.7778 43.9292 98.7778 44.2514V60.5848Z"
              fill={props.data.color}
            />
          )}
          {props.data.service_request.both_request && (
            <path
              d="M100.019 27.9077V26.192H100.652C101.396 26.192 102 25.5869 102 24.844V21.4121C102 20.6688 101.395 20.064 100.652 20.064H100.019V18.3482C100.019 17.6049 99.4138 17.0002 98.6706 17.0002L81.348 17C80.6044 17 80 17.6048 80 18.348V27.9079C80 28.6511 80.6046 29.2559 81.348 29.2559H98.6702C99.4137 29.2557 100.019 28.6509 100.019 27.9077L100.019 27.9077ZM81.3481 28.0304C81.2808 28.0304 81.2254 27.9754 81.2254 27.9077L81.2256 18.3479C81.2256 18.2804 81.2806 18.2252 81.3483 18.2252H98.6704C98.7379 18.2252 98.7928 18.2802 98.7928 18.3479V27.9077C98.7928 27.9752 98.7379 28.0304 98.6704 28.0304H81.3481ZM81.9305 19.0221V27.2336H85.4085V19.0221H81.9305Z"
              fill={props.data.color}
            />
          )}
          {/* items  */}
          {/* 1 star */}
          {props.data.vip_type == 1 && (
            <path
              d="M34.6222 0.37936C34.5022 0.146386 34.262 0 33.9999 0C33.7379 0 33.4978 0.146371 33.3777 0.37936L31.4775 4.06736L27.5667 4.82557C27.3188 4.87362 27.1159 5.05163 27.036 5.29118C26.9561 5.53084 27.0114 5.79486 27.1807 5.98225L29.9388 9.03273L29.4119 13.2124C29.3795 13.469 29.4912 13.7225 29.7024 13.8716C29.9135 14.0208 30.1897 14.0416 30.4207 13.9254L34 12.1261L37.5793 13.9254C37.8102 14.0416 38.0864 14.0208 38.2976 13.8716C38.5088 13.7225 38.6205 13.469 38.5881 13.2124L38.0611 9.03273L40.8193 5.98225C40.9887 5.79486 41.0439 5.53083 40.964 5.29118C40.8841 5.05164 40.6812 4.87363 40.4333 4.82557L36.5225 4.06736L34.6222 0.37936Z"
              fill={props.data.color}
            />
          )}
          {/* 1 star */}
          {/* 2 star  vvip */}
          {props.data.vip_type == 2 && (
            <>
              <path
                d="M25.6222 0.37936C25.5022 0.146386 25.262 0 24.9999 0C24.7379 0 24.4978 0.146371 24.3777 0.37936L22.4775 4.06736L18.5667 4.82557C18.3188 4.87362 18.1159 5.05163 18.036 5.29118C17.9561 5.53084 18.0114 5.79486 18.1807 5.98225L20.9388 9.03273L20.4119 13.2124C20.3795 13.469 20.4912 13.7225 20.7024 13.8716C20.9135 14.0208 21.1897 14.0416 21.4207 13.9254L25 12.1261L28.5793 13.9254C28.8102 14.0416 29.0864 14.0208 29.2976 13.8716C29.5088 13.7225 29.6205 13.469 29.5881 13.2124L29.0611 9.03273L31.8193 5.98225C31.9887 5.79486 32.0439 5.53083 31.964 5.29118C31.8841 5.05164 31.6812 4.87363 31.4333 4.82557L27.5225 4.06736L25.6222 0.37936Z"
                fill={props.data.color}
              />
              <path
                d="M43.6222 0.37936C43.5022 0.146386 43.262 0 42.9999 0C42.7379 0 42.4978 0.146371 42.3777 0.37936L40.4775 4.06736L36.5667 4.82557C36.3188 4.87362 36.1159 5.05163 36.036 5.29118C35.9561 5.53084 36.0114 5.79486 36.1807 5.98225L38.9388 9.03273L38.4119 13.2124C38.3795 13.469 38.4912 13.7225 38.7024 13.8716C38.9135 14.0208 39.1897 14.0416 39.4207 13.9254L43 12.1261L46.5793 13.9254C46.8102 14.0416 47.0864 14.0208 47.2976 13.8716C47.5088 13.7225 47.6205 13.469 47.5881 13.2124L47.0611 9.03273L49.8193 5.98225C49.9887 5.79486 50.0439 5.53083 49.964 5.29118C49.8841 5.05164 49.6812 4.87363 49.4333 4.82557L45.5225 4.06736L43.6222 0.37936Z"
                fill={props.data.color}
              />
            </>
          )}
          {/* 2 star */}
          {/* house body */}
          <path
            d="M60.1646 48.9082L37.6363 25.481C36.8414 24.505 35.6929 23.9721 34.5444 23.8835C33.3078 23.972 32.1592 24.5934 31.4525 25.481L8.83457 48.9082C7.24419 50.5941 6.44937 52.813 6.44937 55.0312V97.8052C6.44937 98.6036 6.09571 99.4027 5.47767 100.024C4.85906 100.645 4.0642 101 3.18062 101C1.41349 101 0.0887448 99.5802 0 97.8943V53.5236C0 50.684 1.23665 47.9329 3.6224 45.3595L27.9181 19.9799C31.1869 16.3414 36.7527 15.9867 40.3751 19.1815C40.6401 19.4476 40.905 19.7138 41.17 19.9799L65.3776 45.271C67.8515 47.8444 69 50.5954 69 53.435V91.7715C69 94.7003 68.2933 97.0076 66.7029 98.5158C65.1126 100.113 62.8155 101.001 60.6072 100.823H15.7268C14.932 100.823 14.1365 100.468 13.6067 99.847C12.4582 98.6049 12.4582 96.6522 13.6067 95.4101C14.1366 94.7887 14.932 94.4341 15.7268 94.4341H58.9279C61.3131 94.4341 62.5503 93.1919 62.5503 90.707V55.0327C62.5503 52.8145 61.7555 50.5957 60.1651 48.9097L60.1646 48.9082Z"
            fill={props.data.color}
          />
          {/* house body */}
          {props.data.room_number.toString().length == 1 && (
            <text x="30" y="80" style={styles.sizetext} fill="#FFFFFF">
              {props.data.room_number}
            </text>
          )}
          {props.data.room_number.toString().length == 2 && (
            <text x="25" y="80" style={styles.sizetext} fill="#FFFFFF">
              {props.data.room_number}
            </text>
          )}
          {props.data.room_number.toString().length == 3 && (
            <text x="16" y="80" style={styles.sizetext} fill="#FFFFFF">
              {props.data.room_number}
            </text>
          )}
          {props.data.room_number.toString().length == 4 && (
            <text x="9" y="80" style={styles.sizetext} fill="#FFFFFF">
              {props.data.room_number}
            </text>
          )}
          {props.data.room_number.toString().length == 5 && (
            <text x="10" y="80" style={styles.sizetextsmall} fill="#FFFFFF">
              {props.data.room_number}
            </text>
          )}
        </svg>
      </Row>

      {props.data.status == 2 && (
        <Row
          justify={"start"}
          align={"middle"}
          style={{ margin: "6px 0px 0 0px" }}
        >
          <div style={styles.circles}></div>
          <div style={styles.name}>{props.data.count_device}&nbsp;</div>
          <div style={styles.name}>
            {props.data.count_device > 1 ? "Devices" : "Device"}
          </div>
        </Row>
      )}
    </>
  );
};
export { Home_Icon, Homefullfill, Home_full_fun, HomeShow_Filter };
