function IamRead(data, cb) {
    let token = localStorage.getItem('token')
    token = JSON.parse(token)
    fetch(`${process.env.REACT_APP_API_URL}api/notification?site=${data.site}&id=${data.id}&read=true`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((json) => cb(null, json))
      .catch((err) => cb(err, null));
  }
  export default IamRead;
  