import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Collapse,
  Table,
  Button,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import ScrollContainer from "react-indiana-drag-scroll";
import { Home_full_fun } from "../../img/room_icon/home";
import {
  Residents_Htr_Sv,
  Juristic_Htr_Sv,
  Battery_Htr_Sv,
} from "../../img/project_icon/services";
import { ReactSVG } from "react-svg";
import {
  LeftOutlined,
  RightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { getTitle, editTitle } from "../../slice/projectSlice";
import Getroomdetail from "../../api/room/Getroomdetail";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
const { Paragraph } = Typography;
const { Panel } = Collapse;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 36,
      color: "#ffffff",
    }}
    spin
  />
);
const Detail_room = (prop) => {
  const dispatch = useDispatch();
  const [isFetch, setIsfetch] = useState(true);
  const [activeFloor, setActiveFloor] = useState(1);
  const [ellipsis, setEllipsis] = useState(true);
  const [roomDetail, setRoomdetail] = useState({});
  const [zones, setZones] = useState([]);
  const [tmpzones, setTmpZones] = useState([]);
  const columns = [
    {
      title: "Device",
      dataIndex: "device",
      fixed: true,
      width: 100,
      key: "device",
      align: "start",
      render: (text) => {
        {
          let tmp = JSON.parse(text);
          if (tmp.name.search("S") > -1) {
            if (tmp.status == "true") {
              return (
                <div style={_.merge(styles.online_status)}>{tmp.name}</div>
              );
            } else if (tmp.status == "false") {
              return (
                <div style={_.merge(styles.offline_status)}>{tmp.name}</div>
              );
            } else {
              return <div style={_.merge(styles.fail_status)}>{tmp.name}</div>;
            }
          } else {
            if (tmp.status == "true") {
              return (
                <div style={_.merge(styles.reactangle_online_status)}>
                  {tmp.name}
                </div>
              );
            } else if (tmp.status == "false") {
              return (
                <div style={_.merge(styles.reactangle_offline_status)}>
                  {tmp.name}
                </div>
              );
            }
          }
        }
      },
    },
    {
      title: "Device ID",
      dataIndex: "device_id",
      key: "device_id",
      align: "start",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (device_id) => (
        <Tooltip placement="topLeft" title={device_id}>
          <span style={styles.textColumn}>{device_id}</span>
        </Tooltip>
      ),
    },
    {
      title: "Last Updated",
      dataIndex: "updateAt",
      key: "updateAt",
      align: "center",
      width: 100,
      render: (updateAt) => {
        {
          const myString = updateAt;
          const splits = myString.split(" ");
          return (
            <Paragraph
              style={{ color: "white" }}
              ellipsis={
                ellipsis
                  ? { rows: 2, expandable: false, symbol: "more" }
                  : false
              }
            >
              <div style={styles.textColumn}>{splits[0]}</div>
              <div style={styles.textColumn}>{splits[1]}</div>
            </Paragraph>
          );
        }
      },
    },
    {
      title: "Error Hours",
      dataIndex: "error_hour",
      key: "error_hour",
      align: "center",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (error_hour) => (
        <Tooltip placement="topLeft" title={error_hour}>
          <span style={styles.textColumn}>{error_hour}</span>
        </Tooltip>
      ),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      align: "center",
      width: 100,
      render: (state) => {
        {
          const myString = state;
          const splits = myString.split(" ");
          return (
            <Paragraph
              style={{ color: "white" }}
              ellipsis={
                ellipsis
                  ? { rows: 3, expandable: false, symbol: "more" }
                  : false
              }
            >
              <div style={styles.textColumn}>{splits[0]}</div>
              <div style={styles.textColumn}>{splits[1]}</div>
              <div style={styles.textColumn}>{splits[2]}</div>
            </Paragraph>
          );
        }
      },
    },
  ];
  const Params = useParams();
  useEffect(() => {
    setIsfetch(true);
    Getroomdetail(
      { project: Params.project, room: Params.room },
      (err, res) => {
        if (!err && res.statusCode === 200) {
          setRoomdetail(res.data.room);
          setZones(res.data.zone);
          prop.setStatus(res.data.room.status);
          dispatch(editTitle(String(" - Floor " + res.data.room.floor)));
          setIsfetch(false);
        }
      }
    );
  }, [Params.room]);
  return (
    <Spin indicator={antIcon} spinning={isFetch}>
      {!isFetch && (
        <Row gutter={[23, 23]}>
          <Col xs={12} sm={12} md={12} lg={12} xl={10} xxl={10}>
            <Row
              justify="space-between"
              align="middle"
              style={styles.boxfilter}
            >
              <Row align="middle">
                <Home_full_fun data={roomDetail} />
                <div style={styles.textNameRoom}>
                  ROOM {roomDetail.room_number}
                </div>
              </Row>
              <Row align="middle">
                {roomDetail.service_request.resident && (
                  <Residents_Htr_Sv width={45} color={roomDetail.color} />
                )}
                {roomDetail.service_request.juristic && (
                  <Juristic_Htr_Sv width={45} color={roomDetail.color} />
                )}
                {roomDetail.pir_battery && (
                  <Battery_Htr_Sv width={45} color={roomDetail.color} />
                )}
              </Row>
            </Row>
            <div>
              <div style={styles.text_zone}>Zone</div>
              <div
                style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.5)" }}
              ></div>
            </div>

            <Row style={{ padding: "13px 0" }}>
              <Col span={24}>
                <ScrollContainer
                  className="collapse-frame tableProject-detail"
                  style={styles.overflow}
                >
                  <Collapse
                    bordered={false}
                    ghost
                    defaultActiveKey={[
                      ...zones
                        .filter((e) => {
                          return e.status == "false";
                        })
                        .map((e, i) => e.id),
                    ]}
                    expandIcon={(panelProps) => {
                      if (panelProps.status == "false") {
                        return (
                          <div
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              backgroundColor: "#F40606",
                            }}
                          ></div>
                        );
                      } else {
                        return (
                          <div
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              backgroundColor: "#66B040",
                            }}
                          ></div>
                        );
                      }
                    }}
                  >
                    {zones.map((zone, i) => {
                      return (
                        <>
                          <Panel
                            header={zone.name}
                            key={zone.id}
                            style={styles.panel}
                            status={zone.status}
                          >
                            <Table
                              columns={columns}
                              dataSource={zone.data}
                              pagination={false}
                              bordered={false}
                            />
                          </Panel>
                        </>
                      );
                    })}
                  </Collapse>
                </ScrollContainer>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={14} xxl={14}>
            <div style={styles.boxSvg}>
              <ReactSVG
                src={`${process.env.REACT_APP_API_URL}${roomDetail.pathsvg[
                  activeFloor - 1
                ].replace("/", "")}`}
                beforeInjection={(svg) => {
                  svg.setAttribute("style", "width: 550px");
                }}
                afterInjection={(svg) => {
                  svg.setAttribute("style", "width: 553px;height:670px");
                  const devices = [...svg.querySelectorAll(".device")];
                  if (devices.length > 0) {
                    for (let index = 0; index < devices.length; index++) {
                      let id = devices[index].getAttribute("data-name");
                      let deviceCild = devices[index].children;
                      let tmp = zones.find((e) => e.name === id);
                      if (!_.isEmpty(tmp)) {
                        for (var j = 0; j < deviceCild.length; j++) {
                          if (deviceCild[j].tagName == "g") {
                            let deviceCildCild = deviceCild[j].children;
                            for (let i = 0; i < deviceCildCild.length; i++) {
                              if (deviceCildCild[i].tagName == "g") {
                                tmp.data.map((d) => {
                                  let json = JSON.parse(d.device);
                                  if (
                                    json.name ==
                                    deviceCild[j].getAttribute("id")
                                  ) {
                                    if (json.status == "true") {
                                      deviceCildCild[i].style.fill = "#66B040";
                                    } else if (json.status == "false") {
                                      deviceCildCild[i].style.fill = "#F40606";
                                    }
                                  }
                                });
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }}
                loading={() => <span>Loading</span>}
                fallback={() => <span>Error!</span>}
              />
            </div>
            {roomDetail.pathsvg.length > 1 && (
              <Row
                justify="space-between"
                align="middle"
                style={{ margin: "22px 0 0 0" }}
              >
                <Button
                  type="primary"
                  shape="circle"
                  icon={<LeftOutlined />}
                  size="middle"
                  style={styles.btn_color}
                  onClick={() => {
                    if (activeFloor > 1) {
                      setActiveFloor(activeFloor - 1);
                    }
                  }}
                />
                <div style={styles.text_zoneName}>
                  Floor plan - Floor {activeFloor}
                </div>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<RightOutlined />}
                  size="middle"
                  style={styles.btn_color}
                  onClick={() => {
                    if (activeFloor < roomDetail.pathsvg.length) {
                      setActiveFloor(activeFloor + 1);
                    }
                  }}
                />
              </Row>
            )}
          </Col>
        </Row>
      )}
    </Spin>
  );
};
const styles = {
  overflow: {
    // overflow: "auto",
    height: "617px",
  },
  boxRoom: {
    background: "#122C4D",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
  },
  boxSvg: {
    background: "rgba(0, 0, 0, 0.15)",
    boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    padding: "29px 23px 37px 23px",
    height: "765px",
    overflow: "overlay",
  },
  panel: {
    textAlign: "start",
    color: "#FFFFFF !important",
    marginBottom: 6,
  },
  reactangle_online_status: {
    background: "#66B040",
    height: "50px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#252525",
    fontSize: "24px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "28px",
  },
  reactangle_offline_status: {
    background: "#F40606",
    height: "50px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#252525",
    fontSize: "24px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "28px",
  },
  online_status: {
    background: "#66B040",
    borderRadius: "50%",
    height: "50px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#252525",
    fontSize: "24px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "28px",
  },
  offline_status: {
    background: "#F40606",
    borderRadius: "50%",
    height: "50px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#252525",
    fontSize: "24px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "28px",
  },
  fail_status: {
    background: "#FAB52F",
    borderRadius: "50%",
    height: "50px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#252525",
    fontSize: "24px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "28px",
  },
  boxfilter: {
    background: "#122C4D",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    padding: "18px 20px",
    margin: "0 0 16px 0",
  },
  textNameRoom: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    color: "#FFFFFF",
    textAlign: "start",
    padding: "0 6px",
  },
  text_zone: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    color: "#FFFFFF",
    textAlign: "start",
    margin: "10px 0 ",
  },
  text_zoneName: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    color: "#FFFFFF",
  },
  textColumn: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: "#FFFFFF",
  },
  btn_color: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    color: "#000000",
  },
};
export default Detail_room;
