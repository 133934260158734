import React, { useEffect, useState } from "react";
import Component from "../../components";
import { useLocation, useNavigate } from 'react-router-dom'
import _ from "lodash";
const Vetifyemail = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (_.isEmpty(state)) {
      navigate('/login');
    }
  }, []);

  return (
    <div>
      <Component.CardCarousel />
      <Component.CardVerify email={state?.email} />
    </div>
  );
};

export default Vetifyemail;
