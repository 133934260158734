function Fetchlogin(credentials, cb) {
  fetch(`${process.env.REACT_APP_API_URL}api/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((data) => data.json())
    .then((json) => cb(null, json))
    .catch((err) => cb(err, null));
}
export default Fetchlogin;
