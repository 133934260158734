import React, { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { Row, Col, Input, Button, Checkbox, Form, Alert, Layout } from "antd";
import Fetchlogin from "../../api/auth/login";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import Home_icon from "../../img/home_icon/index.js";
import Avatar from "../../img/home_icon/auth/avatar.js";
const { Header, Footer, Sider, Content } = Layout;
const Cardlogin = ({ setToken }) => {
  const [form] = Form.useForm();
  const [verifynoti, setVerifyNoti] = useState(false);
  const [verify, setVerify] = useState(false);
  const [verifyborder, setVerifyBorder] = useState(false);
  var navigate = useNavigate();
  useEffect(() => {
    form.resetFields();
  }, []);
  return (
    <Layout className="boxframe">
      <Header style={styles.Header}>
        <Home_icon />
      </Header>
      <Content style={styles.my_auto}>
        <div className="container">
          <Row justify={"center"} align={"middle"}>
            {/* <Col span={5}></Col> */}
            <Col
              span={24}
              // style={{ textAlign: "start", padding: "67.5px 0 0 0" }}
            >
              <div style={{ textAlign: "center" }}>
                <Avatar />
              </div>

              <div>
                <div style={{ minHeight: "62px", margin: "14px 0 13px 0" }}>
                  {verifynoti && (
                    <Alert
                      message="The username or password you entered is incorrect, Please try again."
                      type="error"
                      showIcon
                      style={styles.alert_mss}
                    />
                  )}
                </div>
                <Form
                  layout={"vertical"}
                  form={form}
                  autoComplete="off"
                  onFinish={(e) => {
                    Fetchlogin(e, (err, res) => {
                      if (err) {
                        console.log("error", err);
                      }
                      if (res && res.status == "true") {
                        setToken(res);
                        navigate("/overview");
                        // window.location="/overview";
                      } else {
                        setVerifyNoti(true);
                        setVerify(true);
                        setVerifyBorder(true);
                      }
                    });
                  }}
                >
                  <div style={verify ? styles.input_err : styles.input}>
                    <Form.Item
                      label={null}
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                        {
                          transform(value) {
                            if (value.length == 0) {
                              setVerify(true);
                              setVerifyNoti(false);
                            } else {
                              setVerify(false);
                              setVerifyBorder(false);
                              setVerifyNoti(false);
                            }
                            return value;
                          },
                        },
                      ]}
                    >
                      <Input
                        placeholder="Username"
                        bordered={false}
                        required
                        style={styles.font_input}
                      />
                    </Form.Item>
                  </div>

                  <div
                    style={verifyborder ? styles.input_err : styles.input}
                    className="input_ps"
                  >
                    <Form.Item
                      label={null}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          transform(value) {
                            if (value.length == 0) {
                              setVerifyBorder(true);
                              setVerifyNoti(false);
                            } else {
                              setVerify(false);
                              setVerifyBorder(false);
                              setVerifyNoti(false);
                            }
                            return value;
                          },
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Password"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        required
                        bordered={false}
                        style={styles.font_input}
                      />
                    </Form.Item>
                  </div>
                  <Row justify="space-between" style={{ margin: "0 0 40px 0" }}>
                    <Checkbox style={styles.textRemember}>Remember me</Checkbox>
                    <a
                      style={styles.textforgot}
                      onClick={() => {
                        navigate("/forgotpassword");
                      }}
                    >
                      Forgot password?
                    </a>
                  </Row>

                  <Button
                    type="primary"
                    style={styles.buttonlogin}
                    htmlType="submit"
                  >
                    LOG IN
                  </Button>
                </Form>
              </div>
            </Col>
            {/* <Col span={5}></Col> */}
          </Row>
        </div>
      </Content>
      <Footer style={styles.Footer}>
        <Row style={styles.bottom}>
          <span>&#169;</span>&nbsp;{new Date().getFullYear()}, Obotrons
          Corporation Limited
        </Row>
      </Footer>
    </Layout>
  );
};

const styles = {
  Header: {
    background: "transparent",
    padding: "20px 18px 20px 18px",
    height: "100px",
  },
  Footer: {
    background: "transparent",
    padding: "20px",
  },
  my_auto: {
    marginBottom: "auto",
    marginTop: "auto",
    flex: "unset",
  },
  bottom: {
    justifyContent: "center",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(255, 255, 255, 0.55)",
  },
  input: {
    width: "100%",
    height: "75px",
    background: "rgba(0, 0, 0, 0.1)",
    borderBottom: "2px solid rgba(255, 255, 255, 0.9)",
    borderRadius: "10px",
    margin: "0 0 40px 0",
    color: "#FFFFFF",
  },
  input_err: {
    width: "100%",
    height: "75px",
    background: "rgba(0, 0, 0, 0.1)",
    borderBottom: "2px solid #F40606",
    borderRadius: "10px",
    margin: "0 0 40px 0",
    color: "#FFFFFF",
  },
  font_input: {
    height: "75px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    color: "rgba(255, 255, 255, 0.8)",
    // line-height: 28px;
  },
  buttonlogin: {
    width: "100%",
    height: "75px",
    background: "rgba(18, 44, 77, 0.8)",
    borderRadius: "10px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    color: "rgba(255, 255, 255, 0.8)",
    margin: "40px 0",
    boxShadow: "none",
  },
  textRemember: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    color: "rgba(255, 255, 255, 0.8)",
  },
  textforgot: {
    textDecorationLine: "underline",
    fontFamily: "Arial Narrow",
    fontStyle: "italic",
    fontWeight: "400",
    fontSize: "20px",
    color: "rgba(255, 255, 255, 0.8)",
  },
  alert_mss: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#F40606",
    background: "#FFEEEE",
    border: "1px solid #F40606",
    borderRadius: "10px",
    padding: "21px 15px",
  },
};
export default Cardlogin;
