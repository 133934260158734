import React, { useEffect, useState } from 'react';
import Component from '../../components';
import { Col, Row } from 'antd';

const Colleft = () => {
    return (
        <>
            <Row gutter={[14, 14]} align={'center'}>
                <Col xs={24} sm={24} md={24} lg={24} xl={9} xxl={10} ><Component.Room_device/></Col>
                <Col xs={24} sm={24} md={24} lg={16} xl={9} xxl={10} ><Component.Room_service/></Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={4} ><Component.Pir_battery/></Col>
            </Row>
            <Row style={{marginTop:'15px'}} justify={'center'}>
                <Col span={24}><Component.Table_project/></Col>
            </Row>
        </>

    )
};
export default Colleft;
