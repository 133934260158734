import React, { useState } from "react";
import { Col, Row, Button } from "antd";
import Getmockdata from '../../api/project/detail'
import { useParams } from "react-router-dom";
import { getonFilter,editRooms,editH_filter} from '../../slice/projectSlice';
import { useSelector,useDispatch } from "react-redux";
const FooterFilterbar_project = (props) => {
  const dispatch = useDispatch();
  const Filter_obj = useSelector(getonFilter);
  const Params = useParams();
  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Button type="primary" style={styles.ifream} onClick={() => { props.this_onClose('room'); }}>
            <Row justify="center" align="middle">
              <div>Cancel</div>
            </Row>
          </Button>
        </Col>
        <Col span={12}>
          <Button type="primary" style={styles.ifreamActive}
            onClick={() => {
              Getmockdata({site:Params.project,filter:Filter_obj}, (err, res) => {
                if (!err && res.statusCode === 200) {
                  dispatch(editH_filter(Filter_obj));
                  dispatch(editRooms(res.data));
                  props.this_onClose('filter');
                }
              })
            }}
          >
            <Row justify="center" align="middle">
              <div>Filter</div>
            </Row>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const styles = {
  ifream: {
    background: "#D9D9D9",
    borderRadius: "8px",
    width: "100%",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: "#122C4D",
    textAlign: "start",
  },
  ifreamActive: {
    background: "#122C4D",
    borderRadius: "8px",
    width: "100%",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: "#FFFFFF",
    textAlign: "start",
  },
  subtitle: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    color: "#FFFFFF",
    textAlign: "start",
    margin: "0 0 10px 0",
  },
};
export default FooterFilterbar_project;
