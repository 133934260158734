import Overview from "./overview/index"
import Project from "./project/index"
import Room from "./room"
import Login from "../pages/auth/login"
import Forgotpassword from "../pages/auth/forgotpassword"
import Changepassword from "../pages/auth/changepassword"
import Newpassword from "../pages/auth/newpassword"
import Vetifyemail from "../pages/auth/vetify"
import Error404 from "../pages/error/404"
import Notifications from "../pages/notifications"
export default { Overview,Login,Project,Room,Error404,Forgotpassword,Changepassword,Vetifyemail,Newpassword,Notifications };
