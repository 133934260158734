const Getroomdetail = (data, cb) => {
    let token = localStorage.getItem('token')
    token = JSON.parse(token)
    fetch(
      `${process.env.REACT_APP_API_URL}api/room_detail?site=${data.project}&room=${data.room}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token.token}`,
          'Content-Type': 'application/json'
        },
      }
    )
      .then(res => res.json())
      .then(json => cb(null, json))
      .catch(err => cb(err, null))
    // let data_mock = {
    //     room: {
    //         room_number: "2020",
    //         vip_type: 0,
    //         color: "#FAB52F",
    //         service_request: { resident: true, juristic: true, both_request: true },
    //         pir_battery: false,
    //         status: "?",
    //         pathsvg: ['AA_03', 'AA_04'],
    //     },
    //     zone: [
    //         {
    //             id: "zone2",
    //             name: "largebedroom",
    //             status: "true",
    //             data: [
    //                 {
    //                     key: "1",
    //                     device: '{"name":"S5","status":"true"}',
    //                     device_id: "mls1",
    //                     updateAt: "12-01-2023 10:30",
    //                     error_hour: "4 Hrs.",
    //                     state: "Accepted",
    //                 },
    //                 {
    //                     key: "2",
    //                     device: '{"name":"S4","status":"true"}',
    //                     device_id: "mls1",
    //                     updateAt: "-",
    //                     error_hour: "-",
    //                     state: "Accepted",
    //                 },
    //             ],
    //         },
    //         {
    //             id: "zone3",
    //             name: "livingandkitchen",
    //             status: "false",
    //             data: [
    //                 {
    //                     key: "1",
    //                     device: '{"name":"S3","status":"true"}',
    //                     device_id: "mls1",
    //                     updateAt: "12-01-2023 10:30",
    //                     error_hour: "4 Hrs.",
    //                     state: "Accepted",
    //                 },
    //                 {
    //                     key: "2",
    //                     device: '{"name":"S2","status":"false"}',
    //                     device_id: "mls1",
    //                     updateAt: "-",
    //                     error_hour: "-",
    //                     state: "Accepted",
    //                 },
    //             ],
    //         },
    //     ]
    // }
    // cb(null, data_mock)
}

export default Getroomdetail
