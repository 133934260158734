import React, { useEffect, useState } from "react";
import { Col, Row, Carousel, Input, Button, Checkbox } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import Back from "../../img/home_icon/auth/back";
const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};
const CardRegister = (props) => {
  return (
    <div style={styles.boxframe}>
      <div>
        <Back />
      </div>
      <div style={styles.title}>Register</div>

      <div style={{ padding: "50px 80px" }}>
        <div style={styles.input}>
          <Input
            placeholder="Username"
            bordered={false}
            style={styles.font_input}
          />
        </div>
        <div style={styles.input}>
          <Input
            placeholder="Email"
            bordered={false}
            style={styles.font_input}
          />
        </div>
        <div style={styles.input}>
          <Input
            placeholder="Phone Number"
            bordered={false}
            style={styles.font_input}
          />
        </div>
        <div style={styles.input}>
          <Input.Password
            placeholder="Password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            bordered={false}
            style={styles.font_input}
          />
        </div>
        <div style={styles.input}>
          <Input.Password
            placeholder="Confirm Password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            bordered={false}
            style={styles.font_input}
          />
        </div>
        <Button type="primary" style={styles.buttonSubmit}>
          Submit
        </Button>
      </div>
      <div style={styles.bottom}>2023, Obotrons Corporation Limited</div>
    </div>
  );
};

const styles = {
  title: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "36px",
    color: "#FFFFFF",
    textAlign: "center",
  },
  boxframe: {
    width: "590px",
    // height: "100%",
    background:
      "linear-gradient(144.09deg, rgba(26, 62, 107, 0.95) 0%, rgba(18, 44, 77, 0.95) 100%)",
    backdropFilter: "blur(2px)",
    top: "0",
    right: "0",
    position: "absolute",
    minHeight: "816px",
    padding: "20px",
  },
  bottom: {
    position: "absolute",
    bottom: "20px",
    left: "203.5px",
    right: "203.5px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    color: "rgba(255, 255, 255, 0.55)",
  },
  input: {
    // width: "463px",
    height: "75px",
    background: "rgba(0, 0, 0, 0.1)",
    borderBottom: "2px solid rgba(255, 255, 255, 0.9)",
    borderRadius: "10px",
    margin: "0 0 40px 0",
  },
  font_input: {
    height: "75px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    color: "rgba(255, 255, 255, 0.8)",
    // line-height: 28px;
  },
  buttonSubmit: {
    width: "100%",
    height: "75px",
    background: "rgba(18, 44, 77, 0.8)",
    borderRadius: "10px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    color: "rgba(255, 255, 255, 0.8)",
    margin: "40px 0",
    boxShadow: "none",
  },
};
export default CardRegister;
