import React, { useEffect, useState } from 'react';
import Containerroom from '../../container';
import { Col, Row } from 'antd';

const notifications = () => {

    return (
        <Containerroom.Notifications></Containerroom.Notifications>
    )
};
export default notifications;
