import React, { useEffect, useState } from "react";
import { Col, Row, Input, Button, message, Form, Layout } from "antd";
import { useNavigate } from "react-router-dom";
import Back from "../../img/home_icon/auth/back";
import ForgotPassword from "../../img/home_icon/auth/forgotpassword.js";
import ForgotPasswordApi from "../../api/auth/forgotpassword.js";
const { Header, Footer, Sider, Content } = Layout;
const CardChangePassword = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [verifynoti, setVerifyNoti] = useState(false);
  const [verifyborder, setVerifyBorder] = useState(false);
  var user = localStorage.getItem("token");
  user = JSON.parse(user);
  const [form] = Form.useForm();
  var navigate = useNavigate();
  useEffect(() => {
    form.setFieldsValue({ email: user.email });
  }, []);
  return (
    <Layout className="boxframe">
      <Header style={styles.Header}>
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <Back />
        </div>
      </Header>
      <Content style={styles.my_auto}>
        <div className="container">
          <Row justify={"center"} align={"middle"}>
            <Col span={24}>
              <div style={styles.title}>Change Password</div>
              <div style={{ textAlign: "center", margin: "47px 0 38px 0" }}>
                <ForgotPassword />
              </div>
              <div style={styles.description}>
                Please enter the email address you used. We'll send a
                verification code to your email for reset your password.
              </div>
              <Row justify={"center"} align={"middle"}>
                <Form
                  form={form}
                  autoComplete="off"
                  onFinish={(e) => {
                    ForgotPasswordApi({ email: e.email }, (err, res) => {
                      if (!err && res.statusCode == 200) {
                        navigate("/verifyemail", { state: { email: e.email } });
                      } else if (res.statusCode == 404) {
                        setVerifyNoti(true);
                        setVerifyBorder(true);
                      } else {
                        setVerifyNoti(true);
                        setVerifyBorder(true);
                      }
                    });
                  }}
                >
                  <div style={verifyborder ? styles.input_err : styles.input}>
                    <Form.Item
                      label={null}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                          transform(value) {
                            if (value.length == 0) {
                              setVerifyBorder(true);
                            } else {
                              setVerifyBorder(false);
                              setVerifyNoti(false);
                            }
                            return value;
                          },
                        },
                        {
                          type: "email",
                          message: () => {
                            setVerifyBorder(true);
                            return "The email is incorrect, please enter your email in format : yourname@example.com";
                          },
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email"
                        bordered={false}
                        required
                        type="email"
                        style={styles.font_input}
                      />
                    </Form.Item>
                  </div>
                  {verifynoti && (
                    <div style={styles.font_input_err}>
                      Couldn't find your email account. Please try another one.
                    </div>
                  )}
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={styles.buttonSend}
                  >
                    Send Verification Code
                  </Button>
                </Form>
              </Row>
            </Col>
          </Row>
        </div>
      </Content>
      <Footer style={styles.Footer}>
        <Row style={styles.bottom}>
          <span>&#169;</span>&nbsp;{new Date().getFullYear()}, Obotrons
          Corporation Limited
        </Row>
      </Footer>
    </Layout>
  );
};

const styles = {
  Header: {
    background: "transparent",
    padding: "20px 18px 20px 18px",
    height: "100px",
  },
  Footer: {
    background: "transparent",
    padding: "20px",
  },
  my_auto: {
    marginBottom: "auto",
    marginTop: "auto",
    flex: "unset",
  },
  title: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "36px",
    color: "#FFFFFF",
    textAlign: "center",
    // margin: "63px 0 0 0",
  },
  description: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#FFFFFF",
    textAlign: "center",
    margin: "0 0 128px 0 ",
  },
  bottom: {
    justifyContent: "center",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(255, 255, 255, 0.55)",
  },
  input: {
    width: "383px",
    height: "75px",
    background: "rgba(0, 0, 0, 0.1)",
    borderBottom: "2px solid rgba(255, 255, 255, 0.9)",
    borderRadius: "10px",
    // margin: "0 0 40px 0",
    color: "#FFFFFF",
    // margin: "0 0 106px 0",
  },
  input_err: {
    width: "383px",
    height: "75px",
    background: "rgba(0, 0, 0, 0.1)",
    borderBottom: "2px solid #F40606",
    borderRadius: "10px",
    // margin: "0 0 40px 0",
    color: "#FFFFFF",
  },
  font_input: {
    height: "75px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    color: "rgba(255, 255, 255, 0.8)",
    // line-height: 28px;
  },
  font_input_err: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.5714285714285714",
    color: "#ff4d4f",
  },
  buttonSend: {
    width: "381px",
    height: "75px",
    background: "rgba(18, 44, 77, 0.8)",
    borderRadius: "10px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    color: "rgba(255, 255, 255, 0.8)",
    margin: "146px 0 40px 0",
    boxShadow: "none",
  },
};
export default CardChangePassword;
