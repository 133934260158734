import React, { useEffect, useState } from "react";
import Component from "../../components";

const Changepassword = () => {
  return (
    <div>
      <Component.CardCarousel />
      <Component.CardChangePassword />
    </div>
  );
};

export default Changepassword;
