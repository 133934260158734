import React, { useEffect, useState } from "react";
import Home_icon from "../img/home_icon/index.js";
import { Col, Row, Divider, Badge, Dropdown, Typography } from "antd";
import {  useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getTitle, editonOpennitify, getonOpen ,getcount_unread,editOnfilter} from ".././slice/projectSlice";
import { LeftOutlined } from "@ant-design/icons";
import Clock from "react-live-clock";
import _ from "lodash";
import { Bell, User, ChangePassword, Logout } from "../img/home_icon/navbar";
const { Text } = Typography;
const Navbar = (prop) => {
  const dispatch = useDispatch();
  const tmp_name_title = useSelector(getTitle);
  const onOpennitify = useSelector(getonOpen);
  const count_unerad = useSelector(getcount_unread);
  const [name_tilte, setNametilte] = useState("");
  const Params = useParams();
  const navigate = useNavigate();
  const pathname = window.location.pathname
  var user = localStorage.getItem('token')
  user = JSON.parse(user)
  useEffect(() => {
    console.log(pathname);
    if (pathname === '/notifications') {
      setNametilte(tmp_name_title);
    }
    else {
      if (_.isEmpty(Params)) {
        setNametilte("Project Site");
      }
      if (!_.isEmpty(Params.project)) {
        setNametilte(Params.project);
      }
      if (!_.isEmpty(Params.room)) {
        setNametilte(Params.project + tmp_name_title);
      }
    }
  }, [Params,tmp_name_title]);
  const items = [
    {
      label: <div style={{ textAlign: "center" }}>Change Password</div>,
      onClick: () => {navigate('/changepassword'); },
      key: "0",
      icon: <ChangePassword />,
    },
    {
      label: <div style={{ textAlign: "center" }}>Logout</div>,
      onClick: () => { 
        localStorage.removeItem('token');
        navigate('/login');
      dispatch(editonOpennitify(true)); 
      dispatch(editOnfilter({ serviceRequst: 'all', pir_battery: 'all', room: 'all', time: 'all', status: 'all', state: 'all',floor:'all'})); 
    },
      key: "1",
      icon: <Logout />,
    },
  ];
  return (
    <Row gutter={[48, 48]} align={"middle"} justify={"space-between"}>
      <Col xs={2} sm={2} md={6} lg={5} xl={7} xxl={7}>
        {(!_.isEmpty(Params.room) && !_.isEmpty(Params.project)) &&(
          <Row gutter={[48, 0]} align={"middle"} justify={"start"}>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
              <LeftOutlined
                style={{ fontSize: "24px" }}
                onClick={() => navigate('/overview/'+Params.project)}
              />
            </Col>
            <Col xs={0} sm={2} md={17} lg={18} xl={20} xxl={20}>
              <Home_icon />
            </Col>
          </Row>
        )}
        {(!_.isEmpty(Params.project) && _.isEmpty(Params.room)) && (
             <Row gutter={[48, 0]} align={"middle"} justify={"start"}>
             <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
               <LeftOutlined
                 style={{ fontSize: "24px" }}
                 onClick={() => navigate('/overview')}
               />
             </Col>
             <Col xs={0} sm={2} md={17} lg={18} xl={20} xxl={20}>
               <Home_icon />
             </Col>
           </Row>
        )}
        {(_.isEmpty(Params.room) && _.isEmpty(Params.project)) && (
          <Row gutter={[48, 0]} align={"middle"} justify={"start"}>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
              { pathname === '/notifications' ? <LeftOutlined
                 style={{ fontSize: "24px" }}
                 onClick={() => navigate('/overview')}
               /> : null}
            </Col>
            <Col xs={0} sm={2} md={17} lg={18} xl={20} xxl={20}>
              <Home_icon />
            </Col>
          </Row>
        )}
      </Col>
      <Col xs={8} sm={6} md={5} lg={6} xl={8} xxl={10}>
        <Text
          style={styles.title}
          ellipsis={true ? { tooltip: `${name_tilte}` } : false}
        >
          {name_tilte}
        </Text>
      </Col>
      <Col xs={14} sm={12} md={13} lg={13} xl={9} xxl={7}>
        <Row gutter={[34, 34]} justify="start" align="middle">
          <Col
            xs={16}
            sm={16}
            md={10}
            lg={10}
            xl={9}
            xxl={10}
            className="clocktime"
            style={{ textAlign: "start" }}
          >
            <Clock
              format={"DD MMM YYYY HH:mm"}
              ticking={true}
              timezone={"Asia/Bangkok"}
            />
          </Col>
          <Col
            xs={3}
            sm={3}
            md={3}
            lg={4}
            xl={4}
            xxl={4}
            className="bell-navbar" style={{ textAlign: "center" }}
          >
            <div
              onClick={() => {
                dispatch(editonOpennitify(!onOpennitify));
              }}
            >
              <Badge count={count_unerad} style={{ cursor: "pointer" }}>
                <Bell />
              </Badge>
            </div>
          </Col>
          <Col
            xs={2}
            sm={2}
            md={1}
            lg={1}
            xl={1}
            xxl={2}
            style={{ textAlign: "center" }}
          >
            <Divider
              type="vertical"
              style={{
                borderLeft: "2px solid rgba(255, 255, 255, 0.8)",
                height: "52.5px",
                textAlign: "center",
                // margin: "0 32px",
              }}
            />
          </Col>
          <Col xs={4} sm={4} md={8} lg={8} xl={9} xxl={8} className="menu-title">
            <div className="menu-title-sub">
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
                dropdownRender={(menu) => (
                  <div>
                    {React.cloneElement(menu, {
                      style: styles.menuStyle,
                    })}
                  </div>
                )}
              >
                <div
                  onClick={(e) => e.preventDefault()}
                  style={{ cursor: "pointer" }}
                >
                  <Row gutter={[32, 0]} align={"middle"}>
                    <Col span={10}>
                      <User />
                    </Col>
                    <Col span={14}>
                      <div style={styles.textuser}>{`${user.username}`}</div>
                    </Col>
                  </Row>
                </div>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
const styles = {
  title: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "40px",
    // "line-height": "46px",
    textAlign: "center",
    color: "#FFFFFF",
    whiteSpace: "nowrap",
    // width: "50px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "capitalize",
    width: "100%",
    lineHeight: "46px",
    // overflowX: "auto",
  },
  overflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  textuser: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    color: "#FFFFFF",
    textTransform:"capitalize",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  menuStyle: {
    background: "#122C4D",
    borderRadius: "8px",
    color: "#FFFFFF",
    boxShadow: "none",
  },
};
export default Navbar;
