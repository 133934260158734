const Avatar = () => {
  return (
    <svg
      width="133"
      height="133"
      viewBox="0 0 133 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M85.5139 46.0143C85.5139 56.516 77.001 65.0285 66.4996 65.0285C55.9982 65.0285 47.4854 56.5157 47.4854 46.0143C47.4854 35.513 55.9982 27 66.4996 27C77.001 27 85.5139 35.5128 85.5139 46.0143Z"
        fill="white"
      />
      <path
        d="M99.2202 87.0534C92.6444 75.8824 80.4436 68.356 66.4998 68.356C52.556 68.356 40.3552 75.8824 33.7794 87.0534C28.7886 95.5303 34.8886 106.226 44.7128 106.226H88.2871C98.1113 106.226 104.212 95.5303 99.2205 87.0534H99.2202Z"
        fill="white"
      />
      <circle cx="66.5" cy="66.5" r="65.5" stroke="white" stroke-width="2" />
    </svg>
  );
};
export default Avatar;
