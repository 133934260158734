import React, { useEffect, useState } from "react";
import { Col, Row, Spin, Typography } from "antd";
import Battery from "../../img/project_icon/pir";
import { LoadingOutlined } from "@ant-design/icons";
import Getpirbattery from "../../api/project/pir_battery";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editActive,editOnfilter, editH_filter } from "../../slice/projectSlice";
const { Title, Text } = Typography;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 36,
      color: "#ffffff",
    }}
    spin
  />
);
const Pirbattery = (props) => {
  const dispatch = useDispatch();
  const Params = useParams();
  const [isLoad, setIsload] = useState(true);
  const [count, setCount] = useState(0);
  const [typeroom, setTyperoom] = useState("Room");
  useEffect(() => {
    if (isLoad) {
      Getpirbattery(Params.project, (err, res) => {
        if (!err && res.statusCode === 200) {
          setCount(res.data);
          setIsload(false);
        }
      });
    }
    if (count > 1) {
      setTyperoom("Rooms");
    }
    else if (count === 0 || count === 1) {
      setTyperoom("Room");
    }
  }, []);
  return (
    <Spin indicator={antIcon} spinning={isLoad}>
      <div
        style={styles.box}
        onClick={() => {
          dispatch(
            editActive({
              title: String("PIR Battery"),
              sub: "Battery",
              count_room: count,
            })
          );
          dispatch(editOnfilter({ serviceRequst: 'all', pir_battery: 'low', room: 'all', time: 'all', status: 'all', state: 'all',floor:'all' }));
          dispatch(editH_filter({ serviceRequst: 'all', pir_battery: 'low', room: 'all', time: 'all', status: 'all', state: 'all',floor:'all' }));
        }}
      >
        <div justify={"center"} style={{ margin: "0 0 15px 0" }}>
          <div className="title_rs">PIR</div>
          <div className="title_rs">Battery</div>
        </div>

        <Row justify={"center"} style={{ marginBottom: "10px" }}>
          <Col>
            <Battery width="69" color={"white"} />
          </Col>
        </Row>
        <Row justify={"center"} style={{ marginBottom: "10px" }}>
          <Col>
            <Text strong style={styles.subtitlesnumber}>
              {count}
            </Text>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col>
            <Title level={4} style={styles.subtitles}>
              {typeroom}
            </Title>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

const styles = {
  box: {
    background: "rgba(255, 255, 255, 0.1)",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "15px",
    padding: "13px 15px",
    cursor: "pointer",
    minHeight: "253px",
    maxHeight: "400px",
    // display: "grid",
    // alignContent: "center",
  },
  subtitles: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    color: "#FFFFFF",
  },
  subtitlesnumber: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    color: "#FFFFFF",
  },
};
export default Pirbattery;
