/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useParams } from "react-router-dom";
import { Home_full_fun } from "../../img/room_icon/home";
import { Row, Dropdown, Button, Table, Tooltip, Spin } from "antd";
import { useDispatch } from "react-redux";
import { editTitle } from "../../slice/projectSlice";
import { LoadingOutlined } from "@ant-design/icons";
import { getname_project, getname_id } from "../../slice/projectSlice";
import {
  Residents_Htr_Sv,
  Juristic_Htr_Sv,
  Battery_Htr_Sv,
} from "../../img/project_icon/services";
import {
  Battery_htr,
  HomeNomal_htr,
  Juristic_htr,
  Residents_htr,
  Room_icon_htr,
} from "../../img/project_icon/history";
import Gethistoryroom from "../../api/room/Gethistorty";
import { fromPairs } from "lodash";
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 36,
      color: "#ffffff",
    }}
    spin
  />
);
const items = [
  {
    key: 1,
    label: "Today",
  },
  {
    key: 2,
    label: "Last Week",
  },
  {
    key: 3,
    label: "Last Month",
  },
  {
    key: 4,
    label: "Last Year",
  },
  {
    key: 5,
    label: "Older 1 Year",
  },
];
const columns = [
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    width: "10%",
    align: "center",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (
      <Tooltip placement="topLeft" title={text}>
        <span style={styles.textColumn}>{text}</span>
      </Tooltip>
    ),
  },
  {
    title: "Detail",
    dataIndex: "detail",
    width: "25%",
    align: "start",
    key: "detail",
    ellipsis: true,
    render: (text) => {
      {
        let tmp = JSON.parse(text);

        if (tmp.icon == "0") {
          return (
            <Tooltip placement="topLeft" title={tmp.name}>
              <span style={{ display: "flex", alignItems: "center" }}>
                <HomeNomal_htr />
                <span
                  style={{
                    padding: "0 10px",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "23px",
                  }}
                >
                  {tmp.name}
                </span>
              </span>
            </Tooltip>
          );
        } else if (tmp.icon == "1") {
          return (
            <Tooltip placement="topLeft" title={tmp.name}>
              <span style={{ display: "flex", alignItems: "center" }}>
                <Battery_htr />
                <span
                  style={{
                    padding: "0 10px",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "23px",
                  }}
                >
                  {tmp.name}
                </span>
              </span>
            </Tooltip>
          );
        } else if (tmp.icon == "2") {
          return (
            <Tooltip placement="topLeft" title={tmp.name}>
              <span style={{ display: "flex", alignItems: "center" }}>
                <Residents_htr />
                <span
                  style={{
                    padding: "0 10px",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "23px",
                  }}
                >
                  {tmp.name}
                </span>
              </span>
            </Tooltip>
          );
        } else if (tmp.icon == "3") {
          return (
            <Tooltip placement="topLeft" title={tmp.name}>
              <span style={{ display: "flex", alignItems: "center" }}>
                <Juristic_htr />
                <span
                  style={{
                    padding: "0 10px",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "23px",
                  }}
                >
                  {tmp.name}
                </span>
              </span>
            </Tooltip>
          );
        }
      }
    },
  },
  {
    title: "Zone",
    dataIndex: "zone",
    width: "20%",
    align: "start",
    key: "zone",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (
      <Tooltip placement="topLeft" title={text}>
        <span style={styles.textColumn}>{text}</span>
      </Tooltip>
    ),
  },
  {
    title: "Device",
    dataIndex: "device",
    width: "15%",
    align: "center",
    key: "device",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (
      <Tooltip placement="topLeft" title={text}>
        <span style={styles.textColumn}>{text}</span>
      </Tooltip>
    ),
  },
  {
    title: "Device ID",
    dataIndex: "device_id",
    width: "15%",
    align: "center",
    key: "device_id",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (
      <Tooltip placement="topLeft" title={text}>
        <span style={styles.textColumn}>{text}</span>
      </Tooltip>
    ),
  },
  {
    title: "Error hours",
    dataIndex: "errorhours",
    width: "15%",
    align: "center",
    key: "errorhours",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => (
      <Tooltip placement="topLeft" title={text}>
        <span style={styles.textColumn}>{text}</span>
      </Tooltip>
    ),
  },
];

const History_Room = (prop) => {
  const [isFetch, setIsfetch] = useState(true);
  const [roomDetail, setRoomdetail] = useState({});
  const [Logs, setLog] = useState([]);
  const [iconLogs, setIconLog] = useState([]);
  const [activeTime, setActivetime] = useState(1);

  const Params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    // if (isFetch) {
    setIsfetch(true);
    Gethistoryroom(
      { project: prop.nameProject, room: prop.roomId, time: activeTime },
      (err, res) => {
        if (!err && res.statusCode === 200) {
          setRoomdetail(res.data.room);
          setLog(res.data.table);
          setIconLog(res.data.table);
          prop.setStatus(res.data.room.status);
          dispatch(editTitle(String(" - Floor " + res.data.room.floor)));
          setIsfetch(false);
        }
      }
    );
    // }
    return () => {
      // cleanup
    };
  }, [prop.nameProject, prop.roomId]);
  return (
    <Spin indicator={antIcon} spinning={isFetch}>
      {!isFetch && (
        <>
          <Row justify="space-between" align="middle" style={styles.boxfilter}>
            <Row align="middle">
              <Home_full_fun data={roomDetail} />
              <div style={styles.textNameRoom}>
                ROOM {roomDetail.room_number}
              </div>
            </Row>
            <Row align="middle">
              {roomDetail.service_request.resident && (
                <Residents_Htr_Sv width={45} color={roomDetail.color} />
              )}
              {roomDetail.service_request.juristic && (
                <Juristic_Htr_Sv width={45} color={roomDetail.color} />
              )}
              {roomDetail.pir_battery && (
                <Battery_Htr_Sv width={45} color={roomDetail.color} />
              )}
            </Row>
          </Row>
          <div className="Dropdownlayout">
            <Dropdown
              menu={{
                items,
                selectable: true,
                onClick: (event) => {
                  setActivetime(parseInt(event.key));
                  Gethistoryroom(
                    {
                      project: prop.nameProject,
                      room: prop.roomId,
                      time: parseInt(event.key),
                    },
                    (err, res) => {
                      if (!err && res.statusCode === 200) {
                        setRoomdetail(res.data.room);
                        setLog(res.data.table);
                      }
                    }
                  );
                },
                defaultSelectedKeys: [activeTime],
              }}
              dropdownRender={(menu) => (
                <div>
                  {React.cloneElement(menu, {
                    style: styles.menuStyle,
                  })}
                </div>
              )}
              trigger={["click"]}
            >
              <Button style={styles.buttonstyle}>
                <Row
                  align={"middle"}
                  justify={"space-between"}
                  style={styles.text_btn}
                >
                  {items[activeTime - 1].label}
                  <svg
                    width="13"
                    height="9"
                    viewBox="0 0 13 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.5 9L12.1292 0H0.870835L6.5 9Z" fill="white" />
                  </svg>
                </Row>
              </Button>
            </Dropdown>
          </div>
          <div style={{ borderBottom: "1px solid #B3B3B3" }}></div>
          <ScrollContainer className="htr-frame tableProject-htr">
            {Logs.map((log) => {
              return (
                <div>
                  <div style={styles.text_day}>{log.day}</div>
                  <Table
                    bordered={false}
                    columns={columns}
                    dataSource={log.data}
                    pagination={false}
                  />
                </div>
              );
            })}
          </ScrollContainer>
        </>
      )}
    </Spin>
  );
};
const styles = {
  boxfilter: {
    background: "#122C4D",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    padding: "18px 20px",
    margin: "0 0 16px 0",
  },
  text_btn: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "23px",
  },
  buttonstyle: {
    background: "rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "#FFFFFF",
    margin: "0 0 6px 0",
    border: "0",
    height: "51px",
    width: "171px",
  },

  // buttonstyle: {
  //   background: "#122C4D",
  //   borderRadius: "8px",
  //   color: "#FFFFFF",
  //   fontFamily: "Arial Narrow",
  //   fontStyle: "normal",
  //   fontWeight: "700",
  //   fontSize: "18px",
  //   margin: "0 0 6px 0",
  //   border: "0",
  //   height: "40px",
  //   width: "135px",
  // },
  menuStyle: {
    background: "#122C4D",
    borderRadius: "8px",
    color: "#FFFFFF",
    boxShadow: "none",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
  },
  text_day: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    color: "#FFFFFF",
    textAlign: "start",
    margin: "10px 0 ",
  },
  textNameRoom: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    color: "#FFFFFF",
    textAlign: "start",
    padding: "0 6px",
  },
  textColumn: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#FFFFFF",
    textTransform: "capitalize",
  },
};
export default History_Room;
