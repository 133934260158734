const Getroomstatus = (site, cb) => {
    let token = localStorage.getItem('token')
    token = JSON.parse(token)
    fetch(
      `${process.env.REACT_APP_API_URL}api/room_devices_status?site=${site}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token.token}`,
          'Content-Type': 'application/json'
        },
      }
    )
      .then(res => res.json())
      .then(json => cb(null, json))
      .catch(err => cb(err, null))
    // cb(null, [{ color: '#66B040', icon: '', name: 'Online', count: 100 }, { color: '#FAB52F', icon: '', name: 'Unstable', count: 250 }, { color: '#F40606', icon: '', name: 'Offline', count: 50 }])
}

export default Getroomstatus
