/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Button, Dropdown } from "antd";
import Components from "../../components";
import SetstatusRoom from "../../api/room/SetstatusRoom"
const Show_Room = (prop) => {
  const [onMenu, setOnmenu] = useState(1);
  const [workDone, setWorkDone] = useState(0);
  const [name_project, setNameProject] = useState("");
  const [room_id, setRoomId] = useState("");
  const Params = useParams();
  const items = [
    {
      label: "Room Detail",
      key: "1",
      // icon: <UserOutlined />,
      onClick: () => {
        setOnmenu(1);
      },
    },
    {
      label: "History",
      key: "2",
      // icon: <UserOutlined />,
      onClick: () => {
        setOnmenu(2);
      },
    },
  ];
  const menuProps = {
    items,
  };

  useEffect(() => {
    setNameProject(prop.nameProject);
    setRoomId(prop.roomId);

  }, [prop.roomId]);

  return (
    <>
      <Row gutter={[0, 0]} style={styles.backdrop}>
        <Col className="Overflow-Crad-Project" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div style={styles.background_color}>
            <Row>
              <Col span={24}>
                <div style={styles.box}>
                  <Row justify="space-between">
                    <div className="Dropdownlayout">
                      <Dropdown
                        menu={menuProps}
                        trigger={["click"]}
                        dropdownRender={(menu) => (
                          <div>
                            {React.cloneElement(menu, {
                              style: styles.menuStyle,
                            })}
                          </div>
                        )}
                      >
                        <Button style={styles.buttonstyle}>
                          <Row
                            align={"middle"}
                            justify={"space-between"}
                            style={styles.text_btn}
                          >
                            <div style={{margin:"0 10px 0 0"}}>{items[onMenu - 1].label}</div>
                            <svg
                              width="13"
                              height="9"
                              viewBox="0 0 13 9"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.5 9L12.1292 0H0.870835L6.5 9Z"
                                fill="white"
                              />
                            </svg>
                          </Row>
                        </Button>
                      </Dropdown>
                    </div>
                    {onMenu == 1 && (
                      <>
                        {workDone == 1 && (
                          <Button
                            type="primary"
                            size="large"
                            style={styles.buttonAcceptstyle}
                            onClick={() => {
                              SetstatusRoom({ project: Params.project, room: Params.room, status: 2 }, (err, res) => {
                                if (!err && res.statusCode === 200) {
                                  // setWorkDone(2);
                                  window.location.reload();
                                }
                              })
                            }}
                          >
                            Accept
                          </Button>
                        )}
                        {workDone == 2 && (
                          <Button
                            type="primary"
                            size="large"
                            style={styles.buttonAcceptstyle}
                            onClick={() => {
                              SetstatusRoom({ project: Params.project, room: Params.room, status: 0 }, (err, res) => {
                                if (!err && res.statusCode === 200) {
                                  setWorkDone(0);
                                  window.location.reload();
                                }
                              })
                            }}
                          >
                            Work Done
                          </Button>
                        )}
                      </>
                    )}
                  </Row>
                  {onMenu == 1 && <Components.Notifications_detail setStatus={(res) => setWorkDone(res)} nameProject={name_project} roomId={room_id} />}
                  {onMenu == 2 && <Components.Notifications_history setStatus={(res) => setWorkDone(res)} nameProject={name_project} roomId={room_id} />}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};
const styles = {
  background_color: {
    background: "linear-gradient(180deg, #1A3E6B 0%, #122C4D 89.52%)",
    height: "100%",
    padding: "19px 22px 0 22px",
  },
//   box: {
//     background: "rgba(255, 255, 255, 0.05)",
//     borderRadius: "15px",
//     padding: "18px 31px",
//     height: "907.2px",
//   },
  text_btn: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "37px",
  },
  buttonstyle: {
    background: "rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "#FFFFFF",
    margin: "0 0 6px 0",
    border: "0",
    height: "100%",
    minWidth: "211px",
  },
  buttonAcceptstyle: {
    background: "#122C4D",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "37px",
    margin: "0 0 6px 0",
    border: "0",
    height: "100%",
    width: "211px",
    boxShadow: "none",
  },
  menuStyle: {
    background: "#122C4D",
    borderRadius: "8px",
    color: "#FFFFFF",
    boxShadow: "none",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
  },
  backdrop: {
    // background:
    //   "linear-gradient(144.09deg, rgba(26, 62, 107, 0.72) 0%, rgba(18, 44, 77, 0.72) 100%)",
    // WebkitBackdropFilter: "blur(2px)",
    // backdropFilter: "blur(2px)",
    width: "100%",
    // height: "100%",
    fontWeight: "bold",
    textAlign: "center",
    minHeight: 120,
    color: "#fff",
  },
  box_title: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",

    // width: "366px",
    height: "58px",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
    // background: "rgba(18, 44, 77, 0.8)",

    /* Inside auto layout */

    flex: "none",
    order: "0",
    flexGrow: "0",
  },
};
export default Show_Room;
