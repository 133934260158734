import React, { useEffect, useState } from "react";
import Container from "../../container";
import ScrollContainer from "react-indiana-drag-scroll";
import { Col, Row } from "antd";
import { getonOpen, editOnfilter } from "../../slice/projectSlice";
import { useSelector } from "react-redux";
import Siderbar from "../../components/siderbar";
import { useDispatch } from "react-redux";
import HeaderSiderbar from "../../components/headersiderbar";
const Project = () => {
  const onOpennitify = useSelector(getonOpen);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      editOnfilter({
        serviceRequst: "Both Request",
        pir_battery: "all",
        room: "all",
        time: "all",
        status: "all",
        state: "all",
        floor: "all",
      })
    );
  }, []);
  return (
    <>
      <Row gutter={[0, 0]} style={styles.backdrop}>
        <Col
          className="Overflow-Crad-Project"
          xs={onOpennitify ? 14 : 24}
          sm={onOpennitify ? 14 : 24}
          md={onOpennitify ? 16 : 24}
          lg={onOpennitify ? 18 : 24}
          xl={onOpennitify ? 20 : 24}
          xxl={onOpennitify ? 19 : 24}
        >
          <div style={styles.background_color}>
            <Row gutter={[20, 20]}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={onOpennitify ? 13 : 12}
                xl={onOpennitify ? 15 : 12}
                xxl={onOpennitify ? 15 : 12}
              >
                <Container.Colleft />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={onOpennitify ? 11 : 12}
                xl={onOpennitify ? 9 : 12}
                xxl={onOpennitify ? 9 : 12}
                style={styles.box}
              >
                <Container.Colright />
              </Col>
            </Row>
          </div>
        </Col>
        {onOpennitify && (
          <Col
            className="Siderbackdrop"
            xs={10}
            sm={10}
            md={8}
            lg={6}
            xl={4}
            xxl={5}
          >
            {/* <div className="siderbarframes">
              <div style={styles.box_title}>Notification</div>
              <div className="hr-title-noti"></div>
            </div> */}
            <HeaderSiderbar type={2} ></HeaderSiderbar>

            <ScrollContainer className="scroll-containers">
              <Siderbar />
            </ScrollContainer>
          </Col>
        )}
      </Row>
    </>
  );
};
const styles = {
  box: {
    background: "rgba(255, 255, 255, 0.1)",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "15px",
    padding: "0px 20px",
    paddingBottom: "20px",
    // height: "925px",
  },
  background_color: {
    background: "linear-gradient(180deg, #1A3E6B 0%, #122C4D 89.52%)",
    padding: "19px 20px 0px 22px",
    height: "100%",
  },
  backdrop: {
    // background:
    //   "linear-gradient(144.09deg, rgba(26, 62, 107, 0.72) 0%, rgba(18, 44, 77, 0.72) 100%)",
    // WebkitBackdropFilter: "blur(2px)",
    // backdropFilter: "blur(2px)",
    width: "100%",
    height: "100%",
    fontWeight: "bold",
    textAlign: "center",
    minHeight: 120,
    color: "#fff",
  },
  box_title: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",

    // width: "366px",
    height: "58px",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
    // background: "rgba(18, 44, 77, 0.8)",

    /* Inside auto layout */

    flex: "none",
    order: "0",
    flexGrow: "0",
  },
};
export default Project;
