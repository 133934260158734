import React, { useEffect, useState } from "react";
import { Col, Row, Spin, Typography } from "antd";
import {
  Residents,
  Juristic,
  BotRequest,
} from "../../img/project_icon/services";
import { LoadingOutlined } from "@ant-design/icons";
import Getroomservice from "../../api/project/room_service";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editActive,editOnfilter, editH_filter } from "../../slice/projectSlice";
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 36,
      color: "#ffffff",
    }}
    spin
  />
);
const Room_service = () => {
  const dispatch = useDispatch();
  const Params = useParams();
  const [isLoad, setIsload] = useState(true);
  const [serviceStatus, setServicestatus] = useState([]);
  useEffect(() => {
    if (isLoad) {
      Getroomservice(Params.project, (err, res) => {
        if (!err && res.statusCode === 200) {
          setServicestatus(res.data);
          dispatch(editActive({
            title: String('Both Request'),
            sub: "Request",
            count_room: res.data[2].count,
          }))
          setIsload(false);
        }
      });
    }
  }, []);
  return (
    <Spin indicator={antIcon} spinning={isLoad}>
      <div style={styles.box}>
        <Row justify={"center"} style={{ margin: "0 0 30px 0" }}>
          <div className="title_rs">
            Room Service Request
          </div>
        </Row>
        <Row gutter={[8, 8]} justify={"center"}>
          {serviceStatus.map((data, i) => {
            if (data.name == "Residents") {
              return (
                <Col
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  key={"roomservice" + i}
                  onClick={() => {
                    dispatch(
                      editActive({
                        title: String(data.name),
                        sub: "Request",
                        count_room: data.count,
                      })
                    );
                    dispatch(editOnfilter({ serviceRequst: data.name.toLowerCase(), pir_battery: 'all', room: 'all', time: 'all', status: 'all', state: 'all',floor:'all'}));
                    dispatch(editH_filter({ serviceRequst: data.name.toLowerCase(), pir_battery: 'all', room: 'all', time: 'all', status: 'all', state: 'all',floor:'all'}));
                  }}
                  style={{ cursor: "pointer" }}
                  span={8}
                >
                  <div style={{textAlign: '-webkit-center'}}>
                    <Residents
                      count={data.count}
                      justify={"center"}
                      color={"white"}
                    />
                    <div className="textName_rs">{data.name}</div>
                  </div>
                </Col>
              );
            } else if (data.name == "Juristic") {
              return (
                <Col
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  key={"roomservice" + i}
                  onClick={() => {
                    dispatch(
                      editActive({
                        title: String(data.name),
                        sub: "Request",
                        count_room: data.count,
                      })
                    );
                    dispatch(editOnfilter({ serviceRequst: data.name.toLowerCase(), pir_battery: 'all', room: 'all', time: 'all', status: 'all', state: 'all',floor:'all'}));
                    dispatch(editH_filter({ serviceRequst: data.name.toLowerCase(), pir_battery: 'all', room: 'all', time: 'all', status: 'all', state: 'all',floor:'all'}));
                  }}
                  style={{ cursor: "pointer", height: "82px", width: "108px" }}
                  span={8}
                >
                  {/* <Juristic
                    count={data.count}
                    width={30}
                    justify={"center"}
                    name={data.name}
                    color={"white"}
                  /> */}
                  <div style={{textAlign: '-webkit-center'}}>
                    <Juristic
                      count={data.count}
                      justify={"center"}
                      color={"white"}
                    />
                    <div className="textName_rs">{data.name}</div>
                  </div>
                </Col>
              );
            } else if (data.name == "Both Request") {
              return (
                <Col
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  key={"roomservice" + i}
                  onClick={() => {
                    dispatch(
                      editActive({
                        title: String(data.name),
                        sub: "Request",
                        count_room: data.count,
                      }));
                      dispatch(editOnfilter({ serviceRequst: data.name.toLowerCase(), pir_battery: 'all', room: 'all', time: 'all', status: 'all', state: 'all',floor:'all'}));
                      dispatch(editH_filter({ serviceRequst: data.name.toLowerCase(), pir_battery: 'all', room: 'all', time: 'all', status: 'all', state: 'all',floor:'all'}));
                  }}
                  style={{ cursor: "pointer", height: "82px", width: "108px" }}
                  span={8}
                >
                  {/* <BotRequest
                    count={data.count}
                    width={42}
                    justify={"center"}
                    name={data.name}
                    color={"white"}
                  /> */}
                  <div style={{textAlign: '-webkit-center'}}>
                    <BotRequest
                      count={data.count}
                      justify={"center"}
                      color={"white"}
                    />
                    <div className="textName_rs">{data.name}</div>
                  </div>
                </Col>
              );
            }
          })}
        </Row>
      </div>
    </Spin>
  );
};

const styles = {
  box: {
    background: "rgba(255, 255, 255, 0.1)",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "15px",
    padding: "13px 15px",
    height: "253px",
    // display: "grid",
    // alignContent: "center",
  },
};
export default Room_service;
