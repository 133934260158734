const Bell = (props) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        cursor: "pointer",
      }}
    >
      <circle cx="25" cy="25" r="25" fill="black" fill-opacity="0.25" />
      <path
        d="M37.1154 23.6887C36.5746 23.6887 36.1365 23.2501 36.1365 22.7097C36.1365 18.3893 33.7568 14.456 29.926 12.4448C29.4472 12.1938 29.2629 11.6017 29.5142 11.1227C29.7653 10.6442 30.3565 10.4599 30.8363 10.711C35.3138 13.0615 38.0948 17.6595 38.0948 22.7097C38.0948 23.2507 37.6563 23.6889 37.1155 23.6889L37.1154 23.6887Z"
        fill="white"
      />
      <path
        d="M11.9789 23.6886C11.4381 23.6886 11 23.2501 11 22.7097C11 17.6594 13.781 13.062 18.2579 10.7115C18.736 10.4605 19.3285 10.6447 19.58 11.1233C19.8315 11.6018 19.6468 12.1939 19.1683 12.4454C15.3376 14.4566 12.9577 18.3896 12.9577 22.7097C12.9575 23.2503 12.519 23.6884 11.9786 23.6884L11.9789 23.6886Z"
        fill="white"
      />
      <path
        d="M33.7072 35.7942H15.3872C14.3356 35.7942 13.48 34.9385 13.48 33.887C13.48 32.7452 13.978 31.6653 14.846 30.9241C15.2778 30.5552 15.5256 30.0178 15.5256 29.4495L15.5258 22.7098C15.5258 17.7352 19.5729 13.6882 24.5474 13.6882C29.5222 13.6882 33.569 17.7353 33.569 22.7098V29.449C33.569 30.0173 33.8166 30.5546 34.2482 30.9235C35.1162 31.6648 35.6142 32.7447 35.6142 33.8864C35.6142 34.9384 34.7586 35.7941 33.7068 35.7941L33.7072 35.7942Z"
        fill="white"
      />
      <path
        d="M24.5471 15.6469C22.9902 15.6469 21.7236 14.3799 21.7236 12.8234C21.7236 11.267 22.9902 10 24.5471 10C26.1036 10 27.3705 11.2665 27.3705 12.8234C27.3705 14.3804 26.1036 15.6469 24.5471 15.6469Z"
        fill="white"
      />
      <path
        d="M20.1235 37.4106C20.5586 39.4572 22.3724 41 24.5469 41C26.7214 41 28.5353 39.4572 28.9699 37.4106H20.1235Z"
        fill="white"
      />
    </svg>
  );
};

const User = (props) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        cursor: "pointer",
      }}
    >
      <path
        d="M32.1485 17.2986C32.1485 21.2466 28.9482 24.4468 25.0003 24.4468C21.0524 24.4468 17.8521 21.2465 17.8521 17.2986C17.8521 13.3508 21.0524 10.1504 25.0003 10.1504C28.9482 10.1504 32.1485 13.3507 32.1485 17.2986Z"
        fill="white"
      />
      <path
        d="M37.301 32.7268C34.8289 28.5272 30.2422 25.6978 25.0001 25.6978C19.7581 25.6978 15.1713 28.5272 12.6992 32.7268C10.823 35.9136 13.1162 39.9346 16.8095 39.9346H33.1908C36.8842 39.9346 39.1775 35.9136 37.3011 32.7268H37.301Z"
        fill="white"
      />
      <circle cx="25" cy="25" r="24" stroke="white" stroke-width="2" />
    </svg>
  );
};

const ChangePassword = (props) => {
  return (
    <svg
      width="20"
      height="29"
      viewBox="0 0 20 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        cursor: "pointer",
        marginRight: "10px",
      }}
    >
      <path
        d="M18.2471 12.8533H1.75292C0.786377 12.8533 0 13.6397 0 14.6062V26.2727C0 27.2392 0.786398 28.0256 1.75292 28.0256H18.2471C19.2136 28.0256 20 27.2392 20 26.2727V14.6062C20 13.6396 19.2136 12.8533 18.2471 12.8533ZM9.99979 22.7759C9.02477 22.7759 8.23436 21.9912 8.23436 21.0234C8.23436 20.0557 9.02479 19.2709 9.99979 19.2709C10.9748 19.2709 11.7652 20.0557 11.7652 21.0234C11.7652 21.9912 10.9748 22.7759 9.99979 22.7759Z"
        fill="white"
      />
      <path
        d="M17.6465 11.6663V7.58564C17.6465 3.40285 14.2165 0 10.0002 0C5.78436 0 2.354 3.40285 2.354 7.58564V8.74526H4.69867V7.58564C4.69867 4.68117 7.07684 2.31872 10.0002 2.31872C12.9233 2.31872 15.3018 4.68158 15.3018 7.58564V11.6663H17.6465Z"
        fill="white"
      />
    </svg>
  );
};

const Logout = (props) => {
  return (
    <svg
      width="20"
      height="31"
      viewBox="0 0 27 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        cursor: "pointer",
        marginRight: "10px",
      }}
    >
      <path
        d="M13.5839 16.5531C13.702 17.7676 14.6075 18.6223 15.6707 18.6223H19.49V31.5319C19.49 32.1616 19.0568 32.7013 18.4663 32.7013H10.0797V5.93764C10.0797 5.53288 9.8828 5.12814 9.56778 4.94806L2.00804 0H18.4662C19.0174 0 19.4899 0.494806 19.4899 1.16942V14.0789H15.5918C14.4111 14.0344 13.4661 15.2038 13.5841 16.5533L13.5839 16.5531ZM0 8.02789e-05V32.6562L7.59911 37.6043C8.26843 38.0542 9.09538 37.4694 9.09538 36.6147V6.61243C9.09538 6.20768 8.89845 5.80294 8.58344 5.62285L0 8.02789e-05ZM26.8134 16.9579C26.8134 16.913 26.8527 16.913 26.8527 16.8678C26.8527 16.823 26.892 16.7778 26.892 16.7329C26.892 16.688 26.9313 16.6429 26.9313 16.598C26.9313 16.5531 26.9705 16.5531 26.9705 16.5079C27.0098 16.373 27.0098 16.1929 26.9705 16.058C26.9705 16.0131 26.9705 16.0131 26.9313 15.968C26.9313 15.9231 26.9313 15.8779 26.892 15.833C26.892 15.7882 26.8527 15.743 26.8527 15.6981C26.8527 15.6533 26.8134 15.6532 26.8134 15.6081C26.7742 15.5632 26.7346 15.4731 26.6953 15.4283L22.7973 10.9751C22.4034 10.5252 21.7736 10.5252 21.3798 10.9751C20.986 11.425 20.986 12.1445 21.3798 12.5944L23.5455 15.0684L15.592 15.0687C15.0408 15.0687 14.5683 15.5636 14.5683 16.2382C14.5683 16.8679 15.0015 17.4076 15.592 17.4076H23.5455L21.3798 19.8816C20.986 20.3316 20.986 21.051 21.3798 21.5009C21.5768 21.7259 21.8522 21.816 22.0884 21.816C22.3642 21.816 22.6004 21.7259 22.797 21.5009L26.6951 17.0477C26.7346 17.0929 26.7739 17.0481 26.8132 16.958L26.8134 16.9579Z"
        fill="white"
      />
    </svg>
  );
};

export { Bell, User, ChangePassword, Logout };
