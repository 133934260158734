import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Input, Button, Form, message, Layout } from "antd";
import Back from "../../img/home_icon/auth/back";
import Verify from "../../img/home_icon/auth/verifyemail";
import Vertifiemail from "../../api/auth/vertifyemail";
// import InputCode from "../../api/auth/vertify_inputcode";
const { Header, Footer, Sider, Content } = Layout;
const CardVerify = ({ label, props }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [oneInput, setOneinput] = useState("");
  const [twoInput, setTwoinput] = useState("");
  const [threeInput, setThreeinput] = useState("");
  const [fourInput, setFourinput] = useState("");
  const [fivInput, setFiveinput] = useState("");
  const [sixInput, setSixinput] = useState("");
  const [verifynoti, setVerifyNoti] = useState(false);
  //
  const [length, setLength] = useState(6);
  const [code, setCode] = useState([...Array(length)].map(() => ""));
  const inputs = useRef([]);
  var navigate = useNavigate();
  const [form] = Form.useForm();
  const processInput = (e, slot) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    setVerifyNoti(false);
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
    // if (newCode.every((num) => num !== "")) {
    //   onComplete(newCode.join(""));
    // }
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = "";
      setCode(newCode);
      inputs.current[slot - 1].focus();
    }
  };
  useEffect(() => {
    form.resetFields();
  }, []);
  return (
    <Layout className="boxframe">
      <Header style={styles.Header}>
        <div
          onClick={() => {
            navigate("/forgotpassword");
          }}
        >
          <Back />
        </div>
      </Header>
      <Content style={styles.my_auto}>
        <div className="container">
          <Row justify={"center"} align={"middle"}>
            <Col span={24}>
              <div style={styles.title}>Verify Your Email</div>
              <div style={{ textAlign: "center", margin: "47px 0 39px 0" }}>
                <Verify />
              </div>
              <div style={styles.description}>
                Please enter your verification code.
              </div>
              <div style={styles.subtitle}>
                We have sent verification code to your email.
              </div>
              <Row justify={"center"} align={"middle"}>
                <Form
                  layout={"vertical"}
                  form={form}
                  onFinish={(e) => {
                    let textCode =
                      code[0].toString() +
                      code[1].toString() +
                      code[2].toString() +
                      code[3].toString() +
                      code[4].toString() +
                      code[5].toString();
                    // let code =
                    //   "" +
                    //   oneInput +
                    //   twoInput +
                    //   threeInput +
                    //   fourInput +
                    //   fivInput +
                    //   sixInput;
                    // if (
                    //   oneInput != "" ||
                    //   twoInput != "" ||
                    //   threeInput != "" ||
                    //   fourInput != "" ||
                    //   fivInput != "" ||
                    //   sixInput != ""
                    // ) {
                    Vertifiemail({ code: textCode }, (err, res) => {
                      if (!err && res.statusCode == 200) {
                        navigate("/newpassword", {
                          state: { id: res.message },
                        });
                      } else {
                        setVerifyNoti(true);
                        // messageApi.open({
                        //   type: "error",
                        //   content: res.message,
                        // });
                      }
                    });
                    // } else {
                    //   setVerifyNoti(true);
                    //   // messageApi.open({
                    //   //   type: "error",
                    //   //   content: "Please enter all input",
                    //   // });
                    // }
                  }}
                >
                  <Form.Item
                    style={{ margin: "0" }}
                    label={null}
                    autoComplete="off"
                    // name="number"
                    rules={[
                      {
                        required: true,
                        message: "Please input your code verify email!",
                      },
                      {
                        transform(value) {
                          if (value.length == 0) {
                            setVerifyNoti(false);
                          } else {
                            setVerifyNoti(false);
                          }
                          return value;
                        },
                      },
                    ]}
                  >
                    {/* <Input.Group size="large"> */}
                    <Row gutter={[25, 25]}>
                      {code.map((num, idx) => {
                        return (
                          <Col span={4} style={styles.padding_border}>
                            <div style={styles.input}>
                              <Input
                                // onChange={(e) => {
                                //   setOneinput(e.target.value);
                                // }}
                                key={idx}
                                type="text"
                                inputMode="numeric"
                                maxLength={1}
                                value={num}
                                onChange={(e) => {
                                  processInput(e, idx);
                                }}
                                required
                                onKeyUp={(e) => onKeyUp(e, idx)}
                                ref={(ref) => inputs.current.push(ref)}
                                bordered={false}
                                style={styles.font_input}
                              />
                            </div>
                          </Col>
                        );
                      })}
                      {/* <Col span={4} style={styles.padding_border}>
                        <div style={styles.input}>
                          <Input
                            onChange={(e) => {
                              setTwoinput(e.target.value);
                            }}
                            maxLength={1}
                            bordered={false}
                            style={styles.font_input}
                          />
                        </div>
                      </Col> */}
                      {/* <Col span={4} style={styles.padding_border}>
                        <div style={styles.input}>
                          <Input
                            onChange={(e) => {
                              setTwoinput(e.target.value);
                            }}
                            maxLength={1}
                            bordered={false}
                            style={styles.font_input}
                          />
                        </div>
                      </Col>
                      <Col span={4} style={styles.padding_border}>
                        <div style={styles.input}>
                          <Input
                            onChange={(e) => {
                              setThreeinput(e.target.value);
                            }}
                            maxLength={1}
                            bordered={false}
                            style={styles.font_input}
                          />
                        </div>
                      </Col>
                      <Col span={4} style={styles.padding_border}>
                        <div style={styles.input}>
                          <Input
                            onChange={(e) => {
                              setFourinput(e.target.value);
                            }}
                            maxLength={1}
                            bordered={false}
                            style={styles.font_input}
                          />
                        </div>
                      </Col>
                      <Col span={4} style={styles.padding_border}>
                        <div style={styles.input}>
                          <Input
                            onChange={(e) => {
                              setFiveinput(e.target.value);
                            }}
                            maxLength={1}
                            bordered={false}
                            style={styles.font_input}
                          />
                        </div>
                      </Col>
                      <Col span={4} style={styles.padding_border}>
                        <div style={styles.input}>
                          <Input
                            onChange={(e) => {
                              setSixinput(e.target.value);
                            }}
                            maxLength={1}
                            bordered={false}
                            style={styles.font_input}
                          />
                        </div>
                      </Col> */}
                    </Row>
                    {/* </Input.Group> */}
                  </Form.Item>

                  {verifynoti && (
                    <div style={styles.font_input_err}>
                      *The verify code is incorrect, Please try again.
                    </div>
                  )}
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={styles.buttonSend}
                  >
                    Next
                  </Button>
                </Form>
              </Row>
              {/* <InputCode length={6} label="Code Label" /> */}
            </Col>
          </Row>
        </div>
      </Content>
      <Footer style={styles.Footer}>
        <Row style={styles.bottom}>
          {new Date().getFullYear()}, Obotrons Corporation Limited
        </Row>
      </Footer>
    </Layout>
  );
};

const styles = {
  Header: {
    background: "transparent",
    padding: "20px 18px 20px 18px",
    height: "100px",
  },
  Footer: {
    background: "transparent",
    padding: "20px",
  },
  my_auto: {
    marginBottom: "auto",
    marginTop: "auto",
    flex: "unset",
  },
  title: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "36px",
    color: "#FFFFFF",
    textAlign: "center",
    // margin: "63px 0 0 0",
  },
  subtitle: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    color: "#FFFFFF",
    textAlign: "center",
    margin: "0 0 64px 0 ",
  },
  description: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#FFFFFF",
    textAlign: "center",
    margin: "0 0 61px 0 ",
  },
  bottom: {
    justifyContent: "center",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(255, 255, 255, 0.55)",
  },
  input: {
    maxWidth: "56px",
    height: "75px",
    background: "rgba(0, 0, 0, 0.25)",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    // margin: "0 0 40px 0",
  },
  font_input: {
    height: "75px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    color: "rgba(255, 255, 255, 0.8)",
    textAlign: "center",
    // line-height: 28px;
  },
  font_input_err: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.5714285714285714",
    color: "#ff4d4f",
    textAlign: "start",
  },
  padding_border: {
    padding: "0 4.5px",
  },
  buttonSend: {
    width: "100%",
    height: "75px",
    background: "rgba(18, 44, 77, 0.8)",
    borderRadius: "10px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    color: "rgba(255, 255, 255, 0.8)",
    margin: "54px 0 0 0",
    boxShadow: "none",
  },
};
export default CardVerify;
