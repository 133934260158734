import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Divider } from "antd";
import Component from "../../components";
import ScrollContainer from "react-indiana-drag-scroll";
import Get_projects from "../../api/overview/proejcts";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { editTitle } from "../../slice/projectSlice";
import { getonOpen, getcount_unread } from "../../slice/projectSlice";
import Siderbar from "../../components/siderbar";
import HeaderSiderbar from "../../components/headersiderbar";

const Projectlist = () => {
  const [isLoad, setIsload] = useState(false);
  const [Cards, setCards] = useState([]);
  const onOpennitify = useSelector(getonOpen);
  const count_unerad = useSelector(getcount_unread);
  var navigate = useNavigate();

  useEffect(() => {
    if (!isLoad) {
      Get_projects(null, (err, res) => {
        if (!err && res.statusCode == 200) {
          setCards(res.data);
          setIsload(true);
        }
      });
    }
  }, []);
  return (
    <>
      {onOpennitify && (
        <Row gutter={[0, 0]} style={styles.backdrop}>
          <Col
            xs={14}
            sm={14}
            md={16}
            lg={18}
            xl={20}
            xxl={19}
            className="Overflow-Crad-Overview"
          >
            <Row gutter={[0, 0]} style={styles.prompt}>
              <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={2}></Col>
              <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={21}>
                <Row gutter={[48, 87]}>
                  {Cards.map((card) => {
                    return (
                      <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                        <div
                          onClick={() => {
                            // dispatch(editTitle(String(card.project + ' ' + card.building)))
                            navigate(
                              "/overview/" +
                                card.project.replaceAll(" ", "").toLowerCase() +
                                "-" +
                                card.building.replaceAll(" ", "").toLowerCase()
                            );
                          }}
                        >
                          <Component.Cardproject data={card} />
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Col>
            </Row>
          </Col>
          <Col
            className="Siderbackdrop"
            xs={10}
            sm={10}
            md={8}
            lg={6}
            xl={4}
            xxl={5}
          >
            
            <HeaderSiderbar count={count_unerad} type={1} ></HeaderSiderbar>

            <ScrollContainer className="scroll-containers">
              <Siderbar />
            </ScrollContainer>
          </Col>
        </Row>
      )}
      {!onOpennitify && (
        <Row gutter={[0, 0]} style={styles.backdrop}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="Overflow-Crad-Overview"
          >
            <Row gutter={[0, 0]} style={styles.prompt}>
              <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}></Col>
              <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
                <Row gutter={[48, 87]}>
                  {Cards.map((card) => {
                    return (
                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                        <div
                          onClick={() => {
                            // dispatch(editTitle(String(card.project + ' ' + card.building)))
                            navigate(
                              "/overview/" +
                                card.project.replaceAll(" ", "").toLowerCase() +
                                "-" +
                                card.building.replaceAll(" ", "").toLowerCase()
                            );
                          }}
                        >
                          <Component.Cardproject data={card} />
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}></Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

const styles = {
  prompt: {
    // alignItems: "center",
    justifyContent: "center",
  },
  backdrop: {
    background:
      "linear-gradient(144.09deg, rgba(26, 62, 107, 0.72) 0%, rgba(18, 44, 77, 0.72) 100%)",
    WebkitBackdropFilter: "blur(2px)",
    backdropFilter: "blur(2px)",
    width: "100%",
    height: "100%",
    fontWeight: "bold",
    textAlign: "center",
    minHeight: 120,
    color: "#fff",
  },
  box_title: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",

    // width: "366px",
    height: "58px",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "28px",
    // background: "rgba(18, 44, 77, 0.8)",

    /* Inside auto layout */

    flex: "none",
    order: "0",
    flexGrow: "0",
  },
};
export default Projectlist;
