import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import _ from "lodash";
import Layout from "./container/layout/main";
import Page from "./pages";
import useToken from "./api/auth/useToken";
const PrivateRoute = ({ auth: { isAuthenticated }, children }) => {
  const { token, setToken } = useToken()
  console.log('PrivateRoute');
  if ((new Date().valueOf()/1000) < token?.expDay) {
    return children;
  } else {
    return <Navigate to='/login' />;
  }
  return isAuthenticated ? children : <Navigate to='/login' />;
}
function App() {
  const { token, setToken } = useToken()
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/overview" />} />

      <Route
        path="/"
        element={
          <PrivateRoute auth={{ isAuthenticated: false }}>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route index path="overview" element={<Page.Overview />} />
        <Route path="overview/:project" element={<Page.Project />} />
        <Route path="overview/:project/:room" element={<Page.Room />} />
        <Route path="notifications" element={<Page.Notifications />} />
      </Route>

      <Route path="login" element={<Page.Login setToken={setToken} />} />
      <Route path="forgotpassword" element={<Page.Forgotpassword />} />
      <Route path="changepassword" element={<Page.Changepassword />} />
      <Route path="verifyemail" element={<Page.Vetifyemail />} />
      <Route path="newpassword" element={<Page.Newpassword />} />
      <Route path="*" element={<Page.Error404 />} />
    </Routes>
  );
}

export default App;
