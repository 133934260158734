/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
// import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Button, Dropdown, DatePicker, Form, Select } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Home_Icon } from "../../img/project_icon/home";

// import Show_Room from "../../components/notifications/show_room";
import { editTitle } from "../../slice/projectSlice";

import Components from "../../components";
// import ScrollContainer from "react-indiana-drag-scroll";
// import { getonOpen } from "../../slice/projectSlice";

import Getbucketnoti from "../../api/siderbar";
import moment from "moment";

import {
  Battery_Noti,
  Phone_Noti,
  Communicate_Noti,
  Home_golden_Noti,
} from "../../img/home_icon/notification/index.js";
const { RangePicker } = DatePicker;

const Contrner_Notification = () => {
  const dispatch = useDispatch();

  const [status, setStatus] = useState(1);
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [dataNoti, setDataNoti] = useState([]);
  const [dataShow, setDataShow] = useState([]);
  const [dataBackup, setDataBackup] = useState([]);
  const [start_table, setStartTable] = useState(1);
  const [end_table, setEndTable] = useState(10);
  const [sum_table, setSumTable] = useState(0);
  const [show_detail, setShowdetail] = useState(0);
  const [name_project, setNameProject] = useState("");
  const [room_id, setRoomId] = useState("");

  useEffect(() => {
    dispatch(editTitle(String("Manage Notifications")));
    Getbucketnoti({ site: "all" }, (err, res) => {
      if (!err && res.statusCode === 200) {
        console.log(res.data.notify.reverse());
        setDataNoti(res.data.notify.reverse());
        actionSetDataBackup(
          "2000-01-01",
          moment(new Date()).format("YYYY-MM-DD"),
          1,
          res.data.notify.reverse()
        );
      }
    });
  }, []);

  const actionNoti = (e) => {
    var name_project = e.project + "-" + e.building;
    var room_id = e.room_nuber;
    setNameProject(name_project);
    setRoomId(room_id);
    setShowdetail(1);
  };

  const actionBackTable = () => {
    let data_show = [];
    let set_start = start_table - 10;
    let set_end = start_table - 1;
    if (start_table !== 1) {
      setStartTable(set_start);
      setEndTable(set_end);
      dataBackup.map((e, i) => {
        if (i >= set_start - 1 && i <= set_end - 1) {
          data_show.push(e);
        }
      });
      setDataShow(data_show);
    }
  };

  const actionNextTable = () => {
    let data_show = [];
    let set_start = end_table + 1;
    let set_end = end_table + 10;

    if (end_table < sum_table) {
      if (set_end > sum_table) {
        set_end = sum_table;
      }
      setStartTable(set_start);
      setEndTable(set_end);
      dataBackup.map((e, i) => {
        if (i >= set_start - 1 && i <= set_end - 1) {
          data_show.push(e);
        }
      });
      setDataShow(data_show);
    }
  };

  const setDate = (date) => {
    if (date.length > 1) {
      setStartDate(moment(date[0].$d).format("YYYY-MM-DD"));
      setEndDate(moment(date[1].$d).format("YYYY-MM-DD"));
    }
  };
  const actionDropdown = (data) => {
    // setShowdetail(0);
    setStatus(data);

    if (start_date == "" && end_date == "") {
      actionSetDataBackup(
        "2000-01-01",
        moment(new Date()).format("YYYY-MM-DD"),
        data,
        dataNoti
      );
    } else {
      actionSetDataBackup(start_date, end_date, data, dataNoti);
    }
  };

  const actionSetDataBackup = (startdate, enddate, status, data_noti) => {
    let date_filter = [];
    var data_all = data_noti;

    data_all.map((e) => {
      // let date_Time = moment(e.time).format("YYYY-MM-DD");
      let spit_date = e.time.split("-");
      let date_e = spit_date[2] + "-" + spit_date[1] + "-" + spit_date[0];
      let date_Time = moment(date_e).format("YYYY-MM-DD");

      // if (status === 1) {
      //   if (date_Time >= startdate && date_Time <= enddate) {
      //     date_filter.push(e);
      //   }
      // } else 
      if (status === 1) {
        if (
          date_Time >= startdate &&
          date_Time <= enddate &&
          e.readed === "true"
        ) {
          date_filter.push(e);
        }
      } else if (status === 2) {
        if (
          date_Time >= startdate &&
          date_Time <= enddate &&
          e.readed === "false"
        ) {
          date_filter.push(e);
        }
      }
    });

    setDataBackup(date_filter);
    actionSetDataShow(date_filter);
  };
  const actionSetDataShow = (data) => {
    // console.log('actionSetDataShow',data);
    // setSumTable(data.length);
    // var default_data = {
    //   project: "",
    //   building: "",
    //   request: "",
    //   readed: "",
    //   room_nuber: "",
    //   createAt: "",
    // };

    // if (data.length < 10) {
    //   var count_num =  10 - data.length;
    //   for (let i = 0; i < count_num; i++) {
    //     data.push(default_data);
    //   }
    // }

    let set_data = [];
    if (data.length > 0) {
      if (data.length < 10) {
        setStartTable(1);
        setEndTable(data.length);
        setSumTable(data.length);
        setDataShow(data);
      } else {
        data.map((e, i) => {
          if (i >= 0 && i <= 9) {
            set_data.push(e);
          }
        });
        setStartTable(1);
        setDataShow(set_data);
        setSumTable(data.length);
        setEndTable(set_data.length);
      }
    } else {
      setStartTable(0);
      setEndTable(0);
      setSumTable(0);
      setDataShow(data);
    }
  };

  const onChange = (value) => {
    actionDropdown(parseInt(value));
    console.log(`onChange ${value}`);
  }
  const items = [
    // {
    //   label: "Select Status",
    //   key: "1",
    //   onClick: () => {
    //     actionDropdown(1);
    //   },
    // },
    {
      label: "Accepted",
      key: "1",
      onClick: () => {
        actionDropdown(1);
      },
    },
    {
      label: "Unaccepted",
      key: "2",
      onClick: () => {
        actionDropdown(2);
      },
    },
  ];

  const item_select = [
    {
      value: "1",
      label: "Accepted",
    },
    {
      value: "2",
      label: "Unaccepted",
    },
  ];

  const menuProps = {
    items,
  };
  return (
    <>
      <Row gutter={[0, 0]} style={styles.backdrop}>
        <Col
          className="Notification-moniter"
          xs={10}
          sm={10}
          md={8}
          lg={6}
          xl={5}
          xxl={5}
          style={{ padding: "0.75%" }}
        >
          <div className="Overflow-Crad-Project" style={styles.box}>
            <Row style={{ margin: "0 0 10px 0" }}>
              <Col span={12} style={styles.text_date}>
                Start Date
              </Col>
              <Col span={12} style={styles.text_date}>
                End Date
              </Col>
            </Row>
            <RangePicker
              style={{ width: "100%" }}
              className="rangepicker-date"
              onChange={setDate}
              format="DD MMM YYYY"
            />
            <Row>
              {/* <Dropdown
                menu={menuProps}
                trigger={["click"]}
                dropdownRender={(menu) => (
                  <div>
                    {React.cloneElement(menu, {
                      style: styles.menuStyle,
                    })}
                  </div>
                )}
              >
                <Button style={styles.buttonstyle}>
                  <Row
                    align={"middle"}
                    justify={"space-between"}
                    style={styles.text_btn}
                  >
                    <div style={{ margin: "0 10px 0 0" }}>
                      {items[status - 1].label}
                    </div>
                    <svg
                      width="13"
                      height="9"
                      viewBox="0 0 13 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M6.5 9L12.1292 0H0.870835L6.5 9Z" fill="white" />
                    </svg>
                  </Row>
                </Button>
              </Dropdown> */}
            </Row>
            <Row style={{marginTop: '5%'}}>
              <Select
                showSearch
                style={{
                  width: '100%',
                }}
                placeholder="Select Status"
                onChange={onChange}
                options={item_select}
              />
            </Row>
            <Row style={{ marginTop: "20px",height: '600px' }}>
              <Col span={24}>
                <div>
                  <table style={{ width: "100%" }}>
                    <tr
                      style={{
                        background: "rgba(0, 0, 0, 0.15)",
                        height: "45px",
                        borderBottom: "1px solid rgba(255, 255, 255, 0.25)",
                      }}
                    >
                      <th style={styles.tablename}>Select Status</th>
                      <th style={styles.tableroom}>Room</th>
                      <th style={styles.tableroom}>Time</th>
                    </tr>
                    {dataShow.map((e, i) => {
                      let icon_display;
                      let color_background = "rgba(18, 44, 77, 0.8)";
                      let text_status = "Accepted";
                      let text_name = e.project + " " + e.building;
                      text_name =
                        text_name.charAt(0).toUpperCase() +
                        text_name.substring(1);
                      if (e.request.resident)
                        icon_display = <Phone_Noti width={20} height={20} />;
                      if (e.request.juristic)
                        icon_display = (
                          <Communicate_Noti width={20} height={20} />
                        );
                      if (e.request.pir_battery)
                        icon_display = <Battery_Noti width={20} height={20} />;
                      if (e.request.home)
                        icon_display = (
                          <Home_golden_Noti width={20} height={20} />
                        );

                      if (e.readed === "false") {
                        color_background = "rgba(255, 255, 255, 0.1)";
                        text_status = "Unaccepted";
                      }
                      return (
                        <tr
                          style={{
                            borderBottom: "1px solid rgba(255, 255, 255, 0.25)",
                            background: color_background,
                            cursor: "pointer",
                          }}
                          key={i + "noti"}
                          onClick={() => {
                            actionNoti(e);
                          }}
                        >
                          <td style={styles.tablename}>
                            <Row gutter={[16, 16]}>
                              <Col span={4}>{icon_display}</Col>
                              <Col span={20}>
                                <div>{text_name}</div>{" "}
                              </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                              <Col span={4}></Col>
                              <Col span={20}>Status: {text_status}</Col>
                            </Row>
                          </td>
                          <td style={styles.tableroom}> {e.room_nuber} </td>
                          <td style={styles.tableroom}> {e.createAt} </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </Col>
            </Row>
            <Row style={styles.page_number}>
              <Col xs={14} sm={14} md={14} lg={18} xl={20} xxl={19}>
                <div style={{}}>
                  {start_table}-{end_table} of {sum_table} Notifications{" "}
                  <LeftOutlined
                    onClick={() => {
                      actionBackTable();
                    }}
                  />{" "}
                  <RightOutlined
                    onClick={() => {
                      actionNextTable();
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col
          xs={14}
          sm={14}
          md={14}
          lg={18}
          xl={19}
          xxl={19}
          style={{ padding: "0.75%" }}
          className="Notification-moniter"
        >
          {show_detail === 0 ? (
            <div className="Overflow-Crad-Project" style={styles.box1}>
              <Home_Icon color={"white"} count={""} name={"No Room Selected"} />
            </div>
          ) : null}
          {show_detail === 1 ? (
            <div className="Overflow-Crad-Project" style={styles.box}>
              <Components.Show_room
                nameProject={name_project}
                roomId={room_id}
              />
            </div>
          ) : null}
        </Col>
      </Row>
    </>
  );
};
const styles = {
  background_color: {
    background: "linear-gradient(180deg, #1A3E6B 0%, #122C4D 89.52%)",
    height: "100%",
    padding: "19px 22px 0 22px",
  },
  tablename: {
    textAlign: "left",
    padding: "8px",
  },
  tableroom: {
    textAlign: "center",
    width: "15%",
  },
  box1: {
    background: "rgba(255, 255, 255, 0.05)",
    borderRadius: "15px",
    padding: "18px 15px",
    height: "100%",
    paddingTop: "20%",
  },
  box: {
    background: "rgba(255, 255, 255, 0.05)",
    borderRadius: "15px",
    padding: "18px 15px",
    height: "100%",
    // width: "100%",
    // margin: '10px'
  },
  text_btn: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "37px",
  },
  buttonstyle: {
    background: "rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "#FFFFFF",
    margin: "0 0 6px 0",
    border: "0",
    height: "100%",
    minWidth: "100%",
    marginTop: "20px",
  },
  buttonAcceptstyle: {
    background: "#122C4D",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "37px",
    margin: "0 0 6px 0",
    border: "0",
    height: "100%",
    width: "211px",
    boxShadow: "none",
  },
  menuStyle: {
    background: "#122C4D",
    borderRadius: "8px",
    color: "#FFFFFF",
    boxShadow: "none",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
  },
  backdrop: {
    background:
      "linear-gradient(144.09deg, rgba(26, 62, 107, 0.72) 0%, rgba(18, 44, 77, 0.72) 100%)",
    WebkitBackdropFilter: "blur(2px)",
    backdropFilter: "blur(2px)",
    width: "100%",
    height: "100%",
    // fontWeight: "bold",
    textAlign: "center",
    minHeight: 120,
    color: "#fff",
  },
  box_title: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",

    // width: "366px",
    height: "58px",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
    // background: "rgba(18, 44, 77, 0.8)",

    /* Inside auto layout */

    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  page_number: {
    display: "flex",
    alignItems: "end",
    flexDirection: "column",
    paddingTop: "10%",
  },
  text_date: {
    textAlignLast: "start",
  },
};
export default Contrner_Notification;
