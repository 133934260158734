import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Home_Icon } from "../../img/project_icon/home";
import Getroomstatus from "../../api/project/room_device";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  editActive,
  editOnfilter,
  editH_filter,
} from "../../slice/projectSlice";
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 36,
      color: "#ffffff",
    }}
    spin
  />
);
const Room_device = () => {
  const dispatch = useDispatch();
  const Params = useParams();
  const [homeStatus, setHomestatus] = useState([]);
  const [isLoad, setIsload] = useState(true);

  useEffect(() => {
    if (isLoad) {
      Getroomstatus(Params.project, (err, res) => {
        if (!err && res.statusCode === 200) {
          setHomestatus(res.data);
          setIsload(false);
        }
      });
    }
  }, []);
  return (
    <Spin indicator={antIcon} spinning={isLoad}>
      <div style={styles.box}>
        <Row justify={"center"} style={{ margin: "0 0 15px 0" }}>
          <div className="title_rs">Room Devices Status</div>
        </Row>
        <Row gutter={[16, 8]} justify={"center"}>
          {homeStatus.map((data, i) => {
            return (
              <Col
                key={"roomdevice" + i}
                onClick={() => {
                  dispatch(
                    editActive({
                      title: String(data.name),
                      sub: "Status",
                      count_room: data.count,
                    })
                  );
                  dispatch(
                    editOnfilter({
                      serviceRequst: "all",
                      pir_battery: "all",
                      room: "all",
                      time: "all",
                      status: data.name.toLowerCase(),
                      state: "all",
                      floor: "all",
                    })
                  );
                  dispatch(
                    editH_filter({
                      serviceRequst: "all",
                      pir_battery: "all",
                      room: "all",
                      time: "all",
                      status: data.name.toLowerCase(),
                      state: "all",
                      floor: "all",
                    })
                  );
                }}
                style={{ cursor: "pointer" }}
                span={8}
              >
                <Home_Icon
                  color={data.color}
                  count={data.count}
                  name={data.name}
                />
              </Col>
            );
          })}
        </Row>
        <Row justify={"center"}>
          <span style={styles.total}>
            Total&nbsp;&nbsp;&nbsp;{homeStatus.reduce((a, b) => a + b.count, 0)}
          </span>
        </Row>
      </div>
    </Spin>
  );
};
const styles = {
  box: {
    background: "rgba(255, 255, 255, 0.1)",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "15px",
    padding: "13px 15px",
    height: "253px",
    // display: "grid",
    // alignContent: "center",
    // width:'376px'
  },
  total: {
    marginTop: "35px",
    marginBottom: "0px",
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
  },
};
export default Room_device;
