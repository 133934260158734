import { Col, Row } from "antd";

const Residents = (props) => {
  return (
    <>
      <div
        className="width_backbox"
        style={styles.item}
      >
        <div>
          <svg
            className="width_icon_sv_1"
            width="23"
            height="31"
            viewBox="0 0 23 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.8056 0H3.19444C1.43111 0 0 1.45312 0 3.22917V27.7708C0 29.5533 1.43111 31 3.19444 31H19.8056C21.5689 31 23 29.5533 23 27.7708V3.22917C23 1.45312 21.5689 0 19.8056 0ZM9.58333 1.93935H13.4167C13.7696 1.93935 14.0556 2.22838 14.0556 2.58518C14.0556 2.94197 13.7696 3.23101 13.4167 3.23101H9.58333C9.23038 3.23101 8.94444 2.94197 8.94444 2.58518C8.94444 2.22874 9.23073 1.93935 9.58333 1.93935ZM11.5 28.9462C10.7952 28.9462 10.2222 28.3671 10.2222 27.6546C10.2222 26.9421 10.7952 26.3629 11.5 26.3629C12.2049 26.3629 12.7778 26.9421 12.7778 27.6546C12.7778 28.3667 12.2049 28.9462 11.5 28.9462ZM20.4444 23.8974C20.4444 24.2542 20.1585 24.5432 19.8056 24.5432H3.19444C2.84149 24.5432 2.55556 24.2542 2.55556 23.8974V5.81407C2.55556 5.45727 2.84149 5.16823 3.19444 5.16823H19.8056C20.1585 5.16823 20.4444 5.45727 20.4444 5.81407V23.8974Z"
              fill={props.color}
            />
          </svg>
        </div>
        <div>
          <div style={styles.count}>{props.count}</div>
        </div>
      </div>
      {/* <Row justify={'center'}>
                <span style={styles.name}>{props.name}</span>
            </Row> */}
    </>
  );
};
const Juristic = (props) => {
  return (
    <>
      <div
        className="width_backbox"
        style={styles.item}
      >
        <div>
          <svg
            className="width_icon_sv_2"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              width="50px"
              height="50px"
              d="M24.8667 10.8C24.7334 12.3999 23.5999 13.9334 22 13.9334H21.6666C22.1665 12.8666 22.4332 11.6668 22.4332 10.4001C22.4332 9.50006 22.2999 8.63345 22.0333 7.80012H23.2C21.9665 4.06661 18.6667 1.33345 14.8 1.30012C10.9333 1.33337 7.63337 4.03357 6.40004 7.80012H7.70004C7.43334 8.60018 7.30011 9.50006 7.30011 10.4001C7.30011 11.6334 7.5668 12.8668 8.06671 13.9334H7.60006C6.00012 13.9334 4.83339 12.3667 4.73339 10.8C4.26674 5.03363 9.13351 0.0333333 14.8001 0C20.4667 0.0334821 25.3333 5.03345 24.8667 10.8ZM15.8667 13.2C16.3001 13.2 16.6667 13.4 16.8667 13.7334H20.3335C20.8669 12.7668 21.1336 11.6333 21.1336 10.4001C21.1336 6.96672 18.4336 3.66672 14.8669 3.66672C10.567 3.66672 7.80024 8.20005 8.80024 12.1667C9.50036 14.9334 11.9336 17.1667 14.8669 17.1667C16.6668 17.1667 18.267 16.3334 19.4002 15.0334H16.867C16.667 15.3668 16.3003 15.5668 15.8669 15.5668C15.2003 15.5668 14.6669 15.0334 14.6669 14.3668C14.7002 13.7334 15.2335 13.2 15.8667 13.2ZM28.8333 23.9667C28.2667 20.9668 25.6332 18.7667 22.5333 18.7667H20.2668L16.6 27.4667L15.8667 20.8001L16.5333 18.7667H13.3999L14.0665 20.8001L13.3332 27.4667L9.66637 18.7667H7.39982C4.33315 18.7667 1.6997 20.9333 1.09982 23.9667L0 29.9667H30L28.8333 23.9667Z"
              fill={props.color}
            />
          </svg>
        </div>
        <div>
          <div style={styles.count}>{props.count}</div>
        </div>
      </div>
      {/* <Row justify={'center'}>
                <span style={styles.name}>{props.name}</span>
            </Row> */}
    </>
  );
};
const BotRequest = (props) => {
  return (
    <>
      <div
        className="width_backbox"
        style={styles.item}
      >
        <div>
          <svg
            className="width_icon_sv_3"
            width="49"
            height="33"
            viewBox="0 0 49 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M43.3479 11.5536C43.2054 13.2652 41.9928 14.9056 40.2812 14.9056H39.9245C40.4593 13.7644 40.7446 12.4809 40.7446 11.1258C40.7446 10.1629 40.6021 9.23587 40.3168 8.34439H41.565C40.2454 4.35036 36.7153 1.4265 32.5788 1.39084C28.4423 1.42641 24.9121 4.31502 23.5927 8.34439H24.9834C24.6981 9.20028 24.5556 10.1629 24.5556 11.1258C24.5556 12.4451 24.8409 13.7647 25.3757 14.9056H24.8765C23.1649 14.9056 21.9167 13.2296 21.8098 11.5536C21.3106 5.38486 26.5169 0.0356592 32.5789 0C38.6409 0.0358184 43.8471 5.38467 43.3479 11.5536ZM33.7199 14.1211C34.1835 14.1211 34.5758 14.335 34.7897 14.6917H38.4984C39.069 13.6577 39.3543 12.4451 39.3543 11.1257C39.3543 7.45284 36.466 3.92257 32.6504 3.92257C28.0505 3.92257 25.0906 8.77223 26.1604 13.0157C26.9094 15.9754 29.5124 18.3646 32.6504 18.3646C34.5758 18.3646 36.2877 17.4731 37.5 16.0824H34.79C34.5761 16.4391 34.1838 16.653 33.7201 16.653C33.007 16.653 32.4364 16.0824 32.4364 15.3693C32.472 14.6917 33.0426 14.1211 33.7199 14.1211ZM47.5913 25.639C46.9852 22.4298 44.1679 20.0762 40.8517 20.0762H38.427L34.5044 29.3833L33.7199 22.2514L34.433 20.0762H31.081L31.7941 22.2514L31.0096 29.3833L27.087 20.0762H24.6623C21.3816 20.0762 18.5644 22.3939 17.9227 25.639L16.7461 32.0577H48.8394L47.5913 25.639Z"
              fill={props.color}
            />
            <path
              d="M19.664 1.52234H3.17162C1.42088 1.52234 0 2.94957 0 4.69395V28.7982C0 30.549 1.42088 31.9698 3.17162 31.9698H19.664C21.4147 31.9698 22.8356 30.549 22.8356 28.7982V4.69395C22.8356 2.94957 21.4147 1.52234 19.664 1.52234ZM9.51485 3.42712H13.3208C13.6712 3.42712 13.9551 3.71101 13.9551 4.06144C13.9551 4.41188 13.6712 4.69577 13.3208 4.69577H9.51485C9.16441 4.69577 8.88052 4.41188 8.88052 4.06144C8.88052 3.71136 9.16476 3.42712 9.51485 3.42712ZM11.4178 29.9527C10.718 29.9527 10.1492 29.3839 10.1492 28.684C10.1492 27.9842 10.718 27.4154 11.4178 27.4154C12.1176 27.4154 12.6865 27.9842 12.6865 28.684C12.6865 29.3835 12.1176 29.9527 11.4178 29.9527ZM20.2983 24.9938C20.2983 25.3443 20.0144 25.6282 19.664 25.6282H3.17162C2.82118 25.6282 2.53729 25.3443 2.53729 24.9938V7.23279C2.53729 6.88235 2.82118 6.59846 3.17162 6.59846H19.664C20.0144 6.59846 20.2983 6.88235 20.2983 7.23279V24.9938Z"
              fill={props.color}
            />
          </svg>
        </div>
        <div>
          <div style={styles.count}>{props.count}</div>
        </div>
      </div>
      {/* <Row justify={'center'}>
                <span style={styles.name}>{props.name}</span>
            </Row> */}
    </>
  );
};
const Residents_filter = (props) => {
  return (
    <>
      <Row align={"middle"} justify={props.justify}>
        <Col>
          <svg
            width={props.width}
            height="45"
            viewBox="0 0 23 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.8056 0H3.19444C1.43111 0 0 1.45312 0 3.22917V27.7708C0 29.5533 1.43111 31 3.19444 31H19.8056C21.5689 31 23 29.5533 23 27.7708V3.22917C23 1.45312 21.5689 0 19.8056 0ZM9.58333 1.93935H13.4167C13.7696 1.93935 14.0556 2.22838 14.0556 2.58518C14.0556 2.94197 13.7696 3.23101 13.4167 3.23101H9.58333C9.23038 3.23101 8.94444 2.94197 8.94444 2.58518C8.94444 2.22874 9.23073 1.93935 9.58333 1.93935ZM11.5 28.9462C10.7952 28.9462 10.2222 28.3671 10.2222 27.6546C10.2222 26.9421 10.7952 26.3629 11.5 26.3629C12.2049 26.3629 12.7778 26.9421 12.7778 27.6546C12.7778 28.3667 12.2049 28.9462 11.5 28.9462ZM20.4444 23.8974C20.4444 24.2542 20.1585 24.5432 19.8056 24.5432H3.19444C2.84149 24.5432 2.55556 24.2542 2.55556 23.8974V5.81407C2.55556 5.45727 2.84149 5.16823 3.19444 5.16823H19.8056C20.1585 5.16823 20.4444 5.45727 20.4444 5.81407V23.8974Z"
              fill={props.color}
            />
          </svg>
        </Col>
        <Col>
          <span style={styles.counts}>{props.count}</span>
        </Col>
      </Row>
      {/* <Row justify={'center'}>
                <span style={styles.name}>{props.name}</span>
            </Row> */}
    </>
  );
};
const Juristic_filter = (props) => {
  return (
    <>
      <Row align={"middle"} justify={props.justify}>
        <Col>
          <svg
            width={props.width}
            height="45"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              width="50px"
              height="50px"
              d="M24.8667 10.8C24.7334 12.3999 23.5999 13.9334 22 13.9334H21.6666C22.1665 12.8666 22.4332 11.6668 22.4332 10.4001C22.4332 9.50006 22.2999 8.63345 22.0333 7.80012H23.2C21.9665 4.06661 18.6667 1.33345 14.8 1.30012C10.9333 1.33337 7.63337 4.03357 6.40004 7.80012H7.70004C7.43334 8.60018 7.30011 9.50006 7.30011 10.4001C7.30011 11.6334 7.5668 12.8668 8.06671 13.9334H7.60006C6.00012 13.9334 4.83339 12.3667 4.73339 10.8C4.26674 5.03363 9.13351 0.0333333 14.8001 0C20.4667 0.0334821 25.3333 5.03345 24.8667 10.8ZM15.8667 13.2C16.3001 13.2 16.6667 13.4 16.8667 13.7334H20.3335C20.8669 12.7668 21.1336 11.6333 21.1336 10.4001C21.1336 6.96672 18.4336 3.66672 14.8669 3.66672C10.567 3.66672 7.80024 8.20005 8.80024 12.1667C9.50036 14.9334 11.9336 17.1667 14.8669 17.1667C16.6668 17.1667 18.267 16.3334 19.4002 15.0334H16.867C16.667 15.3668 16.3003 15.5668 15.8669 15.5668C15.2003 15.5668 14.6669 15.0334 14.6669 14.3668C14.7002 13.7334 15.2335 13.2 15.8667 13.2ZM28.8333 23.9667C28.2667 20.9668 25.6332 18.7667 22.5333 18.7667H20.2668L16.6 27.4667L15.8667 20.8001L16.5333 18.7667H13.3999L14.0665 20.8001L13.3332 27.4667L9.66637 18.7667H7.39982C4.33315 18.7667 1.6997 20.9333 1.09982 23.9667L0 29.9667H30L28.8333 23.9667Z"
              fill={props.color}
            />
          </svg>
        </Col>
        <Col>
          <span style={styles.counts}>{props.count}</span>
        </Col>
      </Row>
      {/* <Row justify={'center'}>
                <span style={styles.name}>{props.name}</span>
            </Row> */}
    </>
  );
};
const BotRequest_filter = (props) => {
  return (
    <>
      <Row align={"middle"} justify={props.justify}>
        <Col>
          <svg
            width={props.width}
            height="45"
            viewBox="0 0 49 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M43.3479 11.5536C43.2054 13.2652 41.9928 14.9056 40.2812 14.9056H39.9245C40.4593 13.7644 40.7446 12.4809 40.7446 11.1258C40.7446 10.1629 40.6021 9.23587 40.3168 8.34439H41.565C40.2454 4.35036 36.7153 1.4265 32.5788 1.39084C28.4423 1.42641 24.9121 4.31502 23.5927 8.34439H24.9834C24.6981 9.20028 24.5556 10.1629 24.5556 11.1258C24.5556 12.4451 24.8409 13.7647 25.3757 14.9056H24.8765C23.1649 14.9056 21.9167 13.2296 21.8098 11.5536C21.3106 5.38486 26.5169 0.0356592 32.5789 0C38.6409 0.0358184 43.8471 5.38467 43.3479 11.5536ZM33.7199 14.1211C34.1835 14.1211 34.5758 14.335 34.7897 14.6917H38.4984C39.069 13.6577 39.3543 12.4451 39.3543 11.1257C39.3543 7.45284 36.466 3.92257 32.6504 3.92257C28.0505 3.92257 25.0906 8.77223 26.1604 13.0157C26.9094 15.9754 29.5124 18.3646 32.6504 18.3646C34.5758 18.3646 36.2877 17.4731 37.5 16.0824H34.79C34.5761 16.4391 34.1838 16.653 33.7201 16.653C33.007 16.653 32.4364 16.0824 32.4364 15.3693C32.472 14.6917 33.0426 14.1211 33.7199 14.1211ZM47.5913 25.639C46.9852 22.4298 44.1679 20.0762 40.8517 20.0762H38.427L34.5044 29.3833L33.7199 22.2514L34.433 20.0762H31.081L31.7941 22.2514L31.0096 29.3833L27.087 20.0762H24.6623C21.3816 20.0762 18.5644 22.3939 17.9227 25.639L16.7461 32.0577H48.8394L47.5913 25.639Z"
              fill={props.color}
            />
            <path
              d="M19.664 1.52234H3.17162C1.42088 1.52234 0 2.94957 0 4.69395V28.7982C0 30.549 1.42088 31.9698 3.17162 31.9698H19.664C21.4147 31.9698 22.8356 30.549 22.8356 28.7982V4.69395C22.8356 2.94957 21.4147 1.52234 19.664 1.52234ZM9.51485 3.42712H13.3208C13.6712 3.42712 13.9551 3.71101 13.9551 4.06144C13.9551 4.41188 13.6712 4.69577 13.3208 4.69577H9.51485C9.16441 4.69577 8.88052 4.41188 8.88052 4.06144C8.88052 3.71136 9.16476 3.42712 9.51485 3.42712ZM11.4178 29.9527C10.718 29.9527 10.1492 29.3839 10.1492 28.684C10.1492 27.9842 10.718 27.4154 11.4178 27.4154C12.1176 27.4154 12.6865 27.9842 12.6865 28.684C12.6865 29.3835 12.1176 29.9527 11.4178 29.9527ZM20.2983 24.9938C20.2983 25.3443 20.0144 25.6282 19.664 25.6282H3.17162C2.82118 25.6282 2.53729 25.3443 2.53729 24.9938V7.23279C2.53729 6.88235 2.82118 6.59846 3.17162 6.59846H19.664C20.0144 6.59846 20.2983 6.88235 20.2983 7.23279V24.9938Z"
              fill={props.color}
            />
          </svg>
        </Col>
        <Col>
          <span style={styles.counts}>{props.count}</span>
        </Col>
      </Row>
      {/* <Row justify={'center'}>
                <span style={styles.name}>{props.name}</span>
            </Row> */}
    </>
  );
};
const Residents_Htr_Sv = (props) => {
  return (
    <>
      <Row justify={props.justify} align={"middle"}>
        <Col>
          <svg
            width={props.width}
            height="31"
            viewBox="0 0 23 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.8056 0H3.19444C1.43111 0 0 1.45312 0 3.22917V27.7708C0 29.5533 1.43111 31 3.19444 31H19.8056C21.5689 31 23 29.5533 23 27.7708V3.22917C23 1.45312 21.5689 0 19.8056 0ZM9.58333 1.93935H13.4167C13.7696 1.93935 14.0556 2.22838 14.0556 2.58518C14.0556 2.94197 13.7696 3.23101 13.4167 3.23101H9.58333C9.23038 3.23101 8.94444 2.94197 8.94444 2.58518C8.94444 2.22874 9.23073 1.93935 9.58333 1.93935ZM11.5 28.9462C10.7952 28.9462 10.2222 28.3671 10.2222 27.6546C10.2222 26.9421 10.7952 26.3629 11.5 26.3629C12.2049 26.3629 12.7778 26.9421 12.7778 27.6546C12.7778 28.3667 12.2049 28.9462 11.5 28.9462ZM20.4444 23.8974C20.4444 24.2542 20.1585 24.5432 19.8056 24.5432H3.19444C2.84149 24.5432 2.55556 24.2542 2.55556 23.8974V5.81407C2.55556 5.45727 2.84149 5.16823 3.19444 5.16823H19.8056C20.1585 5.16823 20.4444 5.45727 20.4444 5.81407V23.8974Z"
              fill={props.color}
            />
          </svg>
        </Col>
        <Col>
          <span style={styles.count}>{props.count}</span>
        </Col>
      </Row>
      <Row justify={"center"}>
        <span style={styles.name}>{props.name}</span>
      </Row>
    </>
  );
};
const Juristic_Htr_Sv = (props) => {
  return (
    <>
      <Row justify={props.justify} align={"middle"}>
        <Col>
          <svg
            width={props.width}
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              width="50px"
              height="50px"
              d="M24.8667 10.8C24.7334 12.3999 23.5999 13.9334 22 13.9334H21.6666C22.1665 12.8666 22.4332 11.6668 22.4332 10.4001C22.4332 9.50006 22.2999 8.63345 22.0333 7.80012H23.2C21.9665 4.06661 18.6667 1.33345 14.8 1.30012C10.9333 1.33337 7.63337 4.03357 6.40004 7.80012H7.70004C7.43334 8.60018 7.30011 9.50006 7.30011 10.4001C7.30011 11.6334 7.5668 12.8668 8.06671 13.9334H7.60006C6.00012 13.9334 4.83339 12.3667 4.73339 10.8C4.26674 5.03363 9.13351 0.0333333 14.8001 0C20.4667 0.0334821 25.3333 5.03345 24.8667 10.8ZM15.8667 13.2C16.3001 13.2 16.6667 13.4 16.8667 13.7334H20.3335C20.8669 12.7668 21.1336 11.6333 21.1336 10.4001C21.1336 6.96672 18.4336 3.66672 14.8669 3.66672C10.567 3.66672 7.80024 8.20005 8.80024 12.1667C9.50036 14.9334 11.9336 17.1667 14.8669 17.1667C16.6668 17.1667 18.267 16.3334 19.4002 15.0334H16.867C16.667 15.3668 16.3003 15.5668 15.8669 15.5668C15.2003 15.5668 14.6669 15.0334 14.6669 14.3668C14.7002 13.7334 15.2335 13.2 15.8667 13.2ZM28.8333 23.9667C28.2667 20.9668 25.6332 18.7667 22.5333 18.7667H20.2668L16.6 27.4667L15.8667 20.8001L16.5333 18.7667H13.3999L14.0665 20.8001L13.3332 27.4667L9.66637 18.7667H7.39982C4.33315 18.7667 1.6997 20.9333 1.09982 23.9667L0 29.9667H30L28.8333 23.9667Z"
              fill={props.color}
            />
          </svg>
        </Col>
        <Col>
          <span style={styles.count}>{props.count}</span>
        </Col>
      </Row>
      <Row justify={"center"}>
        <span style={styles.name}>{props.name}</span>
      </Row>
    </>
  );
};
const BotRequest_Htr_Sv = (props) => {
  return (
    <>
      <Row justify={props.justify} align={"middle"}>
        <Col>
          <svg
            width={props.width}
            height="33"
            viewBox="0 0 49 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M43.3479 11.5536C43.2054 13.2652 41.9928 14.9056 40.2812 14.9056H39.9245C40.4593 13.7644 40.7446 12.4809 40.7446 11.1258C40.7446 10.1629 40.6021 9.23587 40.3168 8.34439H41.565C40.2454 4.35036 36.7153 1.4265 32.5788 1.39084C28.4423 1.42641 24.9121 4.31502 23.5927 8.34439H24.9834C24.6981 9.20028 24.5556 10.1629 24.5556 11.1258C24.5556 12.4451 24.8409 13.7647 25.3757 14.9056H24.8765C23.1649 14.9056 21.9167 13.2296 21.8098 11.5536C21.3106 5.38486 26.5169 0.0356592 32.5789 0C38.6409 0.0358184 43.8471 5.38467 43.3479 11.5536ZM33.7199 14.1211C34.1835 14.1211 34.5758 14.335 34.7897 14.6917H38.4984C39.069 13.6577 39.3543 12.4451 39.3543 11.1257C39.3543 7.45284 36.466 3.92257 32.6504 3.92257C28.0505 3.92257 25.0906 8.77223 26.1604 13.0157C26.9094 15.9754 29.5124 18.3646 32.6504 18.3646C34.5758 18.3646 36.2877 17.4731 37.5 16.0824H34.79C34.5761 16.4391 34.1838 16.653 33.7201 16.653C33.007 16.653 32.4364 16.0824 32.4364 15.3693C32.472 14.6917 33.0426 14.1211 33.7199 14.1211ZM47.5913 25.639C46.9852 22.4298 44.1679 20.0762 40.8517 20.0762H38.427L34.5044 29.3833L33.7199 22.2514L34.433 20.0762H31.081L31.7941 22.2514L31.0096 29.3833L27.087 20.0762H24.6623C21.3816 20.0762 18.5644 22.3939 17.9227 25.639L16.7461 32.0577H48.8394L47.5913 25.639Z"
              fill={props.color}
            />
            <path
              d="M19.664 1.52234H3.17162C1.42088 1.52234 0 2.94957 0 4.69395V28.7982C0 30.549 1.42088 31.9698 3.17162 31.9698H19.664C21.4147 31.9698 22.8356 30.549 22.8356 28.7982V4.69395C22.8356 2.94957 21.4147 1.52234 19.664 1.52234ZM9.51485 3.42712H13.3208C13.6712 3.42712 13.9551 3.71101 13.9551 4.06144C13.9551 4.41188 13.6712 4.69577 13.3208 4.69577H9.51485C9.16441 4.69577 8.88052 4.41188 8.88052 4.06144C8.88052 3.71136 9.16476 3.42712 9.51485 3.42712ZM11.4178 29.9527C10.718 29.9527 10.1492 29.3839 10.1492 28.684C10.1492 27.9842 10.718 27.4154 11.4178 27.4154C12.1176 27.4154 12.6865 27.9842 12.6865 28.684C12.6865 29.3835 12.1176 29.9527 11.4178 29.9527ZM20.2983 24.9938C20.2983 25.3443 20.0144 25.6282 19.664 25.6282H3.17162C2.82118 25.6282 2.53729 25.3443 2.53729 24.9938V7.23279C2.53729 6.88235 2.82118 6.59846 3.17162 6.59846H19.664C20.0144 6.59846 20.2983 6.88235 20.2983 7.23279V24.9938Z"
              fill={props.color}
            />
          </svg>
        </Col>
        <Col>
          <span style={styles.count}>{props.count}</span>
        </Col>
      </Row>
      <Row justify={"center"}>
        <span style={styles.name}>{props.name}</span>
      </Row>
    </>
  );
};
const Battery_Htr_Sv = (props) => {
  return (
    <svg
      width={props.width}
      height="15"
      viewBox="0 1 24 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6895 13.031V11.0457H22.37C23.1688 11.0457 23.8182 10.3455 23.8182 9.48582V5.51458C23.8182 4.65453 23.1687 3.95472 22.37 3.95472H21.6895V1.96922C21.6895 1.10917 21.0397 0.409363 20.2412 0.409363L1.63017 0.40918C0.831294 0.40918 0.181885 1.10899 0.181885 1.96904V13.0311C0.181885 13.8912 0.831468 14.591 1.63017 14.591H20.2407C21.0396 14.5908 21.6894 13.891 21.6894 13.031L21.6895 13.031ZM1.63026 13.1729C1.55791 13.1729 1.49848 13.1093 1.49848 13.031L1.49865 1.96889C1.49865 1.89079 1.55775 1.82695 1.63043 1.82695H20.241C20.3135 1.82695 20.3724 1.89061 20.3724 1.96889V13.031C20.3724 13.1091 20.3135 13.1729 20.241 13.1729H1.63026ZM2.25593 2.74906V12.2509H5.9927V2.74906H2.25593Z"
        fill={props.color}
      />
    </svg>
  );
};
const styles = {
  name: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    marginTop: "5px",
  },
  item: {
    backgroundColor: "#122C4D",
    borderRadius: "8px",
    justifyContent: "center",
    height: "107px",
    // width: "108px"
  },
  count: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    // margin: "0 4px",
  },
  counts: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    // margin: "0 2px",
  },
};
export {
  Residents,
  Juristic,
  BotRequest,
  Residents_filter,
  Juristic_filter,
  BotRequest_filter,
  Residents_Htr_Sv,
  Juristic_Htr_Sv,
  BotRequest_Htr_Sv,
  Battery_Htr_Sv,
};
