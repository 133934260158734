
const Home_icon = () => {

    return (
        <div>
            <svg width="129" height="53" viewBox="0 0 129 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 13.2825V0H2.16925V5.22785H6.43605V0H8.61427V13.2825H6.43605V7.47483H2.16925V13.2825H0Z"
                    fill="white" />
                <path
                    d="M10.3622 8.33557C10.3622 7.39329 10.5475 6.53557 10.918 5.76242C11.2885 4.98926 11.7725 4.4094 12.3701 4.02282C12.9677 3.63624 13.64 3.44295 14.387 3.44295C15.6419 3.44295 16.628 3.94128 17.3451 4.93792C18.0622 5.92852 18.4207 7.08825 18.4207 8.41711C18.4207 9.40772 18.2325 10.3017 17.856 11.099C17.4855 11.8963 16.9955 12.4973 16.3859 12.902C15.7824 13.3007 15.119 13.5 14.396 13.5C13.2366 13.5 12.2745 13.053 11.5096 12.1591C10.7447 11.2651 10.3622 9.9906 10.3622 8.33557ZM12.4777 8.47148C12.4777 9.44396 12.6659 10.1809 13.0424 10.6822C13.4189 11.1775 13.8731 11.4252 14.4049 11.4252C14.9248 11.4252 15.37 11.1745 15.7405 10.6732C16.111 10.1718 16.2963 9.43188 16.2963 8.45336C16.2963 7.49899 16.1081 6.77114 15.7316 6.2698C15.3551 5.76846 14.9039 5.51779 14.378 5.51779C13.8581 5.51779 13.4099 5.76846 13.0335 6.2698C12.6629 6.77114 12.4777 7.50503 12.4777 8.47148Z"
                    fill="white" />
                <path
                    d="M19.8818 3.6604H21.7822V4.97416C22.1228 4.46074 22.4963 4.07718 22.9027 3.82349C23.309 3.5698 23.7483 3.44295 24.2204 3.44295C24.7223 3.44295 25.1556 3.5698 25.5201 3.82349C25.8906 4.07718 26.1924 4.46074 26.4255 4.97416C26.7721 4.46074 27.1426 4.07718 27.537 3.82349C27.9374 3.5698 28.3617 3.44295 28.8099 3.44295C29.3417 3.44295 29.7989 3.57282 30.1813 3.83255C30.5698 4.08624 30.8626 4.45168 31.0598 4.92886C31.263 5.40604 31.3646 6.13993 31.3646 7.13054V13.2825H29.2939V7.78289C29.2939 6.82248 29.2102 6.18523 29.0429 5.87114C28.8756 5.55101 28.5947 5.39094 28.2003 5.39094C27.7402 5.39094 27.3697 5.61443 27.0888 6.06141C26.8079 6.50235 26.6675 7.36913 26.6675 8.66175V13.2825H24.5968V8.0094C24.5968 6.96443 24.5401 6.29094 24.4265 5.98893C24.2771 5.59027 23.9753 5.39094 23.5212 5.39094C23.2164 5.39094 22.9355 5.49362 22.6786 5.69899C22.4216 5.90436 22.2364 6.20638 22.1228 6.60503C22.0093 6.99765 21.9525 7.6651 21.9525 8.60738V13.2825H19.8818V3.6604Z"
                    fill="white" />
                <path
                    d="M37.962 10.2201L40.0147 10.646C39.7398 11.6124 39.3125 12.3312 38.7328 12.8023C38.1532 13.2674 37.448 13.5 36.6174 13.5C35.464 13.5 34.5736 13.1104 33.9461 12.3312C33.2051 11.4252 32.8346 10.1567 32.8346 8.52584C32.8346 6.91913 33.2081 5.63255 33.9551 4.66611C34.5886 3.85067 35.4073 3.44295 36.4112 3.44295C37.5287 3.44295 38.4042 3.85973 39.0376 4.69329C39.7667 5.64765 40.1312 7.05503 40.1312 8.91544L40.1222 9.20537H34.9501C34.9621 9.96644 35.1294 10.5554 35.4521 10.9721C35.7808 11.3889 36.1752 11.5973 36.6353 11.5973C37.2986 11.5973 37.7408 11.1383 37.962 10.2201ZM38.0785 7.64698C38.0606 6.89799 37.9022 6.33624 37.6034 5.96174C37.3046 5.58121 36.949 5.39094 36.5367 5.39094C36.1005 5.39094 35.7329 5.58725 35.4341 5.97987C35.1294 6.37852 34.98 6.93423 34.986 7.64698H38.0785Z"
                    fill="white" />
                <path
                    d="M55.1726 13.2825H52.8061L51.8649 10.2654H47.5623L46.6748 13.2825H44.3622L48.5572 0H50.861L55.1726 13.2825ZM51.1657 8.02752L49.6867 3.09866L48.2256 8.02752H51.1657Z"
                    fill="white" />
                <path
                    d="M61.4383 13.2825V11.8419C61.1515 12.3614 60.775 12.7691 60.3089 13.0651C59.8427 13.355 59.3557 13.5 58.8478 13.5C58.3338 13.5 57.8737 13.3641 57.4673 13.0923C57.061 12.8205 56.7562 12.4339 56.553 11.9326C56.3558 11.4312 56.2572 10.7034 56.2572 9.74899V3.6604H58.3279V8.08188C58.3279 9.34429 58.3577 10.1477 58.4175 10.4919C58.4832 10.8302 58.6237 11.093 58.8388 11.2802C59.0539 11.4674 59.3139 11.5611 59.6186 11.5611C59.9653 11.5611 60.285 11.4403 60.5778 11.1987C60.8706 10.951 61.0618 10.6369 61.1515 10.2564C61.2471 9.8698 61.2949 9.02416 61.2949 7.71946V3.6604H63.3566V13.2825H61.4383Z"
                    fill="white" />
                <path
                    d="M69.0755 3.6604V5.68993H67.6682V9.56779C67.6682 10.4074 67.6891 10.8936 67.7309 11.0265C67.8146 11.2681 67.9849 11.3889 68.2419 11.3889C68.4331 11.3889 68.708 11.3074 69.0666 11.1443L69.2458 13.1195C68.7678 13.3732 68.2269 13.5 67.6234 13.5C67.0975 13.5 66.6762 13.3732 66.3595 13.1195C66.0427 12.8658 65.8306 12.5003 65.723 12.0232C65.6394 11.6728 65.5975 10.9601 65.5975 9.8849V5.68993H64.6474V3.6604H65.5975V1.74866L67.6682 0.262752V3.6604H69.0755Z"
                    fill="white" />
                <path
                    d="M70.0436 8.33557C70.0436 7.39329 70.2289 6.53557 70.5994 5.76242C70.9699 4.98926 71.4539 4.4094 72.0515 4.02282C72.6491 3.63624 73.3214 3.44295 74.0684 3.44295C75.3233 3.44295 76.3094 3.94128 77.0265 4.93792C77.7436 5.92852 78.1021 7.08825 78.1021 8.41711C78.1021 9.40772 77.9139 10.3017 77.5374 11.099C77.1669 11.8963 76.6769 12.4973 76.0673 12.902C75.4638 13.3007 74.8004 13.5 74.0774 13.5C72.918 13.5 71.9559 13.053 71.191 12.1591C70.4261 11.2651 70.0436 9.9906 70.0436 8.33557ZM72.1591 8.47148C72.1591 9.44396 72.3473 10.1809 72.7238 10.6822C73.1003 11.1775 73.5545 11.4252 74.0863 11.4252C74.6062 11.4252 75.0514 11.1745 75.4219 10.6732C75.7924 10.1718 75.9777 9.43188 75.9777 8.45336C75.9777 7.49899 75.7895 6.77114 75.413 6.2698C75.0365 5.76846 74.5853 5.51779 74.0594 5.51779C73.5395 5.51779 73.0913 5.76846 72.7149 6.2698C72.3443 6.77114 72.1591 7.50503 72.1591 8.47148Z"
                    fill="white" />
                <path
                    d="M79.5632 3.6604H81.4636V4.97416C81.8042 4.46074 82.1777 4.07718 82.5841 3.82349C82.9904 3.5698 83.4297 3.44295 83.9017 3.44295C84.4037 3.44295 84.837 3.5698 85.2015 3.82349C85.572 4.07718 85.8738 4.46074 86.1069 4.97416C86.4535 4.46074 86.824 4.07718 87.2184 3.82349C87.6188 3.5698 88.0431 3.44295 88.4912 3.44295C89.0231 3.44295 89.4803 3.57282 89.8627 3.83255C90.2512 4.08624 90.544 4.45168 90.7412 4.92886C90.9444 5.40604 91.046 6.13993 91.046 7.13054V13.2825H88.9753V7.78289C88.9753 6.82248 88.8916 6.18523 88.7243 5.87114C88.557 5.55101 88.2761 5.39094 87.8817 5.39094C87.4216 5.39094 87.0511 5.61443 86.7702 6.06141C86.4893 6.50235 86.3489 7.36913 86.3489 8.66175V13.2825H84.2782V8.0094C84.2782 6.96443 84.2215 6.29094 84.1079 5.98893C83.9585 5.59027 83.6567 5.39094 83.2026 5.39094C82.8978 5.39094 82.6169 5.49362 82.36 5.69899C82.103 5.90436 81.9177 6.20638 81.8042 6.60503C81.6907 6.99765 81.6339 7.6651 81.6339 8.60738V13.2825H79.5632V3.6604Z"
                    fill="white" />
                <path
                    d="M94.6584 6.59597L92.7849 6.17919C93.012 5.20671 93.3855 4.50906 93.9054 4.08624C94.4313 3.65738 95.1843 3.44295 96.1643 3.44295C97.0368 3.44295 97.7031 3.56678 98.1633 3.81443C98.6234 4.06208 98.9521 4.40034 99.1493 4.82919C99.3465 5.25201 99.4451 6.02517 99.4451 7.14866L99.4272 10.1205C99.4272 10.954 99.46 11.5762 99.5258 11.9869C99.5915 12.3916 99.714 12.8235 99.8933 13.2825H97.8495L97.5806 12.2315C97.228 12.6544 96.8486 12.9715 96.4422 13.1829C96.0418 13.3943 95.6145 13.5 95.1604 13.5C94.4014 13.5 93.7799 13.2433 93.2959 12.7299C92.8118 12.2104 92.5698 11.5218 92.5698 10.6641C92.5698 10.1205 92.6684 9.64631 92.8656 9.24161C93.0628 8.83691 93.3407 8.51376 93.6993 8.27215C94.0578 8.03054 94.6315 7.81611 95.4203 7.62886C96.3765 7.40537 97.0428 7.19698 97.4193 7.00369C97.4193 6.47215 97.3804 6.12483 97.3027 5.96174C97.231 5.79262 97.0936 5.65671 96.8904 5.55403C96.6932 5.4453 96.4123 5.39094 96.0478 5.39094C95.6773 5.39094 95.3845 5.4755 95.1693 5.64463C94.9602 5.81376 94.7899 6.13087 94.6584 6.59597ZM97.4193 8.66175C97.1563 8.77047 96.747 8.89732 96.1912 9.04228C95.5518 9.21141 95.1335 9.39866 94.9363 9.60403C94.7391 9.8094 94.6405 10.0752 94.6405 10.4013C94.6405 10.7698 94.748 11.0809 94.9632 11.3346C95.1843 11.5822 95.4592 11.706 95.7878 11.706C96.0807 11.706 96.3705 11.6064 96.6573 11.407C96.9442 11.2017 97.1414 10.9601 97.2489 10.6822C97.3625 10.4044 97.4193 9.9 97.4193 9.16913V8.66175Z"
                    fill="white" />
                <path
                    d="M105.056 3.6604V5.68993H103.649V9.56779C103.649 10.4074 103.67 10.8936 103.712 11.0265C103.796 11.2681 103.966 11.3889 104.223 11.3889C104.414 11.3889 104.689 11.3074 105.048 11.1443L105.227 13.1195C104.749 13.3732 104.208 13.5 103.604 13.5C103.078 13.5 102.657 13.3732 102.34 13.1195C102.024 12.8658 101.812 12.5003 101.704 12.0232C101.62 11.6728 101.578 10.9601 101.578 9.8849V5.68993H100.628V3.6604H101.578V1.74866L103.649 0.262752V3.6604H105.056Z"
                    fill="white" />
                <path
                    d="M106.509 2.3557V0H108.579V2.3557H106.509ZM106.509 13.2825V3.6604H108.579V13.2825H106.509Z"
                    fill="white" />
                <path
                    d="M110.22 8.33557C110.22 7.39329 110.405 6.53557 110.775 5.76242C111.146 4.98926 111.63 4.4094 112.228 4.02282C112.825 3.63624 113.497 3.44295 114.244 3.44295C115.499 3.44295 116.485 3.94128 117.202 4.93792C117.92 5.92852 118.278 7.08825 118.278 8.41711C118.278 9.40772 118.09 10.3017 117.713 11.099C117.343 11.8963 116.853 12.4973 116.243 12.902C115.64 13.3007 114.976 13.5 114.253 13.5C113.094 13.5 112.132 13.053 111.367 12.1591C110.602 11.2651 110.22 9.9906 110.22 8.33557ZM112.335 8.47148C112.335 9.44396 112.523 10.1809 112.9 10.6822C113.276 11.1775 113.73 11.4252 114.262 11.4252C114.782 11.4252 115.227 11.1745 115.598 10.6732C115.968 10.1718 116.154 9.43188 116.154 8.45336C116.154 7.49899 115.965 6.77114 115.589 6.2698C115.213 5.76846 114.761 5.51779 114.235 5.51779C113.716 5.51779 113.267 5.76846 112.891 6.2698C112.52 6.77114 112.335 7.50503 112.335 8.47148Z"
                    fill="white" />
                <path
                    d="M127 13.2825H124.929V8.37181C124.929 7.38725 124.891 6.73188 124.813 6.4057C124.735 6.07953 124.589 5.82886 124.374 5.65369C124.164 5.47852 123.925 5.39094 123.656 5.39094C123.31 5.39094 122.996 5.5057 122.715 5.73523C122.434 5.95872 122.237 6.2698 122.124 6.66846C122.01 7.06711 121.953 7.81913 121.953 8.9245V13.2825H119.883V3.6604H121.801V5.07383C122.142 4.5302 122.524 4.12248 122.948 3.85067C123.379 3.57886 123.854 3.44295 124.374 3.44295C124.977 3.44295 125.485 3.60302 125.897 3.92315C126.316 4.23725 126.603 4.64497 126.758 5.14631C126.919 5.64161 127 6.3604 127 7.30268V13.2825Z"
                    fill="white" />
                <path
                    d="M0.5 46.5248V24.5H5.9961L9.29678 39.5237L12.5672 24.5H18.0784V46.5248H14.6718V29.1874L11.0531 46.5248H7.51017L3.92181 29.1874V46.5248H0.5Z"
                    fill="white" />
                <path
                    d="M20.9097 38.3218C20.9097 36.7594 21.2227 35.3371 21.8485 34.0551C22.4743 32.7731 23.2919 31.8115 24.3013 31.1705C25.3107 30.5295 26.4462 30.209 27.7079 30.209C29.8277 30.209 31.4931 31.0353 32.7044 32.6879C33.9157 34.3305 34.5213 36.2536 34.5213 38.457C34.5213 40.0996 34.2033 41.582 33.5674 42.9041C32.9416 44.2261 32.1139 45.2227 31.0843 45.8938C30.0649 46.5548 28.9444 46.8853 27.7231 46.8853C25.7649 46.8853 24.1398 46.1442 22.8478 44.6618C21.5558 43.1795 20.9097 41.0662 20.9097 38.3218ZM24.483 38.5472C24.483 40.1597 24.8009 41.3817 25.4368 42.213C26.0727 43.0343 26.8399 43.4449 27.7382 43.4449C28.6164 43.4449 29.3684 43.0293 29.9942 42.1979C30.62 41.3666 30.9329 40.1397 30.9329 38.5171C30.9329 36.9346 30.615 35.7277 29.9791 34.8964C29.3432 34.0651 28.5811 33.6494 27.6928 33.6494C26.8146 33.6494 26.0576 34.0651 25.4217 34.8964C24.7959 35.7277 24.483 36.9446 24.483 38.5472Z"
                    fill="white" />
                <path
                    d="M49.2533 46.5248H45.7557V38.3819C45.7557 36.7493 45.6901 35.6626 45.5589 35.1218C45.4277 34.5809 45.1804 34.1653 44.817 33.8748C44.4637 33.5843 44.06 33.4391 43.6057 33.4391C43.0203 33.4391 42.4904 33.6294 42.016 34.01C41.5416 34.3806 41.2085 34.8964 41.0167 35.5575C40.8249 36.2185 40.729 37.4655 40.729 39.2984V46.5248H37.2315V30.5696H40.4716V32.9133C41.047 32.0119 41.693 31.3358 42.4096 30.8851C43.1364 30.4344 43.9388 30.209 44.817 30.209C45.8365 30.209 46.6945 30.4744 47.3909 31.0053C48.0975 31.5261 48.582 32.2022 48.8445 33.0335C49.117 33.8548 49.2533 35.0467 49.2533 36.6091V46.5248Z"
                    fill="white" />
                <path
                    d="M52.7962 28.4062V24.5H56.2937V28.4062H52.7962ZM52.7962 46.5248V30.5696H56.2937V46.5248H52.7962Z"
                    fill="white" />
                <path
                    d="M65.9081 30.5696V33.9349H63.531V40.3651C63.531 41.7572 63.5663 42.5635 63.637 42.7839C63.7783 43.1845 64.066 43.3848 64.5 43.3848C64.823 43.3848 65.2873 43.2496 65.893 42.9792L66.1958 46.2544C65.3883 46.675 64.4748 46.8853 63.4553 46.8853C62.567 46.8853 61.8554 46.675 61.3204 46.2544C60.7855 45.8337 60.4271 45.2277 60.2454 44.4365C60.1041 43.8556 60.0335 42.6737 60.0335 40.8909V33.9349H58.4286V30.5696H60.0335V27.3996L63.531 24.9357V30.5696H65.9081Z"
                    fill="white" />
                <path
                    d="M67.5433 38.3218C67.5433 36.7594 67.8562 35.3371 68.482 34.0551C69.1078 32.7731 69.9254 31.8115 70.9348 31.1705C71.9442 30.5295 73.0798 30.209 74.3415 30.209C76.4612 30.209 78.1267 31.0353 79.3379 32.6879C80.5492 34.3305 81.1548 36.2536 81.1548 38.457C81.1548 40.0996 80.8369 41.582 80.201 42.9041C79.5751 44.2261 78.7475 45.2227 77.7179 45.8938C76.6984 46.5548 75.578 46.8853 74.3566 46.8853C72.3984 46.8853 70.7733 46.1442 69.4813 44.6618C68.1893 43.1795 67.5433 41.0662 67.5433 38.3218ZM71.1165 38.5472C71.1165 40.1597 71.4345 41.3817 72.0704 42.213C72.7063 43.0343 73.4734 43.4449 74.3718 43.4449C75.2499 43.4449 76.0019 43.0293 76.6277 42.1979C77.2536 41.3666 77.5665 40.1397 77.5665 38.5171C77.5665 36.9346 77.2485 35.7277 76.6126 34.8964C75.9767 34.0651 75.2146 33.6494 74.3264 33.6494C73.4482 33.6494 72.6911 34.0651 72.0552 34.8964C71.4294 35.7277 71.1165 36.9446 71.1165 38.5472Z"
                    fill="white" />
                <path
                    d="M87.2414 46.5248H83.7439V30.5696H86.984V32.8382C87.5392 31.7765 88.0338 31.0754 88.4678 30.7348C88.9119 30.3843 89.4217 30.209 89.997 30.209C90.7945 30.209 91.5565 30.4744 92.2833 31.0053L91.1932 34.6861C90.6178 34.2254 90.0727 33.995 89.558 33.995C89.0735 33.995 88.6445 34.1753 88.271 34.5358C87.9076 34.8864 87.6452 35.5274 87.4837 36.4589C87.3222 37.3904 87.2414 39.1031 87.2414 41.597V46.5248Z"
                    fill="white" />
                <path
                    d="M93.7974 28.4062V24.5H97.2949V28.4062H93.7974ZM93.7974 46.5248V30.5696H97.2949V46.5248H93.7974Z"
                    fill="white" />
                <path
                    d="M112.875 46.5248H109.377V38.3819C109.377 36.7493 109.312 35.6626 109.18 35.1218C109.049 34.5809 108.802 34.1653 108.438 33.8748C108.085 33.5843 107.681 33.4391 107.227 33.4391C106.642 33.4391 106.112 33.6294 105.637 34.01C105.163 34.3806 104.83 34.8964 104.638 35.5575C104.446 36.2185 104.35 37.4655 104.35 39.2984V46.5248H100.853V30.5696H104.093V32.9133C104.668 32.0119 105.314 31.3358 106.031 30.8851C106.758 30.4344 107.56 30.209 108.438 30.209C109.458 30.209 110.316 30.4744 111.012 31.0053C111.719 31.5261 112.203 32.2022 112.466 33.0335C112.738 33.8548 112.875 35.0467 112.875 36.6091V46.5248Z"
                    fill="white" />
                <path
                    d="M116.085 47.5764L120.082 48.1624C120.142 48.7433 120.324 49.1539 120.627 49.3943C120.94 49.6447 121.429 49.7699 122.095 49.7699C122.863 49.7699 123.483 49.6447 123.958 49.3943C124.291 49.214 124.548 48.9135 124.73 48.4929C124.912 48.0722 125.002 47.331 125.002 46.2694V43.9407C124.478 44.8021 123.887 45.4481 123.231 45.8788C122.575 46.3094 121.848 46.5248 121.051 46.5248C119.587 46.5248 118.356 45.8687 117.356 44.5567C116.206 43.0543 115.63 41.0211 115.63 38.457C115.63 35.7327 116.17 33.6795 117.25 32.2973C118.341 30.9051 119.658 30.209 121.202 30.209C122.02 30.209 122.762 30.4244 123.428 30.855C124.104 31.2857 124.705 31.9367 125.23 32.8081V30.5696H128.5V44.8872C128.5 47.1808 128.288 48.8434 127.864 49.8751C127.44 50.9067 126.784 51.6829 125.896 52.2037C125.018 52.7346 123.796 53 122.232 53C120.788 53 119.633 52.8097 118.764 52.4291C117.896 52.0485 117.23 51.4776 116.766 50.7164C116.312 49.9652 116.085 49.0938 116.085 48.1023V47.5764ZM119.204 38.2167C119.204 39.9193 119.476 41.1613 120.021 41.9425C120.576 42.7238 121.243 43.1144 122.02 43.1144C122.858 43.1144 123.574 42.7188 124.17 41.9275C124.765 41.1363 125.063 39.9344 125.063 38.3218C125.063 36.6592 124.775 35.4323 124.2 34.641C123.635 33.8397 122.933 33.4391 122.095 33.4391C121.268 33.4391 120.576 33.8297 120.021 34.611C119.476 35.3922 119.204 36.5941 119.204 38.2167Z"
                    fill="white" />
            </svg>
        </div>

    )
};
export default Home_icon;
