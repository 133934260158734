import React, { useEffect, useState } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { Layout, Row, Col } from "antd";
import Navbar from "../../../components/navbar";
import Siderbar from "../../../components/siderbar";
import { over } from "lodash";

const { Header, Footer, Sider, Content } = Layout;
const Main = () => {
  let location = useLocation();
  return (
    // <Layout style={location.pathname == "/overview" ? styles.overflow : {}}>
    <Layout style={styles.overflow}>
      <Header style={headerStyle}>
        <Navbar />
      </Header>

      <Layout
        style={location.pathname == "/overview" ? styles.background_url : {}}
      >
        <Content>
          <Outlet />
        </Content>
      </Layout>

      <Footer style={footerStyle}>
        <span>&#169;</span>&nbsp;{new Date().getFullYear()}, Obotrons
        Corporation Limited
      </Footer>
    </Layout>
  );
};
const headerStyle = {
  // textAlign: "center",
  color: "#fff",
  height: "90px",
  // height: '100%',
  padding: "18px 38px 19px 24px",
  lineHeight: "unset",
  backgroundColor: "#122C4D",
  fontFamily: "Arial Narrow",
  boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.1)",
};
const contentStyle = {
  textAlign: "center",
  minHeight: 120,
  lineHeight: "120px",
  color: "#fff",
  // backgroundColor: '#108ee9',
};
const siderStyle = {
  textAlign: "center",
  lineHeight: "120px",
  color: "#fff",
  background: "none",
  // backgroundColor: '#3ba0e9',
};
const copyright = {
  fontFamily: "Arial Narrow",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  color: "rgba(255, 255, 255, 0.55)",
  backgroundColor: "#122C4D",
  lineHeight: "16px",
};
const footerStyle = {
  fontFamily: "Arial Narrow",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  color: "rgba(255, 255, 255, 0.55)",
  backgroundColor: "#122C4D",
  lineHeight: "16px",
};
const styles = {
  overflow: {
    overflow: "hidden",
  },
  background_url: {
    background: `url(${
      process.env.PUBLIC_URL + "/img/whizdom_bg.png"
    }) no-repeat center`,
    backgroundSize: "cover",
    width: "100%",
    // height: "926.2px",
    height: "100vh",
    // height:'822px',
    // height: "816px",
    // minHeight: "816px",
    fontFamily: "Arial Narrow",
  },
};
export default Main;
