import { Col, Row } from 'antd';
import _ from 'lodash';

export const Home_full_fun = (props) => {
    const styles = {
        sizetext: {
            fontFamily: 'Arial Narrow',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '20px',
            textAlign: 'center',
        },
        sizetextsmall: {
            fontFamily: 'Arial Narrow',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'center',
        },
        name: {
            fontFamily: 'Arial Narrow',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            color: '#FFFFFF',
            marginTop: '5px',
        },
        circles: {
            backgroundColor: props.data.color, width: '12px', height: '12px', borderRadius: '8px', marginRight: '5px'
        }
    }

    // console.log(props.data);
    // TODO: onclick 
    return (
        <>
            <Row>
                <svg width="50" height="50" viewBox="0 12 70 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {/* 1 star */}
                    {props.data.vip_type == 1 && (<path d="M34.6222 0.37936C34.5022 0.146386 34.262 0 33.9999 0C33.7379 0 33.4978 0.146371 33.3777 0.37936L31.4775 4.06736L27.5667 4.82557C27.3188 4.87362 27.1159 5.05163 27.036 5.29118C26.9561 5.53084 27.0114 5.79486 27.1807 5.98225L29.9388 9.03273L29.4119 13.2124C29.3795 13.469 29.4912 13.7225 29.7024 13.8716C29.9135 14.0208 30.1897 14.0416 30.4207 13.9254L34 12.1261L37.5793 13.9254C37.8102 14.0416 38.0864 14.0208 38.2976 13.8716C38.5088 13.7225 38.6205 13.469 38.5881 13.2124L38.0611 9.03273L40.8193 5.98225C40.9887 5.79486 41.0439 5.53083 40.964 5.29118C40.8841 5.05164 40.6812 4.87363 40.4333 4.82557L36.5225 4.06736L34.6222 0.37936Z" fill={props.data.color} />)}
                    {/* 1 star */}
                    {/* 2 star  vvip */}
                    {props.data.vip_type == 2 && (
                        <>
                            <path d="M25.6222 0.37936C25.5022 0.146386 25.262 0 24.9999 0C24.7379 0 24.4978 0.146371 24.3777 0.37936L22.4775 4.06736L18.5667 4.82557C18.3188 4.87362 18.1159 5.05163 18.036 5.29118C17.9561 5.53084 18.0114 5.79486 18.1807 5.98225L20.9388 9.03273L20.4119 13.2124C20.3795 13.469 20.4912 13.7225 20.7024 13.8716C20.9135 14.0208 21.1897 14.0416 21.4207 13.9254L25 12.1261L28.5793 13.9254C28.8102 14.0416 29.0864 14.0208 29.2976 13.8716C29.5088 13.7225 29.6205 13.469 29.5881 13.2124L29.0611 9.03273L31.8193 5.98225C31.9887 5.79486 32.0439 5.53083 31.964 5.29118C31.8841 5.05164 31.6812 4.87363 31.4333 4.82557L27.5225 4.06736L25.6222 0.37936Z" fill={props.data.color} />
                            <path d="M43.6222 0.37936C43.5022 0.146386 43.262 0 42.9999 0C42.7379 0 42.4978 0.146371 42.3777 0.37936L40.4775 4.06736L36.5667 4.82557C36.3188 4.87362 36.1159 5.05163 36.036 5.29118C35.9561 5.53084 36.0114 5.79486 36.1807 5.98225L38.9388 9.03273L38.4119 13.2124C38.3795 13.469 38.4912 13.7225 38.7024 13.8716C38.9135 14.0208 39.1897 14.0416 39.4207 13.9254L43 12.1261L46.5793 13.9254C46.8102 14.0416 47.0864 14.0208 47.2976 13.8716C47.5088 13.7225 47.6205 13.469 47.5881 13.2124L47.0611 9.03273L49.8193 5.98225C49.9887 5.79486 50.0439 5.53083 49.964 5.29118C49.8841 5.05164 49.6812 4.87363 49.4333 4.82557L45.5225 4.06736L43.6222 0.37936Z" fill={props.data.color} />
                        </>
                    )}
                    {/* 2 star */}
                    {/* house body */}
                    <path d="M60.1646 48.9082L37.6363 25.481C36.8414 24.505 35.6929 23.9721 34.5444 23.8835C33.3078 23.972 32.1592 24.5934 31.4525 25.481L8.83457 48.9082C7.24419 50.5941 6.44937 52.813 6.44937 55.0312V97.8052C6.44937 98.6036 6.09571 99.4027 5.47767 100.024C4.85906 100.645 4.0642 101 3.18062 101C1.41349 101 0.0887448 99.5802 0 97.8943V53.5236C0 50.684 1.23665 47.9329 3.6224 45.3595L27.9181 19.9799C31.1869 16.3414 36.7527 15.9867 40.3751 19.1815C40.6401 19.4476 40.905 19.7138 41.17 19.9799L65.3776 45.271C67.8515 47.8444 69 50.5954 69 53.435V91.7715C69 94.7003 68.2933 97.0076 66.7029 98.5158C65.1126 100.113 62.8155 101.001 60.6072 100.823H15.7268C14.932 100.823 14.1365 100.468 13.6067 99.847C12.4582 98.6049 12.4582 96.6522 13.6067 95.4101C14.1366 94.7887 14.932 94.4341 15.7268 94.4341H58.9279C61.3131 94.4341 62.5503 93.1919 62.5503 90.707V55.0327C62.5503 52.8145 61.7555 50.5957 60.1651 48.9097L60.1646 48.9082Z" fill={props.data.color} />
                    {/* house body */}
                </svg>
            </Row>
        </>
    )
}
export default Home_full_fun;
