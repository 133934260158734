const Back = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="28"
      viewBox="0 0 16 28"
      fill="none"
      style={{ cursor: "pointer" }}
    >
      <path
        d="M0.00204688 14.0872C0.0210883 14.6591 0.251227 15.2038 0.649133 15.6182L11.9449 27.2827C12.5283 27.8845 13.3911 28.1318 14.2082 27.932C15.0254 27.7321 15.6731 27.1151 15.9072 26.3134C16.141 25.5118 15.9259 24.6474 15.3425 24.0456L5.62012 14L15.3425 3.95436C15.9259 3.35258 16.141 2.48814 15.9072 1.68654C15.6731 0.884929 15.0254 0.268219 14.2082 0.0680061C13.3911 -0.131823 12.5283 0.115849 11.9449 0.717301L0.649133 12.3818C0.208877 12.84 -0.0245452 13.4558 0.00204688 14.0879V14.0872Z"
        fill="white"
      />
    </svg>
  );
};
export default Back;
