import React, { useEffect, useState,useRef  } from "react";
import { Col, Row, Spin, Typography, Divider } from "antd";
import ScrollContainer from 'react-indiana-drag-scroll'
import { Home_full_fun } from "../../img/project_icon/home";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getonOpen, editonOpennitify } from "../../slice/projectSlice";
import { editTitle, getActive, getRooms, editOnfilter } from "../../slice/projectSlice";
import _ from "lodash";
const { Title } = Typography;
const Detail_rooms = () => {
  const dispatch = useDispatch();
  const [isFetch, setIsfetch] = useState(false);
  const [Houses, setHouses] = useState([]);
  const onOpennitify = useSelector(getonOpen);
  const all_room = useSelector(getRooms);
  let location = useLocation();
  const scrollbar = useRef(null);

  var navigate = useNavigate();
  useEffect(() => {

    // if (!isFetch) {
    setHouses([...new Set(all_room)]);
    // }
    console.log("work");
    scrollbar.current.getElement().scrollTo(0,0);
    return () => {
      // cleanup
    };
  }, [all_room]);
  // this component will not fetch but  load state in redux state
  // vip_type 0 = normal 1 = vip 2 = vvip
  // status 1 = online  2 = Unstable 3 = offline
  // State 1 = Accepted 2 = Unaccepted

  return (
    <div style={styles.box} className="detail-rooms">
      <ScrollContainer  className="scroll-container-detailproject" ref={scrollbar}>
      {Houses.map((house, index) => {
        return (
          <div
            key={index + "house"}
            style={index == 0 ? { marginTop: "26px" } : { marginTop: "65px" }}
          >
            {!_.isEmpty(Object.keys(house)) &&
              Object.keys(house)[0] !== "0" && (
                <Row justify={"start"}>
                  <Title level={2} style={styles.title}>
                    Floor {Object.keys(house)[0]}
                  </Title>
                </Row>
              )}
              <Row gutter={onOpennitify ? [0, 40] : [0, 65]} style={styles.houses}  className={!onOpennitify ? "sizeCol" : ""}>
                {house[Object.keys(house)[0]].map((data, i) => {
                  return (
                    <Col
                      xs={onOpennitify ? 24 : 12} sm={onOpennitify ? 12 : 8} md={onOpennitify ? 8 : 6} lg={onOpennitify ? 12 : 8} xl={onOpennitify ? 12 : 6} xxl={onOpennitify ? 8 : 4}
                      style={{
                        cursor: "pointer",
                        padding: "10px 10px 10px 10px",
                      }}
                      key={"colhousenumber" + data.room_number + i}
                      onClick={() => {
                        dispatch(editOnfilter({ serviceRequst: 'Both Request', pir_battery: 'all', room: 'all', time: 'all', status: 'all', state: 'all',floor:'all'}));
                        // dispatch(editTitle(String('Floor ' + Object.keys(house)[0])))
                        //check floor 0
                        navigate(location.pathname + "/" + data.room_number);
                      }}
                    >
                      <Home_full_fun data={data} />
                    </Col>
                  );
                })}
              </Row>
              <Divider />
          </div>
        );
      })}

      </ScrollContainer>
    </div>
  );
};

const styles = {
  box: {
    padding: "0px 20px 10px 25px",
    // overflowX: "hidden",
    // height: "716px",
  },
  title: {
    fontFamily: "Arial Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    color: "#FFFFFF",
  },
  floor: {},
  houses: {
    // margin:"0 50px",
    padding: "46px 20px 36px 40px",
    // padding: '46px 70px 33px 40px',
    // borderBottom: "3px solid rgba(232, 232, 232, 0.49)",
  },
};
export default Detail_rooms;
