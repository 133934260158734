const Getprojects = (data, cb) => {
    let token = localStorage.getItem('token')
    token = JSON.parse(token)
    fetch(
      `${process.env.REACT_APP_API_URL}api/user`,
      {
        method: 'Get',
        headers: {
          Authorization: `Bearer ${token.token}`,
          'Content-Type': 'application/json'
        },
      }
    )
      .then(res => res.json())
      .then(json => cb(null, json))
      .catch(err => cb(err, null))
    // let data_mock = [
    //     { image: '', count_battery: 24, count_resident: 2, count_juristic: 30, count_home: 10, project: 'Whizdom', building: 'COEX Pinklao' },
    //     { image: '', count_battery: 5, count_resident: 28, count_juristic: 34, count_home: 400, project: 'Whizdom', building: 'Inspire Sukhumvit' },
    //     { image: '', count_battery: 2, count_resident: 2, count_juristic: 20, count_home: 40, project: 'Mulberry Grove', building: 'The Foreatias - Villa' }]
    // cb(null, data_mock)
}


export default Getprojects
