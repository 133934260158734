import {
  Battery_Noti,
  Communicate_Noti,
  Home_golden_Noti,
  Phone_Noti,
} from "../../img/home_icon/notification/index";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Row, Button, Badge, Typography } from "antd";
import { getonOpen, editonOpennitify } from "../../slice/projectSlice";
const { Paragraph, Text } = Typography;
const Cradproject = (props) => {
  const [ellipsis, setEllipsis] = useState(true);
  const onOpennitify = useSelector(getonOpen);
  return (
    <div className="cradprojectframe borderframe">
      <div
        className="size-frame"
        style={{
          backgroundImage: `url(${
            process.env.REACT_APP_API_URL + props.data.image.replace("/","")
          })`,
        }}
      >
        {onOpennitify && (
          <Row style={{ justifyContent: "end", padding: "10px" }}>
            <div className="gap">
              <Badge count={props.data.count_battery}>
                <Button type="primary" style={styles.ifream_noti}>
                  <Row align={"middle"} justify={"center"}>
                    <Battery_Noti width={20} height={20}/>
                  </Row>
                </Button>
              </Badge>
              <Badge count={props.data.count_resident}>
                <Button type="primary" style={styles.ifream_noti}>
                  <Row align={"middle"} justify={"center"}>
                    <Phone_Noti width={20} height={20}/>
                  </Row>
                </Button>
              </Badge>
              <Badge count={props.data.count_juristic}>
                <Button type="primary" style={styles.ifream_noti}>
                  <Row align={"middle"} justify={"center"}>
                    <Communicate_Noti width={20} height={20}/>
                  </Row>
                </Button>
              </Badge>
              <Badge count={props.data.count_home}>
                <Button type="primary" style={styles.ifream_noti}>
                  <Row align={"middle"} justify={"center"}>
                    <Home_golden_Noti width={20} height={20}/>
                  </Row>
                </Button>
              </Badge>
            </div>
          </Row>
        )}
        {!onOpennitify && (
          <Row style={{ justifyContent: "end", padding: "10px" }}>
            <div className="gap">
              <Badge count={props.data.count_battery}>
                <Button type="primary" style={styles.ifream_not_noti}>
                  <Row align={"middle"} justify={"center"}>
                    <Battery_Noti width={36} height={36}/>
                  </Row>
                </Button>
              </Badge>
              <Badge count={props.data.count_resident}>
                <Button type="primary" style={styles.ifream_not_noti}>
                  <Row align={"middle"} justify={"center"}>
                    <Phone_Noti width={36} height={36}/>
                  </Row>
                </Button>
              </Badge>
              <Badge count={props.data.count_juristic}>
                <Button type="primary" style={styles.ifream_not_noti}>
                  <Row align={"middle"} justify={"center"}>
                    <Communicate_Noti width={36} height={36}/>
                  </Row>
                </Button>
              </Badge>
              <Badge count={props.data.count_home}>
                <Button type="primary" style={styles.ifream_not_noti}>
                  <Row align={"middle"} justify={"center"}>
                    <Home_golden_Noti width={36} height={36} />
                  </Row>
                </Button>
              </Badge>
            </div>
          </Row>
        )}
        <div className="box_inframe ">
          <Paragraph
            ellipsis={
              ellipsis
                ? {
                    rows: 2,
                    expandable: false,
                  }
                : false
            }
          >
            <div className="box_title">
              {props.data.project} {props.data.building}
            </div>
          </Paragraph>
        </div>
      </div>
    </div>
  );
};
const styles = {
  ifream_noti: {
    background: "#122C4D",
    borderRadius: "10px",
    width: "40px",
    // margin: "10px 10px 10px 0",
    height: "40px",
    padding: "0",
    boxShadow: "none",
    // paddingTop: "10px",
    // paddingBottom: "6px",
    // alignItems: "center",
    // padding: "8.5px 16px",
  },
  ifream_not_noti: {
    background: "#122C4D",
    borderRadius: "10px",
    width: "55.57px",
    // margin: "10px 10px 10px 0",
    height: "55.57px",
    boxShadow: "none",
    // paddingTop: "10px",
    // paddingBottom: "6px",
    // alignItems: "center",
    // padding: "8.5px 16px",
  },
};
export default Cradproject;
