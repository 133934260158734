const Getbucketnoti = (data, cb) => {
    let token = localStorage.getItem('token')
    token = JSON.parse(token)
    fetch(`${process.env.REACT_APP_API_URL}api/notification?site=${data.site}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((json) => cb(null, json))
      .catch((err) => cb(err, null));
  
}

export default Getbucketnoti
