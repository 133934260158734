import React, { useEffect, useState } from "react";
import { Col, Row, Carousel } from "antd";
import Imagessilder from "../../api/auth/imagesilder";
const CardCarousel = () => {
  const [isLoad, setIsload] = useState(true);
  const [images, setImages] = useState([]);
  useEffect(() => {
    Imagessilder(null, (err, res) => {
      if (!err && res.statusCode === 200) {
        setImages(res.data);
        setIsload(false);
      }
    });
    return () => {
      // cleanup
    };
  }, []);
  return (
    <div>
      <Carousel autoplay>
        {images.map((image, index) => {
          const contentStyle = {
            // width: "100%",
            // minHeight: "1000px",
            // background: `url(${process.env.REACT_APP_API_URL + image.replace("/","")}) no-repeat center`,
            // backgroundSize: "cover",
            backgroundImage: `url(${
              process.env.REACT_APP_API_URL + image.replace("/", "")
            })`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            minHeight: "100vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          };
          return (
            <div key={index}>
              <div style={contentStyle}></div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

const contentStyle = {
  width: "100%",
  height: "100vh",
  background: `url(${
    process.env.PUBLIC_URL + "/img/whizdom_bg.png"
  }) no-repeat center`,
  backgroundSize: "cover",
};
export default CardCarousel;
