const SetstatusRoom = (data, cb) => {
    let token = localStorage.getItem('token')
    token = JSON.parse(token)
    fetch(
      `${process.env.REACT_APP_API_URL}api/room_detail?site=${data.project}&room=${data.room}&status=${data.status}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token.token}`,
          'Content-Type': 'application/json'
        },
      }
    )
      .then(res => res.json())
      .then(json => cb(null, json))
      .catch(err => cb(err, null))
}

export default SetstatusRoom
