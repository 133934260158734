import { Layout, Row, Col, Divider, Badge } from "antd";
import { unset } from "lodash";
import { useNavigate } from "react-router-dom";
const HeaderSiderbar = (prop) => {
  var navigate = useNavigate();
  return (
    <>
      {prop.type === 1 ? (
        <div className="siderbarframes">
          <div>
            <Row gutter={[8, 16]} style={{ alignItems: "center" }}>
              <Col span={12}>
                <Row style={{ alignItems: "center", padding: "4px 8px" }}>
                  <Col span={23}>
                    <div style={styles.box_title}>
                      Today
                      <Badge
                        count={prop.count}
                        style={{
                          cursor: "pointer",
                          margin: "0 0 0 8px",
                          fontSize: "14px",
                          border: "unset",
                          boxShadow: "unset",
                        }}
                      />
                    </div>
                  </Col>

                  <Col span={1}>
                    <Divider
                      type="vertical"
                      style={{
                        borderLeft: "2px solid rgba(255, 255, 255, 0.8)",
                        height: "52.5px",
                        textAlign: "center",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                span={12}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/notifications")}
              >
                <div style={{ fontSize: "14.5px" }}>
                  Manage Notifications{" "}
                  <span style={{ padding: "0 0 0 4px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="15"
                      viewBox="0 0 8 4"
                      fill="none"
                    >
                      <path
                        d="M7.99898 7.0405C7.98946 7.30599 7.87439 7.55893 7.67543 7.7513L2.02756 13.167C1.73586 13.4464 1.30447 13.5612 0.895884 13.4684C0.487308 13.3756 0.163425 13.0891 0.0463915 12.717C-0.0704823 12.3448 0.0370355 11.9434 0.328735 11.664L5.18994 6.99999L0.328735 2.33595C0.0370378 2.05656 -0.0704823 1.65521 0.0463915 1.28304C0.163434 0.91086 0.487296 0.62453 0.895884 0.531574C1.30446 0.438796 1.73584 0.553787 2.02756 0.833033L7.67543 6.24869C7.89556 6.46145 8.01227 6.74735 7.99898 7.04081V7.0405Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <div className="hr-title-noti"></div>
        </div>
      ) : null}
      {prop.type === 2 ? (
        <div className="siderbarframes">
          <div style={styles.box_title_noti}>Notification</div>
          <div className="hr-title-noti"></div>
        </div>
      ) : null}
    </>
  );
};
const styles = {
  box_title: {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    // padding: "0px",

    // width: "366px",
    // height: "58px",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
    // background: "rgba(18, 44, 77, 0.8)",

    /* Inside auto layout */

    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  box_title_noti: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",

    // width: "366px",
    height: "58px",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
    // background: "rgba(18, 44, 77, 0.8)",

    /* Inside auto layout */

    flex: "none",
    order: "0",
    flexGrow: "0",
  },
};
export default HeaderSiderbar;
