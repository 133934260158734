const Battery_Noti = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 42 23"
      fill="none"
    >
      <path
        d="M37.8926 20.47V17.2502H39.0812C40.4767 17.2502 41.611 16.1147 41.611 14.7205V8.27991C41.611 6.88509 40.4764 5.75014 39.0812 5.75014H37.8926V2.53007C37.8926 1.13524 36.7576 0.000296647 35.3628 0.000296647L2.85448 0C1.45905 0 0.324707 1.13495 0.324707 2.52977V20.4702C0.324707 21.8651 1.45935 23 2.85448 23H35.3621C36.7575 22.9997 37.8924 21.8647 37.8924 20.4699L37.8926 20.47ZM2.85463 20.7002C2.72825 20.7002 2.62443 20.597 2.62443 20.47L2.62473 2.52953C2.62473 2.40286 2.72797 2.29934 2.85492 2.29934H35.3625C35.4892 2.29934 35.5921 2.40258 35.5921 2.52953V20.47C35.5921 20.5967 35.4892 20.7002 35.3625 20.7002H2.85463ZM3.9475 3.79481V19.2049H10.4746V3.79481H3.9475Z"
        fill="white"
      />
    </svg>
  );
};
const Communicate_Noti = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 41 41"
      fill="none"
    >
      <path
        d="M33.7694 14.5583C33.5905 16.7064 32.0686 18.7652 29.9206 18.7652H29.4729C30.1441 17.3329 30.5021 15.7221 30.5021 14.0214C30.5021 12.813 30.3233 11.6495 29.9652 10.5307H31.5317C29.8756 5.51803 25.4453 1.84849 20.2539 1.80374C15.0624 1.84838 10.6319 5.47368 8.97601 10.5307H10.7214C10.3633 11.6048 10.1844 12.813 10.1844 14.0214C10.1844 15.6772 10.5425 17.3333 11.2137 18.7652H10.5872C8.43908 18.7652 6.87263 16.6618 6.73837 14.5583C6.11183 6.81636 12.646 0.10295 20.2539 0.058197C27.8619 0.10315 34.3959 6.81612 33.7694 14.5583ZM21.6859 17.7806C22.2678 17.7806 22.7601 18.049 23.0286 18.4967H27.6831C28.3993 17.199 28.7573 15.6771 28.7573 14.0213C28.7573 9.41174 25.1323 4.98115 20.3436 4.98115C14.5706 4.98115 10.8559 11.0676 12.1985 16.3933C13.1385 20.1078 16.4054 23.1063 20.3436 23.1063C22.7602 23.1063 24.9086 21.9874 26.4301 20.2421H23.0289C22.7605 20.6897 22.2682 20.9582 21.6862 20.9582C20.7913 20.9582 20.0751 20.2421 20.0751 19.3471C20.1198 18.4967 20.8359 17.7806 21.6859 17.7806ZM39.095 32.2359C38.3342 28.2083 34.7985 25.2545 30.6366 25.2545H27.5935L22.6705 36.9351L21.6859 27.9844L22.5809 25.2545H18.374L19.269 27.9844L18.2844 36.9351L13.3614 25.2545H10.3183C6.20101 25.2545 2.66533 28.1633 1.85992 32.2359L0.383301 40.2915H40.6614L39.095 32.2359Z"
        fill="white"
      />
    </svg>
  );
};
const Home_golden_Noti = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 35 41"
      fill="none"
    >
      <path
        d="M30.4506 15.3293L19.2849 4.10846C18.891 3.64097 18.3217 3.38572 17.7525 3.34331C17.1396 3.38571 16.5704 3.68333 16.2201 4.10846L5.01001 15.3293C4.22177 16.1368 3.82784 17.1996 3.82784 18.262V38.7495C3.82784 39.1319 3.65255 39.5146 3.34624 39.8122C3.03963 40.1098 2.64568 40.2797 2.20775 40.2797C1.33191 40.2797 0.675332 39.5996 0.631348 38.7921V17.5399C0.631348 16.1799 1.24426 14.8622 2.42671 13.6296L14.4684 1.47361C16.0885 -0.269142 18.847 -0.43899 20.6424 1.0912C20.7737 1.21867 20.905 1.34615 21.0364 1.47361L33.0343 13.5872C34.2605 14.8198 34.8297 16.1375 34.8297 17.4975V35.8595C34.8297 37.2623 34.4794 38.3674 33.6912 39.0898C32.903 39.8549 31.7645 40.28 30.67 40.1949H8.42601C8.03206 40.1949 7.63777 40.0248 7.3752 39.7274C6.80596 39.1325 6.80596 38.1972 7.3752 37.6023C7.63784 37.3047 8.03209 37.1348 8.42601 37.1348H29.8377C31.0198 37.1348 31.633 36.5398 31.633 35.3496V18.2628C31.633 17.2003 31.2391 16.1376 30.4509 15.3301L30.4506 15.3293Z"
        fill="#FAB52F"
      />
    </svg>
  );
};
const Phone_Noti = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 33 44"
      fill="none"
    >
      <path
        d="M28.1225 0.875H4.87662C2.40898 0.875 0.40625 2.85523 0.40625 5.27552V38.7195C0.40625 41.1486 2.40898 43.12 4.87662 43.12H28.1225C30.5902 43.12 32.5929 41.1486 32.5929 38.7195V5.27552C32.5929 2.85523 30.5902 0.875 28.1225 0.875ZM13.8174 3.51783H19.1818C19.6757 3.51783 20.0759 3.91171 20.0759 4.39793C20.0759 4.88415 19.6757 5.27804 19.1818 5.27804H13.8174C13.3234 5.27804 12.9233 4.88415 12.9233 4.39793C12.9233 3.9122 13.3239 3.51783 13.8174 3.51783ZM16.4996 40.3213C15.5132 40.3213 14.7114 39.532 14.7114 38.5611C14.7114 37.5901 15.5132 36.8008 16.4996 36.8008C17.486 36.8008 18.2877 37.5901 18.2877 38.5611C18.2877 39.5315 17.486 40.3213 16.4996 40.3213ZM29.0166 33.441C29.0166 33.9272 28.6165 34.3211 28.1225 34.3211H4.87662C4.38268 34.3211 3.98255 33.9272 3.98255 33.441V8.79807C3.98255 8.31185 4.38268 7.91797 4.87662 7.91797H28.1225C28.6165 7.91797 29.0166 8.31185 29.0166 8.79807V33.441Z"
        fill="white"
      />
    </svg>
  );
};
export { Battery_Noti, Communicate_Noti, Home_golden_Noti, Phone_Noti };
