import React, { useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { Col, Row, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editcount_unread } from ".././slice/projectSlice";
import moment from "moment";
import {
  Battery_Noti,
  Phone_Noti,
  Communicate_Noti,
  Home_golden_Noti,
} from "../img/home_icon/notification/index";
import Getbucketnoti from "../api/siderbar";
import IamRead from "../api/notification/iamread";
const Siderbar = () => {
  // TODO: socket
  const [isLoad, setIsload] = useState(true);
  const [bucket, setBucket] = useState([]);
  var navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    Getbucketnoti({ site: "all" }, (err, res) => {
      if (!err && res.statusCode === 200) {
        setBucket(res.data.notify.reverse());
        dispatch(editcount_unread(res.data.total));
        setIsload(false);
      }
    });
  }, []);
  return (
    <div className="siderbarframe" style={styles.title}>
      {/* <div style={styles.box_title}>Notification</div>
      <div className="hr-title-noti"></div> */}
      <div style={styles.box_noti}>
        {/* <ScrollContainer className="scroll-container"> */}
        {bucket.map((message, index) => {
          let todaydate = moment(Date()).format("DD-MM-YYYY");
          let msgdate = "";
          if (message.time == todaydate) {
            msgdate = message.createAt;
          } else {
            msgdate = message.time;
          }
          let icon_display;
          if (message.request.resident)
            icon_display = <Phone_Noti width={20} height={20} />;
          if (message.request.juristic)
            icon_display = <Communicate_Noti width={20} height={20} />;
          if (message.request.pir_battery)
            icon_display = <Battery_Noti width={20} height={20} />;
          if (message.request.home)
            icon_display = <Home_golden_Noti width={20} height={20} />;
          if (message.readed == "false") {
            return (
              <>
                <Row
                  gutter={[0, 0]}
                  style={styles.box_detail}
                  key={index + "noti"}
                  onClick={() => {
                    // dispatch(editTitle(String('Floor ' + message.floor)))
                    IamRead({ site: "all", id: message.id }, (err, res) => {
                      if (!err && res.statusCode === 200) {
                        setBucket(res.data.notify.reverse());
                        dispatch(editcount_unread(res.data.total));
                      }
                    });
                    navigate(
                      "/overview/" +
                        message.project.replaceAll(" ", "").toLowerCase() +
                        "-" +
                        message.building.replaceAll(" ", "").toLowerCase() +
                        "/" +
                        message.room_nuber
                    );
                  }}
                >
                  <Col span={4} style={styles.box_detail_text}>
                    {icon_display}
                  </Col>
                  <Col span={11} style={styles.nameroom_text}>
                    {message.project} {message.building}
                  </Col>
                  <Col span={4} style={styles.box_detail_text}>
                    {message.room_nuber}
                  </Col>
                  <Col span={5} style={styles.box_detail_text}>
                    {msgdate}
                  </Col>
                </Row>
                <Divider />
              </>
            );
          } else {
            return (
              <>
                <Row
                  gutter={[0, 0]}
                  style={styles.box_detail_hightlight}
                  key={index + "noti"}
                  onClick={() => {
                    navigate(
                      "/overview/" +
                        message.project.replaceAll(" ", "").toLowerCase() +
                        "-" +
                        message.building.replaceAll(" ", "").toLowerCase() +
                        "/" +
                        message.room_nuber
                    );
                  }}
                >
                  <Col span={4} style={styles.box_detail_hightlight_text}>
                    {icon_display}
                  </Col>
                  <Col span={11} style={styles.nameroom_hightlight_text}>
                    {message.project} {message.building}
                  </Col>
                  <Col span={4} style={styles.box_detail_hightlight_text}>
                    {message.room_nuber}
                  </Col>
                  <Col span={5} style={styles.box_detail_hightlight_text}>
                    {msgdate}
                  </Col>
                </Row>
                <Divider />
              </>
            );
          }
        })}
        {/* </ScrollContainer> */}
      </div>
    </div>
  );
};
const styles = {
  title: {
    fontFamily: "Arial Narrow",
    color: "#FFFFFF",
  },
  box_title: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",

    // width: "366px",
    height: "58px",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
    // background: "rgba(18, 44, 77, 0.8)",

    /* Inside auto layout */

    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  box_noti: {
    // width: "366px",
    // height: "933px",
    // background: "rgba(18, 44, 77, 0.8)",
  },
  box_detail_text: {
    fontFamily: "Arial Narrow",
    fontWeight: "700",
    fontSize: "16px",
  },
  box_detail: {
    background: "rgba(255, 255, 255, 0.1)",
    width: "100%",
    height: "58px",
    alignItems: "center",
    cursor: "pointer",
  },
  box_detail_hightlight_text: {
    fontFamily: "Arial Narrow",
    fontWeight: "400",
    fontSize: "16px",
  },
  box_detail_hightlight: {
    background: "rgba(0, 0, 0, 0.25)",
    width: "100%",
    height: "58px",
    alignItems: "center",
    cursor: "pointer",
  },
  nameroom_hightlight_text: {
    fontFamily: "Arial Narrow",
    fontWeight: "400",
    fontSize: "16px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "capitalize",
    textAlign: "start",
  },
  nameroom_text: {
    fontFamily: "Arial Narrow",
    fontWeight: "700",
    fontSize: "16px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "capitalize",
    textAlign: "start",
  },
};
export default Siderbar;
