const Getpirbattery = (site, cb) => {
    let token = localStorage.getItem('token')
    token = JSON.parse(token)
    fetch(
        `${process.env.REACT_APP_API_URL}api/pir_battery?site=${site}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token.token}`,
            'Content-Type': 'application/json'
          },
        }
      )
        .then(res => res.json())
        .then(json => cb(null, json))
        .catch(err => cb(err, null))
}

export default Getpirbattery
