import Cardproject from "./overview/cardproject";
import Room_device from "./project/room_device";
import Room_service from "./project/room_service";
import Pir_battery from "./project/pir_battery";
import Table_project from "./project/table";
import Detail_project from "./project/detail";
import Filterbar_project from "./project/filterbar";
import FooterFilterbar_project from "./project/footer_filterbar";
import Room_detail from "./room/detail";
import Room_history from "./room/history";
import Cardlogin from "./auth/cardlogin";
import CardRegister from "./auth/cardregister";
import CardForgot from "./auth/cardforgot";
import CardNewPassword from "./auth/card_newpassword";
import CardChangePassword from "./auth/card_changepassword";
import CardVerify from "./auth/cardverify";
import CardCarousel from "./auth/carousel";
import Show_room from "./notifications/show_room";
import Notifications_detail from "./notifications/detail";
import Notifications_history from "./notifications/history";

export default {
  Cardproject,
  Room_device,
  Room_service,
  Pir_battery,
  Table_project,
  Detail_project,
  Filterbar_project,
  FooterFilterbar_project,
  Cardlogin,
  CardRegister,
  CardForgot,
  CardNewPassword,
  CardChangePassword,
  CardVerify,
  CardCarousel,
  Room_detail,
  Room_history,
  Show_room,
  Notifications_detail,
  Notifications_history
};
