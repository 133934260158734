import React, { useEffect, useState } from "react";
import Component from "../../components";

const Forgotpassword = () => {
  return (
    <div>
      <Component.CardCarousel />
      <Component.CardForgot />
    </div>
  );
};

export default Forgotpassword;
