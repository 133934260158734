const Getdetail = (request, cb) => {
    let token = localStorage.getItem('token')
    token = JSON.parse(token)
    fetch(
      `${process.env.REACT_APP_API_URL}api/request_detail?site=${request.site}&serviceRequst=${request.filter.serviceRequst}&pir_battery=${request.filter.pir_battery}&room=${request.filter.room}&time=${request.filter.time}&status=${request.filter.status}&state=${request.filter.state}&floor=${request.filter.floor}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token.token}`,
          'Content-Type': 'application/json'
        },
      }
    )
      .then(res => res.json())
      .then(json => cb(null, json))
      .catch(err => cb(err, null))
}

export default Getdetail
