import React, { useEffect, useState } from "react";
import Component from "../../components";

const Login = ({setToken}) => {
  return (
    <div>
      <Component.CardCarousel />
      <Component.Cardlogin setToken={setToken}/>
    </div>
  );
};

export default Login;
