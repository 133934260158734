function Imagessilder(credentials, cb) {
    fetch(`${process.env.REACT_APP_API_URL}api/images/login`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((json) => cb(null, json))
      .catch((err) => cb(err, null));
  }
  export default Imagessilder;
  