const Battery_htr = (props) => {
  return (
    <svg
      width="24"
      height="15"
      viewBox="0 0 24 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6895 13.031V11.0457H22.37C23.1688 11.0457 23.8182 10.3455 23.8182 9.48582V5.51458C23.8182 4.65453 23.1687 3.95472 22.37 3.95472H21.6895V1.96922C21.6895 1.10917 21.0397 0.409363 20.2412 0.409363L1.63017 0.40918C0.831294 0.40918 0.181885 1.10899 0.181885 1.96904V13.0311C0.181885 13.8912 0.831468 14.591 1.63017 14.591H20.2407C21.0396 14.5908 21.6894 13.891 21.6894 13.031L21.6895 13.031ZM1.63026 13.1729C1.55791 13.1729 1.49848 13.1093 1.49848 13.031L1.49865 1.96889C1.49865 1.89079 1.55775 1.82695 1.63043 1.82695H20.241C20.3135 1.82695 20.3724 1.89061 20.3724 1.96889V13.031C20.3724 13.1091 20.3135 13.1729 20.241 13.1729H1.63026ZM2.25593 2.74906V12.2509H5.9927V2.74906H2.25593Z"
        fill="white"
      />
    </svg>
  );
};
const HomeNomal_htr = (props) => {
  return (
    <svg
      width="26"
      height="29"
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6707 11.0924L14.1818 3.18195C13.8823 2.85238 13.4495 2.67244 13.0167 2.64254C12.5508 2.67243 12.118 2.88225 11.8517 3.18195L3.32897 11.0924C2.72969 11.6617 2.4302 12.4109 2.4302 13.1599V27.6031C2.4302 27.8727 2.29693 28.1425 2.06405 28.3523C1.83095 28.5621 1.53144 28.6819 1.19849 28.6819C0.53262 28.6819 0.0334401 28.2025 0 27.6332V12.6509C0 11.692 0.465982 10.7631 1.36496 9.89418L10.5199 1.32444C11.7516 0.0958392 13.8489 -0.0238998 15.2138 1.05485C15.3137 1.14471 15.4135 1.23458 15.5133 1.32444L24.635 9.86428C25.5672 10.7332 26 11.6621 26 12.621V25.5658C26 26.5547 25.7337 27.3338 25.1344 27.843C24.5352 28.3825 23.6696 28.6821 22.8375 28.6221H5.92606C5.62654 28.6221 5.32678 28.5022 5.12716 28.2926C4.69438 27.8731 4.69438 27.2138 5.12716 26.7944C5.32683 26.5846 5.62657 26.4648 5.92606 26.4648H22.2047C23.1035 26.4648 23.5697 26.0454 23.5697 25.2063V13.1604C23.5697 12.4114 23.2702 11.6623 22.6709 11.0929L22.6707 11.0924Z"
        fill="white"
      />
    </svg>
  );
};
const Juristic_htr = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7738 8.95627C19.6688 10.1551 18.7758 11.3042 17.5152 11.3042H17.2525C17.6464 10.5048 17.8565 9.60582 17.8565 8.65664C17.8565 7.98221 17.7515 7.33284 17.5414 6.70841H18.4607C17.4888 3.91083 14.889 1.86283 11.8425 1.83785C8.79601 1.86276 6.19605 3.88607 5.22434 6.70841H6.24858C6.03846 7.30791 5.93348 7.98221 5.93348 8.65664C5.93348 9.58075 6.14361 10.505 6.53748 11.3042H6.16981C4.90925 11.3042 3.99001 10.1302 3.91122 8.95627C3.54356 4.63543 7.37799 0.888625 11.8425 0.863647C16.3071 0.888736 20.1415 4.6353 19.7738 8.95627ZM12.6829 10.7546C13.0244 10.7546 13.3132 10.9045 13.4708 11.1543H16.2022C16.6225 10.4301 16.8326 9.5807 16.8326 8.65659C16.8326 6.08393 14.7054 3.61118 11.8952 3.61118C8.50742 3.61118 6.32753 7.00809 7.11541 9.98038C7.66701 12.0535 9.58414 13.727 11.8952 13.727C13.3133 13.727 14.574 13.1025 15.4669 12.1284H13.471C13.3135 12.3783 13.0246 12.5281 12.6831 12.5281C12.1579 12.5281 11.7376 12.1284 11.7376 11.6289C11.7638 11.1543 12.1841 10.7546 12.6829 10.7546ZM22.8991 18.8223C22.4526 16.5744 20.3778 14.9259 17.9354 14.9259H16.1497L13.2607 21.4449L12.6829 16.4495L13.2081 14.9259H10.7394L11.2646 16.4495L10.6868 21.4449L7.79781 14.9259H6.01205C3.59589 14.9259 1.52104 16.5493 1.04841 18.8223L0.181885 23.3182H23.8182L22.8991 18.8223Z"
        fill="white"
      />
    </svg>
  );
};
const Residents_htr = (props) => {
  return (
    <svg
      width="22"
      height="27"
      viewBox="0 0 22 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6817 0.5H3.31807C1.68716 0.5 0.363525 1.71875 0.363525 3.20833V23.7917C0.363525 25.2867 1.68716 26.5 3.31807 26.5H18.6817C20.3126 26.5 21.6363 25.2867 21.6363 23.7917V3.20833C21.6363 1.71875 20.3126 0.5 18.6817 0.5ZM9.22716 2.12655H12.7726C13.0991 2.12655 13.3635 2.36897 13.3635 2.66821C13.3635 2.96746 13.0991 3.20988 12.7726 3.20988H9.22716C8.90071 3.20988 8.63625 2.96746 8.63625 2.66821C8.63625 2.36927 8.90104 2.12655 9.22716 2.12655ZM10.9999 24.7775C10.348 24.7775 9.81807 24.2918 9.81807 23.6942C9.81807 23.0966 10.348 22.6108 10.9999 22.6108C11.6518 22.6108 12.1817 23.0966 12.1817 23.6942C12.1817 24.2915 11.6518 24.7775 10.9999 24.7775ZM19.2726 20.543C19.2726 20.8422 19.0082 21.0847 18.6817 21.0847H3.31807C2.99162 21.0847 2.72716 20.8422 2.72716 20.543V5.37632C2.72716 5.07707 2.99162 4.83465 3.31807 4.83465H18.6817C19.0082 4.83465 19.2726 5.07707 19.2726 5.37632V20.543Z"
        fill="white"
      />
    </svg>
  );
};
const Room_icon_htr = (props) => {
  return (
    <div>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.62219 0.37936C7.50219 0.146386 7.26195 0 6.99993 0C6.73792 0 6.4978 0.146371 6.37768 0.37936L4.47749 4.06736L0.566719 4.82557C0.318752 4.87362 0.115912 5.05163 0.0359921 5.29118C-0.0439303 5.53084 0.0113831 5.79486 0.180717 5.98225L2.93884 9.03273L2.41186 13.2124C2.37952 13.469 2.4912 13.7225 2.70237 13.8716C2.91354 14.0208 3.18974 14.0416 3.42071 13.9254L6.99999 12.1261L10.5793 13.9254C10.8102 14.0416 11.0864 14.0208 11.2976 13.8716C11.5088 13.7225 11.6205 13.469 11.5881 13.2124L11.0611 9.03273L13.8193 5.98225C13.9887 5.79486 14.0439 5.53083 13.964 5.29118C13.8841 5.05164 13.6812 4.87363 13.4333 4.82557L9.52249 4.06736L7.62219 0.37936Z"
          fill="#FAB52F"
        />
      </svg>
      <svg
        width="41"
        height="49"
        viewBox="0 0 41 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35.096 18.6131L21.9545 4.94725C21.4908 4.3779 20.8208 4.06704 20.1509 4.01539C19.4295 4.06702 18.7595 4.4295 18.3473 4.94725L5.1535 18.6131C4.22578 19.5965 3.76213 20.8909 3.76213 22.1848V47.1364C3.76213 47.6021 3.55583 48.0682 3.19531 48.4306C2.83445 48.7931 2.37078 49 1.85536 49C0.824537 49 0.0517678 48.1718 0 47.1884V21.3054C0 19.649 0.721376 18.0442 2.11307 16.5431L16.2856 1.73829C18.1924 -0.384207 21.4391 -0.591064 23.5522 1.27255C23.7067 1.42779 23.8613 1.58304 24.0158 1.73829L38.1369 16.4914C39.5801 17.9925 40.25 19.5973 40.25 21.2538V43.6167C40.25 45.3252 39.8377 46.6711 38.91 47.5509C37.9823 48.4827 36.6424 49.0004 35.3542 48.8968H9.17399C8.71032 48.8968 8.24627 48.6896 7.93723 48.3274C7.26726 47.6028 7.26726 46.4638 7.93723 45.7392C8.24635 45.3768 8.71036 45.1699 9.17399 45.1699H34.3746C35.766 45.1699 36.4877 44.4453 36.4877 42.9957V22.1857C36.4877 20.8918 36.024 19.5975 35.0963 18.614L35.096 18.6131Z"
          fill="#FAB52F"
        />
      </svg>
    </div>
  );
};
export { Battery_htr, HomeNomal_htr, Juristic_htr, Residents_htr, Room_icon_htr };
