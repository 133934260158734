const Battery = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 -4 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3788 8.92451V7.52075H16.8971C17.5055 7.52075 18 7.02567 18 6.41782V3.60988C18 3.00176 17.5053 2.50695 16.8971 2.50695H16.3788V1.10306C16.3788 0.494944 15.884 0.000129332 15.2759 0.000129332L1.10293 0C0.49455 0 0 0.494815 0 1.10293V8.92461C0 9.53272 0.494682 10.0275 1.10293 10.0275H15.2756C15.884 10.0274 16.3788 9.53259 16.3788 8.92448L16.3788 8.92451ZM1.103 9.02487C1.0479 9.02487 1.00263 8.97986 1.00263 8.92451L1.00276 1.10283C1.00276 1.0476 1.04777 1.00247 1.10312 1.00247H15.2758C15.331 1.00247 15.3759 1.04747 15.3759 1.10283V8.92451C15.3759 8.97973 15.331 9.02487 15.2758 9.02487H1.103ZM1.57947 1.65446V8.37295H4.42516V1.65446H1.57947Z"
        fill={props.fill}
      />
    </svg>
  );
};

const BothRequest = (props) => {
  return (
    <svg
      width="25"
      height="17"
      viewBox="0 0 25 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1892 5.91407C22.1163 6.7902 21.4956 7.62991 20.6194 7.62991H20.4369C20.7106 7.04574 20.8567 6.38875 20.8567 5.6951C20.8567 5.20222 20.7837 4.72767 20.6377 4.27134H21.2766C20.6011 2.22687 18.7941 0.730197 16.6767 0.711944C14.5593 0.730151 12.7523 2.20878 12.0769 4.27134H12.7888C12.6428 4.70945 12.5698 5.20222 12.5698 5.6951C12.5698 6.37044 12.7158 7.04587 12.9896 7.62991H12.7341C11.8579 7.62991 11.219 6.77201 11.1643 5.91407C10.9087 2.75641 13.5738 0.0182533 16.6768 0C19.7798 0.0183348 22.4448 2.75631 22.1892 5.91407ZM17.2608 7.22831C17.4982 7.22831 17.6989 7.33781 17.8085 7.52039H19.7069C19.999 6.99111 20.145 6.3704 20.145 5.69506C20.145 3.81497 18.6665 2.00789 16.7134 2.00789C14.3588 2.00789 12.8437 4.49034 13.3913 6.66248C13.7747 8.17751 15.1071 9.40048 16.7134 9.40048C17.699 9.40048 18.5752 8.94415 19.1958 8.23227H17.8086C17.6991 8.41485 17.4983 8.52435 17.261 8.52435C16.8959 8.52435 16.6038 8.23227 16.6038 7.86723C16.6221 7.52039 16.9141 7.22831 17.2608 7.22831ZM24.3614 13.1241C24.0511 11.4814 22.609 10.2766 20.9115 10.2766H19.6703L17.6624 15.0408L17.2608 11.3901L17.6259 10.2766H15.91L16.2751 11.3901L15.8735 15.0408L13.8656 10.2766H12.6244C10.9451 10.2766 9.50302 11.463 9.17453 13.1241L8.57227 16.4097H25.0002L24.3614 13.1241Z"
        fill={props.fill}
      />
      <path
        d="M10.0656 0.779297H1.62349C0.727324 0.779297 0 1.50987 0 2.40279V14.7413C0 15.6375 0.727324 16.3648 1.62349 16.3648H10.0656C10.9618 16.3648 11.6891 15.6375 11.6891 14.7413V2.40279C11.6891 1.50987 10.9618 0.779297 10.0656 0.779297ZM4.87047 1.75432H6.81866C6.99804 1.75432 7.14336 1.89964 7.14336 2.07902C7.14336 2.2584 6.99804 2.40372 6.81866 2.40372H4.87047C4.69109 2.40372 4.54577 2.2584 4.54577 2.07902C4.54577 1.89982 4.69127 1.75432 4.87047 1.75432ZM5.84457 15.3323C5.48635 15.3323 5.19517 15.0411 5.19517 14.6829C5.19517 14.3246 5.48635 14.0335 5.84457 14.0335C6.20279 14.0335 6.49396 14.3246 6.49396 14.6829C6.49396 15.0409 6.20279 15.3323 5.84457 15.3323ZM10.3903 12.7939C10.3903 12.9733 10.245 13.1186 10.0656 13.1186H1.62349C1.44411 13.1186 1.29879 12.9733 1.29879 12.7939V3.70237C1.29879 3.52299 1.44411 3.37767 1.62349 3.37767H10.0656C10.245 3.37767 10.3903 3.52299 10.3903 3.70237V12.7939Z"
        fill={props.fill}
      />
    </svg>
  );
};
const HomeNomal = (props) => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4634 5.69789L6.54544 1.51446C6.4072 1.34017 6.20745 1.24501 6.00772 1.2292C5.79265 1.24501 5.59291 1.35597 5.47001 1.51446L1.53645 5.69789C1.25986 5.99894 1.12163 6.39518 1.12163 6.79128V14.4295C1.12163 14.5721 1.06012 14.7148 0.952639 14.8257C0.845054 14.9367 0.706817 15 0.553151 15C0.245825 15 0.0154339 14.7465 0 14.4454V6.52206C0 6.01499 0.215069 5.52373 0.629983 5.0642L4.85533 0.532129C5.42381 -0.117614 6.39178 -0.180938 7.02176 0.389556C7.06784 0.437079 7.11392 0.484606 7.16 0.532129L11.37 5.04839C11.8003 5.50792 12 5.99918 12 6.50625V13.3521C12 13.875 11.8771 14.2871 11.6005 14.5564C11.3239 14.8417 10.9244 15.0001 10.5404 14.9684H2.7351C2.59687 14.9684 2.45851 14.905 2.36638 14.7941C2.16664 14.5723 2.16664 14.2236 2.36638 14.0018C2.45854 13.8908 2.59688 13.8275 2.7351 13.8275H10.2483C10.6632 13.8275 10.8783 13.6057 10.8783 13.162V6.79155C10.8783 6.39545 10.7401 5.99924 10.4635 5.69816L10.4634 5.69789Z"
        fill={props.fill}
      />
    </svg>
  );
};

const HomeVIP = (props) => {
  return (
    <svg
      width="12"
      height="19"
      viewBox="0 0 12 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4634 10.318L6.54544 6.4135C6.4072 6.25083 6.20745 6.16201 6.00772 6.14725C5.79265 6.16201 5.59291 6.26557 5.47001 6.4135L1.53645 10.318C1.25986 10.599 1.12163 10.9688 1.12163 11.3385V18.4675C1.12163 18.6006 1.06012 18.7338 0.952639 18.8373C0.845054 18.9409 0.706817 19 0.553151 19C0.245825 19 0.0154339 18.7634 0 18.4824V11.0873C0 10.614 0.215069 10.1555 0.629983 9.72659L4.85533 5.49665C5.42381 4.89023 6.39178 4.83112 7.02176 5.36359C7.06784 5.40794 7.11392 5.4523 7.16 5.49665L11.37 9.71183C11.8003 10.1407 12 10.5992 12 11.0725V17.4619C12 17.95 11.8771 18.3346 11.6005 18.586C11.3239 18.8522 10.9244 19.0001 10.5404 18.9705H2.7351C2.59687 18.9705 2.45851 18.9113 2.36638 18.8078C2.16664 18.6008 2.16664 18.2754 2.36638 18.0684C2.45854 17.9648 2.59688 17.9057 2.7351 17.9057H10.2483C10.6632 17.9057 10.8783 17.6987 10.8783 17.2845V11.3388C10.8783 10.9691 10.7401 10.5993 10.4635 10.3183L10.4634 10.318Z"
        fill={props.fill}
      />
      <path
        d="M6.17777 0.108389C6.14348 0.0418245 6.07484 0 5.99998 0C5.92512 0 5.85651 0.0418203 5.82219 0.108389L5.27928 1.1621L4.16192 1.37873C4.09107 1.39246 4.03312 1.44332 4.01028 1.51177C3.98745 1.58024 4.00325 1.65568 4.05163 1.70921L4.83967 2.58078L4.6891 3.77498C4.67986 3.84828 4.71177 3.9207 4.77211 3.96333C4.83244 4.00595 4.91136 4.01188 4.97735 3.9787L6 3.4646L7.02265 3.9787C7.08864 4.01188 7.16755 4.00595 7.22789 3.96333C7.28822 3.9207 7.32013 3.84828 7.31089 3.77498L7.16033 2.58078L7.94836 1.70921C7.99677 1.65567 8.01255 1.58024 7.98971 1.51177C7.96688 1.44333 7.90892 1.39247 7.83807 1.37873L6.72071 1.1621L6.17777 0.108389Z"
        fill={props.fill}
      />
    </svg>
  );
};

const HomeVVIP = (props) => {
  return (
    <svg
      width="12"
      height="19"
      viewBox="0 0 12 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4634 10.318L6.54544 6.4135C6.4072 6.25083 6.20745 6.16201 6.00772 6.14725C5.79265 6.16201 5.59291 6.26557 5.47001 6.4135L1.53645 10.318C1.25986 10.599 1.12163 10.9688 1.12163 11.3385V18.4675C1.12163 18.6006 1.06012 18.7338 0.952639 18.8373C0.845054 18.9409 0.706817 19 0.553151 19C0.245825 19 0.0154339 18.7634 0 18.4824V11.0873C0 10.614 0.215069 10.1555 0.629983 9.72659L4.85533 5.49665C5.42381 4.89023 6.39178 4.83112 7.02176 5.36359C7.06784 5.40794 7.11392 5.4523 7.16 5.49665L11.37 9.71183C11.8003 10.1407 12 10.5992 12 11.0725V17.4619C12 17.95 11.8771 18.3346 11.6005 18.586C11.3239 18.8522 10.9244 19.0001 10.5404 18.9705H2.7351C2.59687 18.9705 2.45851 18.9113 2.36638 18.8078C2.16664 18.6008 2.16664 18.2754 2.36638 18.0684C2.45854 17.9648 2.59688 17.9057 2.7351 17.9057H10.2483C10.6632 17.9057 10.8783 17.6987 10.8783 17.2845V11.3388C10.8783 10.9691 10.7401 10.5993 10.4635 10.3183L10.4634 10.318Z"
        fill={props.fill}
      />
      <path
        d="M3.17777 0.108389C3.14348 0.0418245 3.07484 0 2.99998 0C2.92512 0 2.85651 0.0418203 2.82219 0.108389L2.27928 1.1621L1.16192 1.37873C1.09107 1.39246 1.03312 1.44332 1.01028 1.51177C0.987448 1.58024 1.00325 1.65568 1.05163 1.70921L1.83967 2.58078L1.6891 3.77498C1.67986 3.84828 1.71177 3.9207 1.77211 3.96333C1.83244 4.00595 1.91136 4.01188 1.97735 3.9787L3 3.4646L4.02265 3.9787C4.08864 4.01188 4.16755 4.00595 4.22789 3.96333C4.28822 3.9207 4.32013 3.84828 4.31089 3.77498L4.16033 2.58078L4.94836 1.70921C4.99677 1.65567 5.01255 1.58024 4.98971 1.51177C4.96688 1.44333 4.90892 1.39247 4.83807 1.37873L3.72071 1.1621L3.17777 0.108389Z"
        fill={props.fill}
      />
      <path
        d="M9.17777 0.108389C9.14348 0.0418245 9.07484 0 8.99998 0C8.92512 0 8.85651 0.0418203 8.82219 0.108389L8.27928 1.1621L7.16192 1.37873C7.09107 1.39246 7.03312 1.44332 7.01028 1.51177C6.98745 1.58024 7.00325 1.65568 7.05163 1.70921L7.83967 2.58078L7.6891 3.77498C7.67986 3.84828 7.71177 3.9207 7.77211 3.96333C7.83244 4.00595 7.91136 4.01188 7.97735 3.9787L9 3.4646L10.0226 3.9787C10.0886 4.01188 10.1676 4.00595 10.2279 3.96333C10.2882 3.9207 10.3201 3.84828 10.3109 3.77498L10.1603 2.58078L10.9484 1.70921C10.9968 1.65567 11.0125 1.58024 10.9897 1.51177C10.9669 1.44333 10.9089 1.39247 10.8381 1.37873L9.72071 1.1621L9.17777 0.108389Z"
        fill={props.fill}
      />
    </svg>
  );
};
const Juristic = (props) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9447 6.49073C14.8646 7.45228 14.1834 8.37388 13.2219 8.37388H13.0215C13.3219 7.73275 13.4822 7.0117 13.4822 6.2504C13.4822 5.70947 13.4021 5.18865 13.2418 4.68782H13.9431C13.2017 2.444 11.2186 0.801396 8.89472 0.781363C6.57085 0.801346 4.5876 2.42415 3.84638 4.68782H4.62767C4.46739 5.16865 4.38732 5.70947 4.38732 6.2504C4.38732 6.99159 4.5476 7.73289 4.84804 8.37388H4.56758C3.60603 8.37388 2.90484 7.43232 2.84474 6.49073C2.56428 3.02518 5.48918 0.0200331 8.89474 0C12.3003 0.0201225 15.2252 3.02507 14.9447 6.49073ZM9.53576 7.93311C9.79623 7.93311 10.0166 8.05329 10.1368 8.25367H12.2203C12.5409 7.67278 12.7011 6.99155 12.7011 6.25036C12.7011 4.18695 11.0785 2.20367 8.93491 2.20367C6.35071 2.20367 4.68789 4.92818 5.28888 7.31212C5.70965 8.97486 7.17203 10.3171 8.93491 10.3171C10.0166 10.3171 10.9783 9.81626 11.6594 9.03496H10.1369C10.0168 9.23535 9.79639 9.35553 9.5359 9.35553C9.13528 9.35553 8.81471 9.03496 8.81471 8.63433C8.83469 8.25367 9.15526 7.93311 9.53576 7.93311ZM17.3286 14.4038C16.9881 12.6009 15.4054 11.2787 13.5424 11.2787H12.1802L9.97649 16.5073L9.53576 12.5007L9.93639 11.2787H8.05324L8.45386 12.5007L8.01314 16.5073L5.80942 11.2787H4.44724C2.6042 11.2787 1.02151 12.5808 0.660985 14.4038L0 18.0098H18.0298L17.3286 14.4038Z"
        fill={props.fill}
      />
    </svg>
  );
};

const Residents = (props) => {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9167 0H2.08333C0.933333 0 0 0.9375 0 2.08333V17.9167C0 19.0667 0.933333 20 2.08333 20H12.9167C14.0667 20 15 19.0667 15 17.9167V2.08333C15 0.9375 14.0667 0 12.9167 0ZM6.25 1.25119H8.75C8.98019 1.25119 9.16667 1.43767 9.16667 1.66786C9.16667 1.89805 8.98019 2.08452 8.75 2.08452H6.25C6.01981 2.08452 5.83333 1.89805 5.83333 1.66786C5.83333 1.4379 6.02004 1.25119 6.25 1.25119ZM7.5 18.675C7.04032 18.675 6.66667 18.3014 6.66667 17.8417C6.66667 17.382 7.04032 17.0083 7.5 17.0083C7.95968 17.0083 8.33333 17.382 8.33333 17.8417C8.33333 18.3011 7.95968 18.675 7.5 18.675ZM13.3333 15.4177C13.3333 15.6479 13.1469 15.8343 12.9167 15.8343H2.08333C1.85314 15.8343 1.66667 15.6479 1.66667 15.4177V3.75101C1.66667 3.52082 1.85314 3.33435 2.08333 3.33435H12.9167C13.1469 3.33435 13.3333 3.52082 13.3333 3.75101V15.4177Z"
        fill={props.fill}
      />
    </svg>
  );
};
const FilterIcon = (props) => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.7032 0.519531C31.9856 0.519531 34.6496 3.36114 34.6496 6.86236C34.6496 10.3636 31.9856 13.2052 28.7032 13.2052C25.4208 13.2052 22.7568 10.3636 22.7568 6.86236C22.7568 3.36114 25.4208 0.519531 28.7032 0.519531ZM28.7032 3.44704C26.9358 3.44704 25.5014 4.97713 25.5014 6.86236C25.5014 8.7476 26.9358 10.2777 28.7032 10.2777C30.4706 10.2777 31.9051 8.7476 31.9051 6.86236C31.9051 4.97713 30.4706 3.44704 28.7032 3.44704Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.7032 27.8438C31.9856 27.8438 34.6496 30.6854 34.6496 34.1866C34.6496 37.6878 31.9856 40.5294 28.7032 40.5294C25.4208 40.5294 22.7568 37.6878 22.7568 34.1866C22.7568 30.6854 25.4208 27.8438 28.7032 27.8438ZM28.7032 30.7713C26.9358 30.7713 25.5014 32.3013 25.5014 34.1866C25.5014 36.0718 26.9358 37.6019 28.7032 37.6019C30.4706 37.6019 31.9051 36.0718 31.9051 34.1866C31.9051 32.3013 30.4706 30.7713 28.7032 30.7713Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8097 14.1816C20.0921 14.1816 22.7561 17.0233 22.7561 20.5245C22.7561 24.0257 20.0921 26.8673 16.8097 26.8673C13.5273 26.8673 10.8633 24.0257 10.8633 20.5245C10.8633 17.0233 13.5273 14.1816 16.8097 14.1816ZM16.8097 17.1092C15.0423 17.1092 13.6078 18.6392 13.6078 20.5245C13.6078 22.4097 15.0423 23.9398 16.8097 23.9398C18.5771 23.9398 20.0115 22.4097 20.0115 20.5245C20.0115 18.6392 18.5771 17.1092 16.8097 17.1092Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.7657 5.39844C39.5232 5.39844 40.1379 6.05418 40.1379 6.86219C40.1379 7.6702 39.5232 8.32595 38.7657 8.32595H33.2766C32.5191 8.32595 31.9043 7.6702 31.9043 6.86219C31.9043 6.05418 32.5191 5.39844 33.2766 5.39844H38.7657Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.7657 32.7227C39.5232 32.7227 40.1379 33.3784 40.1379 34.1864C40.1379 34.9944 39.5232 35.6502 38.7657 35.6502H33.2766C32.5191 35.6502 31.9043 34.9944 31.9043 34.1864C31.9043 33.3784 32.5191 32.7227 33.2766 32.7227H38.7657Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.17305 21.9881C1.41554 21.9881 0.800781 21.3323 0.800781 20.5243C0.800781 19.7163 1.41554 19.0605 2.17305 19.0605H12.2361C12.9936 19.0605 13.6084 19.7163 13.6084 20.5243C13.6084 21.3323 12.9936 21.9881 12.2361 21.9881H2.17305Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5544 5.39844C20.3119 5.39844 20.9267 6.05418 20.9267 6.86219C20.9267 7.6702 20.3119 8.32595 19.5544 8.32595H2.17208C1.41457 8.32595 0.799805 7.6702 0.799805 6.86219C0.799805 6.05418 1.41457 5.39844 2.17208 5.39844H19.5544Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5544 32.7227C20.3119 32.7227 20.9267 33.3784 20.9267 34.1864C20.9267 34.9944 20.3119 35.6502 19.5544 35.6502H2.17208C1.41457 35.6502 0.799805 34.9944 0.799805 34.1864C0.799805 33.3784 1.41457 32.7227 2.17208 32.7227H19.5544Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.9582 21.9881C25.2007 21.9881 24.5859 21.3323 24.5859 20.5243C24.5859 19.7163 25.2007 19.0605 25.9582 19.0605H38.7658C39.5233 19.0605 40.1381 19.7163 40.1381 20.5243C40.1381 21.3323 39.5233 21.9881 38.7658 21.9881H25.9582Z"
        fill="white"
      />
    </svg>
  );
};

export {
  Battery,
  BothRequest,
  HomeNomal,
  HomeVIP,
  HomeVVIP,
  Juristic,
  Residents,
  FilterIcon
};
