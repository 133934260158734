import { createSlice } from '@reduxjs/toolkit'
export const projectSlice = createSlice({
    name: 'project',
    initialState: {
        rooms: [],
        active: { title: "Both Request", sub: "Request", count_room: 0 },
        title: 'Project Site',
        onFilter: true,
        Filters_obj: { serviceRequst: 'all', pir_battery: 'all', room: 'all', time: 'all', status: 'all', state: 'all',floor:'all'},
        H_filter: { serviceRequst: 'all', pir_battery: 'all', room: 'all', time: 'all', status: 'all', state: 'all',floor:'all'},
        onOpennitify: true,
        count_unread:0
    
    },
    reducers: {
        editRooms: (state, action) => {
            let tmp = action.payload;
            state.rooms = tmp;
        },
        editActive: (state, action) => {
            let tmp = action.payload
            if (typeof action === 'object') {
                state.active = tmp;
            }
        },
        editTitle: (state, action) => {
            let tmp = action.payload;
            state.title = tmp;
        },
        editOnfilter: (state, action) => {
            let tmp = action.payload;
            state.Filters_obj = tmp;
        },
        editonOpennitify: (state, action)=>{
            let tmp = action.payload;
            state.onOpennitify = tmp;
        },
        editcount_unread: (state, action)=>{
            let tmp = action.payload;
            state.count_unread = tmp;
        },
        editH_filter: (state, action) => {
            let tmp = action.payload;
            state.H_filter = tmp;
        },
    }
})

export const { editRooms, editActive, editTitle, editOnfilter,editonOpennitify,editcount_unread,editH_filter } = projectSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getRooms = state => state.projectSite.rooms
export const getActive = state => state.projectSite.active
export const getTitle = state => state.projectSite.title
export const getonFilter = state => state.projectSite.Filters_obj
export const getH_filter = state => state.projectSite.H_filter
export const getonOpen = state => state.projectSite.onOpennitify
export const getcount_unread = state => state.projectSite.count_unread
export default projectSlice.reducer
